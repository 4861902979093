import {
  format,
  addDays,
  compareAsc,
  differenceInYears,
  isBefore,
  set,
  addHours,
  addMinutes,
} from "date-fns";

export function formatDateDayOnly(date: string | Date): string {
  const d = new Date(date);
  return format(d, "MMMM d");
}

export function tooltipDateFormatted(date: string | Date) {
  const d = new Date(date);

  // If this is the same week as now, we will show, the weekday and time
  // TODO set locale based on language?

  // Otherwise show whole date.
  return format(d, "MMMM d, y") + " at " + format(d, "HH:mm");
}

export function flatDate(date: Date): Date {
  return set(date, { hours: 0, minutes: 0, milliseconds: 0 });
}

export const isSameDay = (date1: string, date2: string): boolean => {
  const day1 = flatDate(new Date(date1));
  const day2 = flatDate(new Date(date2));

  return compareAsc(day1, day2) === 0;
};

export const calculateAge = (date: string | undefined): number | undefined => {
  if (!date) {
    return undefined;
  }
  try {
    const birthdate = new Date(date);
    const today = new Date();

    // info here
    // https://runkit.com/dvolpato/calculate-age-with-date-fns

    const age = differenceInYears(today, birthdate);

    return age;
  } catch (err) {
    return undefined;
  }
};

export function isOlderThanMinutes(
  dateOld: Date,
  dateNow: Date,
  numberOfMinutes: number
): boolean {
  const old: Date = addMinutes(dateNow, -numberOfMinutes);
  return isBefore(dateOld, old);
}

export function isOlderThanHours(
  dateOld: Date,
  dateNow: Date,
  numberOfHours: number
): boolean {
  const old: Date = addHours(dateNow, -numberOfHours);
  return isBefore(dateOld, old);
}

export function isOlderThan(
  dateOld: Date,
  dateNow: Date,
  numberOfDays: number
): boolean {
  const old: Date = addDays(new Date(dateNow), -numberOfDays);
  return isBefore(flatDate(dateOld), flatDate(old));
}
