import { Button } from "@mui/material";
import styled from "styled-components";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
`;

type ButtonWithIconProps = {
  id?: string;
  className?: string;
  icon: React.ReactNode;
  disabled?: boolean;
  //iconSize: number;
  children?: React.ReactNode;
  onClick?: () => void;
  anchorEl?: any;
};

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  className,
  icon,
  disabled,
  //iconSize,
  children,
  onClick,
  anchorEl,
  id,
  ...props
}) => {
  return (
    <Button
      id={id}
      ref={anchorEl}
      onClick={onClick}
      disabled={disabled}
      className={className}
      {...props}
    >
      <ContentWrapper>
        {icon}
        {children}
      </ContentWrapper>
    </Button>
  );
};

export default ButtonWithIcon;
