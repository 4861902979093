import {
  ChatBubbleOvalLeftEllipsisIcon,
  ScaleIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/solid";
import { Box, useTheme } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { logger } from "util/log";
import StyledButton from "../../components/Button/Button";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import useWindowSize from "../../hooks/useWindowSize";
import LoginFooter from "../login/LoginFooter";
import {
  InfoBubble,
  InfoContainer,
  InfoHeader,
  InfoHeaderWrapper,
  PageWrapper,
} from "../login/styled";
import FeatureList from "./FeatureList";

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 800px;
  justify-content: center;
  align-items: center;
`;

const StyledPage = styled.div<{ bgImage: string }>`
  width: 100%;
  //background-color: ${(props) => props.theme.bodyBackground};
  display: flex;
  flex-flow: column;

  background: ${(props) => `url(/images/${props.bgImage})`} no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @supports (-webkit-touch-callout: none) {
    background: ${(props) => `url(/images/${props.bgImage})`} center top
      no-repeat scroll;
    //background-size: cover;
    background-attachment: scroll;
  }
`;

const StoreImage = styled.img`
  width: 200px;
`;

const InfoItem: React.FC<{
  title: string;
  text: string;
  icon: JSX.Element;
}> = ({ title, text, icon }) => {
  const theme = useTheme() as Theme;

  return (
    <InfoContainer>
      <InfoBubble>{icon}</InfoBubble>
      <Text size={24} weight={600}>
        {title}
      </Text>
      <Text size={18} color={theme.colors.gray}>
        {text}
      </Text>
    </InfoContainer>
  );
};

const VideoDating = () => {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  let bgImage = "bgImage7.jpg";
  const onelink = "https://minglify.io/app-install/web-minglify";

  useEffect(() => {
    if (lang) {
      logger.log("Changing lang to: " + lang);
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }
  }, [i18n, lang]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <PageWrapper>
      <StyledPage bgImage={bgImage}>
        <TopContainer>
          <FlexCol
            padding={windowSize[0] < 980 ? 50 : 0}
            paddingRight={windowSize[0] < 980 ? 50 : 500}
            rowGap={40}
          >
            <FlexRow
              justifyContent="flex-start"
              width={windowSize[0] < 980 ? "100%" : "600px"}
            >
              <Text
                size={windowSize[0] < 500 ? 48 : 60}
                weight={900}
                color="#fff"
              >
                {t("Video Dating With Minglify App")}
              </Text>
            </FlexRow>
            <FlexRow
              justifyContent="flex-start"
              width={windowSize[0] < 980 ? "100%" : "600px"}
            >
              <Text size={20} weight={900} color="#fff">
                {t(
                  "Discover dating videos through Minglify, a worldwide dating app that brings together people through compatibility, nurturing authentic conversations and meaningful connections"
                )}
              </Text>
            </FlexRow>
            <FlexRow
              colGap={20}
              justifyContent={windowSize[0] < 980 ? "center" : "flex-start"}
            >
              <a href={onelink}>
                <StoreImage src="/appstore.png" alt="appstore" />
              </a>

              <a href={onelink}>
                <StoreImage src="/googleplay.png" alt="appstore" />
              </a>
            </FlexRow>
          </FlexCol>
        </TopContainer>
      </StyledPage>

      <InfoHeaderWrapper>
        <InfoHeader>
          <InfoItem
            title={t("Safe and serious dating")}
            text={t(
              "Date with confidence and find someone who is also seeking something genuine."
            )}
            icon={<ShieldCheckIcon width={40} color={theme.colors.primary} />}
          />
          <InfoItem
            title={t("Modern chat")}
            text={t(
              "A modern chat with real-time translation to your language."
            )}
            icon={
              <ChatBubbleOvalLeftEllipsisIcon
                width={40}
                color={theme.colors.primary}
              />
            }
          />
          <InfoItem
            title={t("Matching with people")}
            text={t(
              "Use an advanced filter to find your perfect matches and nearby people."
            )}
            icon={<ScaleIcon width={40} color={theme.colors.primary} />}
          />
        </InfoHeader>
      </InfoHeaderWrapper>

      <Box sx={{ width: 20, height: 40 }} />

      <FlexRow justifyContent="center" alignItems="flex-start" colGap={50}>
        <FlexCol>
          <img
            src="/images/asian-woman.jpg"
            alt="couple"
            width="500px"
            style={{ borderRadius: 20 }}
          />
        </FlexCol>
        <FlexCol alignItems="flex-start" rowGap={20}>
          <FlexRow width="400px">
            <Text size={32} weight={900}>
              {t("Discovering Dating Videos: Your Journey with Minglify")}
            </Text>
          </FlexRow>
          <FlexRow width="400px">
            <Text size={18} weight={400}>
              {t(
                "Looking to connect with new people? Let Minglify be your guide on this exciting quest. With our platform, delving into video dating becomes an enriching experience tailored to your preferences."
              )}
            </Text>
          </FlexRow>
          <FlexRow width="400px">
            <Text size={18} weight={400}>
              {t(
                "Embark on a global exploration through Minglify's app and website, specially designed to assist you in your search companionship with video dating. Whether you're an experienced dater or just beginning to navigate video dating, we've got you covered."
              )}
            </Text>
          </FlexRow>
          <FlexRow width="400px">
            <Text size={18} weight={400}>
              {t(
                "Our commitment to diversity is mirrored in our user base and team hailing from various corners of the world. We understand that the intricacies of dating culture differ across regions, ensuring that your journey with Minglify is a culturally sensitive and rewarding one."
              )}
            </Text>
          </FlexRow>
          <FlexRow width="400px">
            <Text size={18} weight={400}>
              {t(
                "With language barriers out of the way, thanks to our app and website being available in multiple languages, your path to connecting with dating videos is made even smoother. Let Minglify be your bridge to meaningful interactions and potential relationships through video dating, providing a supportive and user-friendly environment for your international dating pursuits."
              )}
            </Text>
          </FlexRow>
        </FlexCol>
      </FlexRow>

      <FlexCol
        paddingTop={50}
        justifyContent="center"
        alignItems="center"
        rowGap={20}
      >
        <FlexRow justifyContent="center" width="100%">
          <Text size={32} weight={900}>
            {t("Top Free Features For Video Dating")}
          </Text>
        </FlexRow>
        <FlexRow maxWidth="600px" justifyContent="center">
          <Text size={18} weight={400}>
            {t(
              "If video dating sparks your interest, we offer a range of free features to assist you in connecting with singles from around the world. Feel free to also explore how our offerings stack up against those of other dating apps."
            )}
          </Text>
        </FlexRow>
      </FlexCol>

      <FeatureList />

      <Box sx={{ width: 20, height: 20 }} />

      <FlexCol
        backgroundColor={"#E0357D"}
        padding={40}
        alignItems="center"
        rowGap={20}
      >
        <FlexRow justifyContent="center" textAlign="center" maxWidth="900px">
          <Text size={32} weight={900} color="#FFF">
            {t(
              "Minglify is the perfect place for singles find their partners, could you be next?"
            )}
          </Text>
        </FlexRow>
        <FlexRow color="#FFF">
          <StyledButton
            borderRadius="15px"
            backgroundColor="#FFF"
            color={theme.colors.primary}
            onClick={() => {
              goToTop();
              navigate("/login/" + lang, { replace: true });
            }}
          >
            {t("Try For Free Now")}
          </StyledButton>
        </FlexRow>
      </FlexCol>

      <Box sx={{ width: 20, height: 100 }} />

      <LoginFooter page="video-dating" />
    </PageWrapper>
  );
};
export default VideoDating;
