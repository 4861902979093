import { ClickAwayListener, Popper, Stack, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import CircIconButton from "../CircIconButton/CircIconButton";
import HrDivider from "../HrDivider/HrDivider";
import { FlexCol, FlexRow } from "../Styled/Container";
import { MenuPaper } from "../Styled/Paper";
import Text from "../Styled/Text";

const OptionsPaper = styled(MenuPaper)`
  padding: 0px !important;
  min-width: 200px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 200000;
`;

const ListItem = styled(FlexRow)`
  border-radius: 8px;
  padding: 5px;

  &:hover {
    background-color: ${(props) => props.theme.listItemHoverBg} !important;
    transition: 0.2s;
    cursor: pointer;
  }
`;

const ListItemContainer = styled.div``;

const StyledPopper = styled(Popper)`
  z-index: 200000;
`;

export type MenuOption = {
  text: string | null;
  onClick: () => void;
  icon: JSX.Element | null;
  dividerAfter: boolean;
  component?: JSX.Element; // To use a component for both text and.
};

const OptionMenu: React.FC<{
  id: string;
  type: "CircIconButton" | "IconButtonWithChildren";
  icon: JSX.Element;
  buttonChildren?: JSX.Element;
  options: MenuOption[];
  placement: "bottom-start" | "bottom-end" | "top-start" | "bottom";
  size?: number;
  backgroundColor?: string;
}> = ({
  id,
  icon,
  options,
  placement,
  buttonChildren,
  type,
  size,
  backgroundColor,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLElement>(null);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (!event) {
      setOpen(false);
      return;
    }

    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleOptionClick = (option: MenuOption) => {
    option.onClick();
    handleClose();
  };

  return (
    <Stack direction="row" spacing={2}>
      {type === "CircIconButton" && (
        <CircIconButton
          backgroundColor={
            open
              ? theme.buttonBgSelected
              : backgroundColor !== undefined
              ? backgroundColor
              : theme.buttonBg
          }
          size={size !== undefined ? size : 20}
          icon={icon}
          anchorEl={anchorRef}
          id={`${id}-optionsmenu-button`}
          aria-controls={open ? `${id}-options-menu` : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
      )}
      {type === "IconButtonWithChildren" && (
        <ButtonWithIcon
          icon={icon}
          anchorEl={anchorRef}
          id={`${id}-optionsmenu-button`}
          aria-controls={open ? `${id}-options-menu` : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {buttonChildren}
        </ButtonWithIcon>
      )}
      {open && (
        <StyledPopper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement={placement}
          //transition
        >
          {/* {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          > */}
          <OptionsPaper>
            <ClickAwayListener onClickAway={handleClose}>
              <FlexCol>
                {options.map((option, index) => {
                  return (
                    <ListItemContainer key={"menu-option" + index}>
                      <ListItem onClick={() => handleOptionClick(option)}>
                        {option.component ? (
                          <>{option.component}</>
                        ) : (
                          <>
                            {option.icon}
                            <Text weight={600}>{option.text}</Text>
                          </>
                        )}
                      </ListItem>
                      {option.dividerAfter && <HrDivider />}
                    </ListItemContainer>
                  );
                })}
              </FlexCol>
            </ClickAwayListener>
          </OptionsPaper>
          {/* </Grow>
        )} */}
        </StyledPopper>
      )}
    </Stack>
  );
};
export default OptionMenu;
