import styled from "styled-components";
import { FlexCol, FlexRow } from "../../components/Styled/Container";

export const StyledA = styled.a`
  color: ${(props) =>
    props.color ? props.color : props.theme.linkColor} !important;
  text-decoration: none !important;

  a {
    color: #fff !important;
  }
  a:visited {
    color: #fff !important;
  }
`;

export const Spacer = styled.div<{ height?: number; width?: number }>`
  height: ${(props) => props.height ?? 0}px;
  width: ${(props) => props.width ?? 0}px;
`;

export const InfoHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: ${(props) => props.theme.bg1};
`;

export const InfoHeader = styled.div`
  max-width: 1000px;
  background-color: ${(props) => props.theme.pageBackground};
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-radius: 12px;
  margin-top: -30px;
  column-gap: 40px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
  padding-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 8px;
  padding-right: 8px;
  flex-basis: 100%;

  @media (max-width: 700px) {
    column-gap: 10px;
  }

  @media (max-width: 600px) {
    align-items: center;
    flex-direction: column;
    row-gap: 60px;
  }
`;

export const MingleContainer = styled(FlexCol)`
  //max-width: 1000px;
  //width: 100%;
  justify-content: center;
  align-items: center;
`;

export const InfoContainer = styled(FlexCol)`
  align-items: center;
  max-width: 280px;
  text-align: center;
  flex-basis: 100%;

  @media (max-width: 600px) {
    max-width: 80%;
  }
`;

export const InfoBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-top: -40px;
  background-color: ${(props) => props.theme.bg2};
  row-gap: 3px;
`;

export const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.bg1};
`;

export const PaperBox = styled(FlexCol)`
  background-color: ${(props) => props.theme.pageBackground};
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
  /* max-width: 300px;
  min-height: 200px; */
  border-radius: 12px;
  padding: 15px;
  flex-basis: 100%;
  //height: 100%;
`;

export const BoxContainer = styled(FlexRow)`
  flex-wrap: nowrap;
  max-width: 1000px;
  row-gap: 40px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export const WhyWrapper = styled(FlexCol)`
  background-color: ${(props) => props.theme.bg3};
`;

export const WhyImage = styled.img`
  border-radius: 12px;
  max-width: 800px;
  width: 100%;
`;

export const WhyImageContainer = styled(FlexRow)`
  position: relative;
`;

export const WhyImageTextContainer = styled.div`
  position: absolute;
  bottom: 20px;
  //left: 0;
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px;
`;

export const WrapContainer = styled(FlexCol)`
  flex-wrap: nowrap;
  max-width: 600px;
`;
