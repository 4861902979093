import {
  AlcoholHabit,
  Beard,
  Etnicity,
  EyeColor,
  Gender,
  HairColor,
  HereTo,
  KidsStatus,
  Living,
  Maybe,
  Me,
  PostAudience,
  Profile,
  ProfileLocation,
  ReactionValue,
  RelationshipStatus,
  Sexuality,
  SmokingHabit,
  Tattoo,
  User,
} from "../graphql/graphql";
import { calculateAge, isOlderThan, isOlderThanMinutes } from "./dates";
import { colonsToEmoji } from "./emoji";
import sanitizeHtml from "sanitize-html";
import { formatDistanceToNowStrict } from "date-fns";
import locale from "date-fns/locale/en-US";
import haversine from "haversine-distance";
import { logger } from "util/log";
import { UserWithNameFields } from "graphql/types";

export const shuffleArray = (array: any[]): any[] => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = newArray[i];
    newArray[i] = newArray[j];
    newArray[j] = temp;
  }
  return newArray;
};

// For showing text in posts and similar.
export const parseStringToText = (text: string): string => {
  logger.log("parseStringToText", text);
  // We need to turn newlines into correct format to output in the Text component.
  let result = text.replace(/\n/g, "<br />");
  return result;
};

export const getFileSizeString = (fileSize: number) => {
  if (fileSize < 1024) {
    return `${fileSize} B`;
  }
  if (fileSize < 1024 * 1024) {
    return `${Math.round(fileSize / 1024)} KB`;
  }
  return `${Math.round(fileSize / (1024 * 1024))} MB`;
};

export const popularityText = (
  popularity: number,
  translate?: (text: string) => string
) => {
  if (popularity < 10) {
    return translate ? translate("very low") : "very low";
  }
  if (popularity < 30) {
    return translate ? translate("low") : "low";
  }
  if (popularity < 50) {
    return translate ? translate("medium") : "medium";
  }
  if (popularity < 70) {
    return translate ? translate("high") : "high";
  }
  if (popularity < 90) {
    return translate ? translate("popular") : "popular";
  }
  return translate ? translate("very popular") : "very popular";
};

export const matchText = (text: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  return /^['0-9\p{L}\p{M} \n.,&'?!+*_:-]+$/iu.test(text);
};

export const textAreaHelperText = (
  text: string,
  maxAllowed: number,
  label: string,
  translate?: (text: string) => string
) => {
  if (text.length < 1) {
    return `${translate ? translate("Add up to") : "Add up to"} ${maxAllowed} ${
      translate ? translate("characters in") : "characters in"
    } ${label}.`;
  }
  return `${text.length} ${translate ? translate("of") : "of"} ${maxAllowed}`;
};

export function reactionValueToColonEmoji(value: ReactionValue) {
  switch (value) {
    case ReactionValue.Like:
      return ":+1:";
    case ReactionValue.Love:
      return ":heart:";
    case ReactionValue.Care:
      return ":hugging_face:";
    case ReactionValue.Haha:
      return ":rolling_on_the_floor_laughing:";
    case ReactionValue.Wow:
      return ":star-struck:";
    case ReactionValue.Sad:
      return ":cry:";
    case ReactionValue.Angry:
      return ":rage:";
    case ReactionValue.Flirt:
      return ":kissing_heart:";
    default:
      return "";
  }
}

export function colonEmojiToReactionValue(emoji: string): ReactionValue {
  if (emoji === ":+1:") {
    return ReactionValue.Like;
  }
  if (emoji === ":heart:") {
    return ReactionValue.Love;
  }
  if (emoji === ":hugging_face:") {
    return ReactionValue.Care;
  }
  if (emoji === ":rolling_on_the_floor_laughing:") {
    return ReactionValue.Haha;
  }
  if (emoji === ":star-struck:") {
    return ReactionValue.Wow;
  }
  if (emoji === ":cry:") {
    return ReactionValue.Sad;
  }
  if (emoji === ":rage:") {
    return ReactionValue.Angry;
  }
  if (emoji === ":kissing_heart:") {
    return ReactionValue.Flirt;
  }
  return ReactionValue.NoReaction;
}

// Corresponds to the ReactionValue enums
export function reactionCountsToColonEmojis(reactionCounts: number[]): string {
  let result = "";
  if (reactionCounts[0] && reactionCounts[0] > 0) {
    result += ":+1:";
  }
  if (reactionCounts[1] && reactionCounts[1] > 0) {
    result += ":heart:";
  }
  if (reactionCounts[2] && reactionCounts[2] > 0) {
    result += ":hugging_face:";
  }
  if (reactionCounts[3] && reactionCounts[3] > 0) {
    result += ":rolling_on_the_floor_laughing:";
  }
  if (reactionCounts[4] && reactionCounts[4] > 0) {
    result += ":star-struck:";
  }
  if (reactionCounts[5] && reactionCounts[5] > 0) {
    result += ":cry:";
  }
  if (reactionCounts[6] && reactionCounts[6] > 0) {
    result += ":rage:";
  }
  if (reactionCounts[7] && reactionCounts[7] > 0) {
    result += ":kissing_heart:";
  }
  return result;
}

export function postAudienceString(
  audience: PostAudience,
  translate?: (text: string) => string
): string {
  switch (audience) {
    case PostAudience.Followers:
      return translate ? translate("Followers") : "Followers";
    case PostAudience.Friends:
      return translate ? translate("Friends") : "Friends";
    case PostAudience.Group:
      return translate ? translate("Group") : "Group";
    case PostAudience.Matches:
      return translate ? translate("Matches") : "Matches";
    default:
      return translate ? translate("Public") : "Public";
  }
}

export function getDistanceInKm(
  coord1: Maybe<Maybe<number>[]> | undefined,
  coord2: Maybe<Maybe<number>[]> | undefined
): number | undefined {
  const distance = getDistanceInMeters(coord1, coord2);
  if (distance === undefined) {
    return undefined;
  } else {
    return distance / 1000;
  }
}

export function getDistanceInMeters(
  coord1: Maybe<Maybe<number>[]> | undefined,
  coord2: Maybe<Maybe<number>[]> | undefined
): number | undefined {
  if (!coord1 || !coord2) {
    return undefined;
  }

  if (coord1.length !== 2 || (coord1[0] === 0 && coord1[1] === 0)) {
    return undefined;
  }

  if (coord2.length !== 2 || (coord2[0] === 0 && coord2[1] === 0)) {
    return undefined;
  }
  const lat1 = coord1[1];
  const lng1 = coord1[0];
  if (lat1 === null || lng1 === null) {
    return undefined;
  }
  const lat2 = coord2[1];
  const lng2 = coord2[0];
  if (lat2 === null || lng2 === null) {
    return undefined;
  }

  const point1 = { lat: lat1, lng: lng1 };
  const point2 = { lat: lat2, lng: lng2 };

  return haversine(point1, point2);
}

export function isNewMember(user: User): boolean {
  return !isOlderThan(new Date(user.created), new Date(), 10);
}

export function hexToRgb(hex: string) {
  const normal = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
  if (normal) return normal.slice(1).map((e) => parseInt(e, 16));

  const shorthand = hex.match(/^#([0-9a-f])([0-9a-f])([0-9a-f])$/i);
  if (shorthand) return shorthand.slice(1).map((e) => 0x11 * parseInt(e, 16));

  return null;
}
export function hexToRgbString(hex: string, opacity: number) {
  const rgb = hexToRgb(hex);
  if (rgb) {
    return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
  }
  return "";
}

const formatDistanceLocale = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}y",
  xYears: "{{count}}y",
  overXYears: "{{count}}y",
  almostXYears: "{{count}}y",
};

export function numberToShortString(value: number): string {
  if (value > 999999) {
    const short = Math.round(value / 1000000);
    return "" + short.toFixed(1) + "m";
  } else if (value > 999) {
    const short = value / 1000;
    return "" + short.toFixed(1) + "k";
  } else {
    return "" + value;
  }
}

export function isSingleEmoji(text: string): boolean {
  //logger.log("isSingleEmoji: " + text);
  if (text === ":+1:") {
    return true;
  }

  return false;
}

export function sanitizeInput(text: string) {
  // Remove trailing spaces.
  let result = text.trim();

  return result;
}

function formatDistance(token: string, count: any, options: any) {
  options = options || {};

  const result = formatDistanceLocale[
    token as keyof typeof formatDistanceLocale
  ].replace("{{count}}", count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }
  return result;
}

/**
 * Ex. 1m, 1h, 1d, 1-xw (28w)
 * @param date
 */
export const timeSinceString = (date: Date | string) => {
  //logger.log("date: " + date);

  if (typeof date === "string") {
    return formatDistanceToNowStrict(new Date(date), {
      locale: {
        ...locale,
        formatDistance,
      },
    });
  } else {
    return formatDistanceToNowStrict(date, {
      locale: {
        ...locale,
        formatDistance,
      },
    });
  }
};

export const activeLabelString = (
  date: Date | string,
  translate?: (text: string) => string
): string | null => {
  if (!date) {
    return null;
  }

  if (!isOlderThanMinutes(new Date(date), new Date(), 10)) {
    return translate ? translate("Active now") : "Active now";
  } else {
    return (
      (translate ? translate("Active") : "Active") +
      " " +
      timeSinceString(new Date(date)) +
      " " +
      (translate ? translate("ago") : "ago")
    );
  }
};

const isOnlineDate = (date: Date | string) => {
  if (!date) {
    return false;
  }
  if (!isOlderThanMinutes(new Date(date), new Date(), 30)) {
    return true;
  }
  return false;
};

export const showOnlineBadge = (
  lastActive: Date,
  userShowOnlineStatus: boolean,
  mePremium: boolean,
  meShowOnlineStatus: boolean
): boolean => {
  return (
    isOnlineDate(lastActive) &&
    userShowOnlineStatus === true &&
    mePremium === true &&
    meShowOnlineStatus === true
  );
};

export const appendTextString = (
  text: string,
  append: string | undefined | null,
  delimiter: string
) => {
  if (!append) {
    return text;
  }
  if (text.length > 0) {
    return `${text}${delimiter} ${append}`;
  } else {
    return append;
  }
};

export const getLocationString = (
  location: ProfileLocation | undefined | null
): string => {
  let result = "";
  if (location) {
    result = appendTextString(result, location.city, ",");
    result = appendTextString(result, location.county, ",");
    result = appendTextString(result, location.country, ",");
  }
  return result;
};

export const getShortName = (
  user: UserWithNameFields | undefined | null,
  maxLength?: number
): string => {
  if (!user) {
    return "";
  }

  let name = "";

  if (user.profile?.firstName) {
    name = user.profile.firstName;
  } else {
    name = user.username ?? user._id;
  }

  // Cap the length of the text.
  if (name.length > (maxLength ? maxLength : 11)) {
    name = name.slice(0, maxLength ? maxLength - 1 : 10) + "...";
  }

  return name;
};

export const getNameForUser = (
  user: UserWithNameFields | undefined | null
): string => {
  if (!user) {
    return "";
  }
  if (user.admin === true) {
    return user.username ?? "Admin";
  }
  return getName(
    user.username ?? "!username",
    user.profile?.firstName ?? "",
    user.profile?.lastName ?? "",
    user.profile?.birthDate
  );
};

export const getAgeForBirthdate = (
  birthDate: string | undefined | null
): string => {
  if (birthDate && birthDate !== undefined) {
    return "" + calculateAge(birthDate);
  }
  return "";
};

export const getAge = (user: User): string => {
  if (user.profile?.birthDate) {
    return "" + calculateAge(user.profile.birthDate);
  } else {
    return "";
  }
};

// we dont export this, getNameForUser should be used instead everywhere
const getName = (
  username: string,
  firstName?: string,
  lastName?: string,
  birthDate?: string
) => {
  let name = "";

  if (firstName) {
    name += firstName;
  }
  if (lastName) {
    name.length > 0 ? (name += " " + lastName) : (name += lastName);
  }
  if (name.length < 1) {
    name = username;
  }
  if (birthDate) {
    name += ", " + calculateAge(birthDate);
  }
  return name;
};

export const getMeName = (me: Me | undefined, profile: Profile): string => {
  let name = "";

  if (profile?.firstName) {
    name += profile.firstName;
  }
  if (profile?.lastName) {
    if (name.length > 0) {
      name += " ";
    }
    name += profile.lastName;
  }

  if (name.length < 1) {
    name += me?.username;
  }
  if (me?.admin !== true) {
    name += ", " + calculateAge(profile?.birthDate);
  }
  return name;
};

export function etnicityString(
  etnicity: Etnicity,
  translate?: (text: string) => string
): string {
  switch (etnicity) {
    case Etnicity.AfricanAmerican:
      return translate ? translate("African american") : "African american";
    case Etnicity.AmericanIndian:
      return translate ? translate("American indian") : "American indian";
    case Etnicity.Asian:
      return translate ? translate("Asian") : "Asian";
    case Etnicity.Hispanic:
      return translate ? translate("Hispanic") : "Hispanic";
    case Etnicity.White:
      return translate ? translate("White") : "Vit";
    case Etnicity.Undisclosed:
      return translate ? translate("Prefer not to say") : "Prefer not to say";
    default:
      return "";
  }
}

export function genderPluralString(
  gender: Gender | string,
  translate?: (text: string) => string
): string {
  switch (gender) {
    case Gender.Man:
      return translate ? translate("Men") : "Men";
    case Gender.NonBinary:
      return translate ? translate("Non-binaries") : "Non-binaries";
    case Gender.Transgender:
      return translate ? translate("Transgenders") : "Transgenders";
    case Gender.Undisclosed:
      return translate ? translate("Prefer not to say") : "Prefer not to say";
    case Gender.Woman:
      return translate ? translate("Women") : "Women";
    default:
      return "";
  }
}

export function genderString(
  gender: Gender | string,
  translate?: (text: string) => string
): string {
  switch (gender) {
    case Gender.Man:
      return translate ? translate("Man") : "Man";
    case Gender.NonBinary:
      return translate ? translate("Non-binary") : "Non-binary";
    case Gender.Transgender:
      return translate ? translate("Transgender") : "Transgender";
    case Gender.Undisclosed:
      return translate ? translate("Prefer not to say") : "Prefer not to say";
    case Gender.Woman:
      return translate ? translate("Woman") : "Woman";
    default:
      return "";
  }
}

export function hereToString(
  hereTo: HereTo,
  translate?: (text: string) => string
): string {
  switch (hereTo) {
    case HereTo.Chat:
      return translate ? translate("I'm here to chat") : "I'm here to chat";
    case HereTo.Date:
      return translate
        ? translate("I'm here to find dates")
        : "I'm here to find dates";
    case HereTo.Relationship:
      return translate
        ? translate("I'm here to find a relationship")
        : "I'm here to find a relationship";
    case HereTo.Friendship:
      return translate
        ? translate("I'm here to find friends")
        : "I'm here to find friends";
    default:
      return "";
  }
}

export function relationshipStatusString(
  status: RelationshipStatus,
  translate?: (text: string) => string
): string {
  switch (status) {
    case RelationshipStatus.Undisclosed:
      return translate ? translate("Prefer not to say") : "Prefer not to say";
    case RelationshipStatus.InRelationship:
      return translate ? translate("In a relationship") : "In a relationship";
    case RelationshipStatus.Married:
      return translate ? translate("Married") : "Married";
    case RelationshipStatus.Single:
      return translate ? translate("Single") : "Single";
    case RelationshipStatus.Other:
      return translate ? translate("Something else") : "Something else";
    case RelationshipStatus.InOpenRelationship:
      return translate
        ? translate("In an open relationship")
        : "In an open relationship";
    case RelationshipStatus.Complicated:
      return translate
        ? translate("In a compliated relationship")
        : "In a compliated relationship";
    default:
      return "";
  }
}

export function sexualityString(
  sex: Sexuality,
  translate?: (text: string) => string
): string {
  switch (sex) {
    case Sexuality.Undisclosed:
      return translate ? translate("Prefer not to say") : "Prefer not to say";
    case Sexuality.AskMe:
      return translate ? translate("Ask me...") : "Ask me...";
    case Sexuality.Hetero:
      return translate ? translate("Heterosexual") : "Heterosexual";
    case Sexuality.Homo:
      return translate ? translate("Homosexual") : "Homosexual";
    case Sexuality.Bisexual:
      return translate ? translate("Bisexual") : "Bisexual";
    case Sexuality.Other:
      return translate ? translate("Other") : "Other";
    default:
      return "";
  }
}

export function hairColorString(
  color: HairColor,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(hairColorString(color));
  }

  switch (color) {
    case HairColor.Black:
      return "Black";
    case HairColor.Blonde:
      return "Blonde";
    case HairColor.Brown:
      return "Brown";
    case HairColor.Colored:
      return "Colored";
    case HairColor.Gray:
      return "Gray";
    case HairColor.Red:
      return "Red";
    case HairColor.Shaved:
      return "Shaved";
    case HairColor.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function eyeColorString(
  color: EyeColor,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(eyeColorString(color));
  }

  switch (color) {
    case EyeColor.Amber:
      return "Amber";
    case EyeColor.Blue:
      return "Blue";
    case EyeColor.Brown:
      return "Brown";
    case EyeColor.Gray:
      return "Gray";
    case EyeColor.Green:
      return "Green";
    case EyeColor.Hazel:
      return "Hazel";
    case EyeColor.Mixed:
      return "Mixed";
    case EyeColor.Other:
      return "Other";
    case EyeColor.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function smokingHabitString(
  value: SmokingHabit,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(smokingHabitString(value));
  }
  switch (value) {
    case SmokingHabit.HateSmoking:
      return "No, I hate smoking";
    case SmokingHabit.No:
      return "No smoking, never";
    case SmokingHabit.YesEveryDay:
      return "Smoking, every day";
    case SmokingHabit.YesParties:
      return "Smoking, only at parties";
    case SmokingHabit.YesSometimes:
      return "Smoking, sometimes";
    case SmokingHabit.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function alcoholHabitString(
  value: AlcoholHabit,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(alcoholHabitString(value));
  }
  switch (value) {
    case AlcoholHabit.HateAlcohol:
      return "No, I hate alcohol";
    case AlcoholHabit.No:
      return "No alcohol, never";
    case AlcoholHabit.YesEveryDay:
      return "Alcohol, every day";
    case AlcoholHabit.YesParties:
      return "Alcohol, at parties";
    case AlcoholHabit.YesSometimes:
      return "Alcohol, sometimes";
    case AlcoholHabit.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function kidsStatusString(
  value: KidsStatus,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(kidsStatusString(value));
  }
  switch (value) {
    case KidsStatus.NoMaybeLater:
      return "No kids, maybe in the future";
    case KidsStatus.NoNever:
      return "No, I do not want kids";
    case KidsStatus.YesAdults:
      return "Yes kids, grown up now";
    case KidsStatus.YesFulltime:
      return "Yes kids, fulltime";
    case KidsStatus.YesParttime:
      return "Yes kids, part time";
    case KidsStatus.YesWantMore:
      return "Yes kids, and I want more";
    case KidsStatus.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function beardString(
  value: Beard,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(beardString(value));
  }
  switch (value) {
    case Beard.Full:
      return "Full beard";
    case Beard.Goatee:
      return "Goatee";
    case Beard.Mustache:
      return "Mustache";
    case Beard.No:
      return "No beard";
    case Beard.Short:
      return "Short beard";
    case Beard.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function tattooString(
  value: Tattoo,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(tattooString(value));
  }
  switch (value) {
    case Tattoo.Many:
      return "Many tattoos";
    case Tattoo.No:
      return "No tattoos";
    case Tattoo.One:
      return "One tattoo";
    case Tattoo.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function livingString(
  value: Living,
  translate?: (text: string) => string
): string {
  if (translate) {
    return translate(livingString(value));
  }
  switch (value) {
    case Living.Apartment:
      return "Apartment";
    case Living.Hotel:
      return "Hotel";
    case Living.House:
      return "House";
    case Living.WithParents:
      return "With parents";
    case Living.Undisclosed:
      return "Prefer not to say";
    default:
      return "";
  }
}

export function lengthString(
  value: number,
  translate?: (text: string) => string
): string {
  if (value === 0) {
    return translate ? translate("Prefer not to say") : "Prefer not to say";
  } else {
    return `${value} cm`;
  }
}

export function weightString(
  value: number,
  translate?: (text: string) => string
): string {
  if (value === 0) {
    return translate ? translate("Prefer not to say") : "Prefer not to say";
  } else {
    return `${value} kg`;
  }
}

export function chipEnumAsString(
  value: string,
  translate?: (text: string) => string
): string {
  if (!value) {
    return value;
  }
  // Turn _AND_ into " & "
  let result = value.replaceAll("_AND_", " & ");
  result = result.replaceAll("_", " ");
  result = result.toLowerCase();

  const capitalized = result.charAt(0).toUpperCase() + result.slice(1);

  return translate ? translate(capitalized) : capitalized;
}

export function textToHtml(value: string): string {
  //let result = value.replaceAll("\n", "\r\n");
  let result = value.replaceAll("\n", "<br/>");

  // Fix emojis.
  result = colonsToEmoji(result);

  // Sanitize html so we do not allowe it
  const clean = sanitizeHtml(result, {
    allowedTags: ["br"],
    allowedAttributes: {},
    allowedIframeHostnames: [],
  });

  return clean;
}

export type ProfileProgressResult = {
  progress: number;
  step: number;
  stepsCompleted: boolean[];
};

/**
 *
 * @param me
 * @param profile
 * @returns A number 0-100 represering progress in percent and step indicating where to go in the profile wizard.
 */
export function calculateProfileProgress(
  me: Me,
  profile: Profile
): ProfileProgressResult {
  let progress = 0;
  let step = -1;

  const stepsCompleted: boolean[] = [];

  if (!profile) {
    for (let i = 0; i < 9; i++) {
      stepsCompleted.push(false);
    }
    return {
      progress: 0,
      step: 0,
      stepsCompleted: stepsCompleted,
    };
  }

  // Step 0 photo
  if (me.profilePhoto && me.profilePhoto.uploadComplete) {
    progress++;
    stepsCompleted.push(true);
  } else {
    step = 0;
    stepsCompleted.push(false);
  }

  // Step 1 name
  if (profile.firstName) {
    progress++;
  } else {
    step = step >= 0 ? step : 1;
  }
  if (profile.lastName) {
    progress++;
  } else {
    step = step >= 0 ? step : 1;
  }
  stepsCompleted.push(profile.firstName && profile.lastName ? true : false);

  // step 2 hereto
  // this is always set, radio options
  stepsCompleted.push(true);

  // step 3 location
  if (profile.location?.city) {
    progress++;
  } else {
    step = step >= 0 ? step : 3;
  }
  if (profile.location?.country) {
    progress++;
  } else {
    step = step >= 0 ? step : 3;
  }
  if (profile.location?.county) {
    progress++;
  } else {
    step = step >= 0 ? step : 3;
  }
  stepsCompleted.push(
    profile.location?.city &&
      profile.location?.country &&
      profile.location.county
      ? true
      : false
  );

  // step 4 appearance
  if (profile.length && profile.length > 0) {
    progress++;
  } else {
    step = step >= 0 ? step : 4;
  }
  if (profile.weight && profile.weight > 0) {
    progress++;
  } else {
    step = step >= 0 ? step : 4;
  }
  if (profile.eyeColor && profile.eyeColor !== EyeColor.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 4;
  }
  if (profile.hairColor && profile.hairColor !== HairColor.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 4;
  }
  if (profile.beard && profile.beard !== Beard.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 4;
  }
  if (profile.tattoo && profile.tattoo !== Tattoo.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 4;
  }
  stepsCompleted.push(
    profile.length &&
      profile.weight &&
      profile.eyeColor &&
      profile.eyeColor !== EyeColor.Undisclosed &&
      profile.hairColor &&
      profile.hairColor !== HairColor.Undisclosed &&
      profile.beard &&
      profile.beard !== Beard.Undisclosed &&
      profile.tattoo &&
      profile.tattoo !== Tattoo.Undisclosed
      ? true
      : false
  );

  // Step 5 personal info
  if (profile.describeYourself && profile.describeYourself.length > 100) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  if (profile.etnicity && profile.etnicity !== Etnicity.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  if (
    profile.relationshipStatus &&
    profile.relationshipStatus !== RelationshipStatus.Undisclosed
  ) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  if (profile.sexuality && profile.sexuality !== Sexuality.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  if (profile.smoking && profile.smoking !== SmokingHabit.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  if (profile.alcohol && profile.alcohol !== AlcoholHabit.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  if (profile.kids && profile.kids !== KidsStatus.Undisclosed) {
    progress++;
  } else {
    step = step >= 0 ? step : 5;
  }
  stepsCompleted.push(
    profile.describeYourself &&
      profile.describeYourself.length > 100 &&
      profile.etnicity &&
      profile.etnicity !== Etnicity.Undisclosed &&
      profile.relationshipStatus &&
      profile.relationshipStatus !== RelationshipStatus.Undisclosed &&
      profile.sexuality &&
      profile.sexuality !== Sexuality.Undisclosed &&
      profile.smoking &&
      profile.smoking !== SmokingHabit.Undisclosed &&
      profile.alcohol &&
      profile.alcohol !== AlcoholHabit.Undisclosed &&
      profile.kids &&
      profile.kids !== KidsStatus.Undisclosed
      ? true
      : false
  );

  // step 6 work
  if (profile.work?.company) {
    progress++;
  } else {
    step = step >= 0 ? step : 6;
  }
  if (profile.work?.education) {
    progress++;
  } else {
    step = step >= 0 ? step : 6;
  }
  if (profile.work?.title) {
    progress++;
  } else {
    step = step >= 0 ? step : 6;
  }
  stepsCompleted.push(
    profile.work?.company && profile.work?.education && profile.work?.title
      ? true
      : false
  );

  // Step 7 interests
  if (profile.interests && profile.interests.length >= 3) {
    progress++;
  } else {
    step = step >= 0 ? step : 7;
  }
  stepsCompleted.push(
    profile.interests && profile.interests.length >= 3 ? true : false
  );

  // Step 8 languages
  if (profile.languages && profile.languages.length > 0) {
    progress++;
  } else {
    step = step >= 0 ? step : 8;
  }
  stepsCompleted.push(
    profile.languages && profile.languages.length > 0 ? true : false
  );

  // Total 24.
  return {
    progress: (progress / 24) * 100,
    step: step >= 0 ? step : 0,
    stepsCompleted,
  };
}
