import { useMutation } from "@apollo/client";
import { Alert } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { logger } from "util/log";
import { CloseButton } from "../../components/Button/Button";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import FormikControl from "../../components/Form/FormikControl";
import HrDivider from "../../components/HrDivider/HrDivider";
import {
  ModalFooter,
  ModalHeader,
  StyledModal,
  SubmitButton,
} from "../../components/Modal/Modal";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";
import { XButton } from "../../components/Styled/XButton";
import { RESET_PASSWORD } from "../../graphql/mutations-gql";

const InfoContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ForgotPasswordModal: React.FC<{
  show: boolean;
  hide: () => void;
}> = ({ show, hide }) => {
  const [resetPassword, { data, loading, error, reset }] =
    useMutation(RESET_PASSWORD);
  const { t } = useTranslation();

  return (
    <StyledModal open={show} onClose={() => hide()}>
      <ModalHeader>
        <Text weight={600} size={18}>
          {t("Forgot password?")}
        </Text>
        <XButton width={24} onClick={() => hide()} />
      </ModalHeader>

      <HrDivider />

      <InfoContainer>
        <Text>
          {t(
            "Here you can request a password reset link to be sent to your email."
          )}
        </Text>
      </InfoContainer>

      <Formik
        initialValues={{ email: "", password: "" }}
        validateOnBlur={false}
        validate={(values) => {
          const errors: {
            email?: string | undefined;
          } = {};
          if (!values.email) {
            errors.email = t("Required") ?? "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email =
              t("Invalid email address") ?? "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          logger.log("Submitting");
          resetPassword({
            context: { service: "api-auth" },
            variables: {
              email: values.email,
            },
            onCompleted: (result) => {
              logger.log("result", result);
              logger.log("data", data);
              setSubmitting(false);
            },
            onError: (error) => {
              setSubmitting(false);
              logger.log("got an error: " + error);
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form id="sendForm" noValidate onSubmit={handleSubmit}>
            <FlexCol>
              <FlexRow>
                <FormikControl
                  type="text"
                  constrolId="forgotPasswordEmail"
                  label={t("Email address")}
                  name="email"
                  value={values.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                  placeholder={t("Type your email")}
                />
              </FlexRow>

              {error && (
                <FlexRow>
                  <ErrorAlert
                    key="error_alert"
                    error={error}
                    onClose={() => reset()}
                  />
                </FlexRow>
              )}

              {data && (
                <FlexRow>
                  <Alert severity="success">
                    <Text>
                      {t(
                        "An email has been sent! Please check your inbox and click on the link to reset your password."
                      )}
                    </Text>
                  </Alert>
                </FlexRow>
              )}
            </FlexCol>
          </Form>
        )}
      </Formik>

      <HrDivider />

      <ModalFooter>
        <CloseButton
          onClick={() => {
            reset();
            hide();
          }}
        >
          {t("Close")}
        </CloseButton>
        <SubmitButton type="submit" form="sendForm" disabled={loading || data}>
          {loading ? t("Sending email...") : t("Send password reset email")}
        </SubmitButton>
      </ModalFooter>
    </StyledModal>
  );
};
