import { BoltIcon } from "@heroicons/react/24/solid";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text from "../../components/Styled/Text";

const ListItem = (props: { title: string; text: string }) => {
  const theme = useTheme();
  return (
    <FlexRow wrap="nowrap" justifyContent="flex-start" alignItems="flex-start">
      <FlexRow>
        <BoltIcon width={32} color={theme.colors.primary} />
      </FlexRow>
      <FlexCol padding={0}>
        <Text size={28} weight={900}>
          {props.title}
        </Text>
        <Text size={18} weight={400}>
          {props.text}
        </Text>
      </FlexCol>
    </FlexRow>
  );
};

const FeatureList = () => {
  const { t } = useTranslation();
  return (
    <FlexRow width="100%" justifyContent="center" alignItems="center">
      <FlexCol maxWidth={600} justifyContent="flex-start">
        <ListItem
          title={t("Real-time translated chat")}
          text={t(
            "Chat with anyone in the world in your language and get their replies translated in real-time"
          )}
        />

        <ListItem
          title={t("Translated app to over 25 languages")}
          text={t(
            "We have translated our app and website to over 25 languages so you can use it in your language"
          )}
        />
        <ListItem
          title={t("Update your location to anywhere")}
          text={t(
            "Update your location to anywhere in the world and find nearby people"
          )}
        />
        <ListItem
          title={t("Anti-ghosting chat features")}
          text={t(
            "Anti ghosting chat features to help you get more replies and keep the conversation going"
          )}
        />
        <ListItem
          title={t("Live streaming")}
          text={t(
            "Streaming gives our users a platform that allows them to express themselves. Enjoy watching live streams of eligible singles no matter where you are"
          )}
        />
        <ListItem
          title={t("AI chat coach")}
          text={t(
            "Our AI chat coach will help you get more replies and keep the conversation going by giving you tips and advice based on what you are currently typing in chat"
          )}
        />
        <ListItem
          title={t("AI photo coach")}
          text={t(
            "Our AI photo coach will help you get more matches by giving you tips and advice on how to improve your profile photo"
          )}
        />
        <ListItem
          title={t("Video calls")}
          text={t(
            "Take your conversations beyond text with video calls integrated in the chat"
          )}
        />
        <ListItem
          title={t("Video / Reels uploads")}
          text={t(
            "Express your unique personality with short form video uploads"
          )}
        />
        <ListItem
          title={t("Match Videos")}
          text={t(
            "Go beyond the static profile picture and use a video in the matching process for others to vote on"
          )}
        />
        <ListItem
          title={t("Voice messages")}
          text={t(
            "Send voice messages directly in the chat to make your conversations more personal"
          )}
        />
        <ListItem
          title={t("Voice memos on your profile")}
          text={t(
            "Add voice memo prompts to your profile to help others get to know you better"
          )}
        />
        <ListItem
          title={t("Detailed filters")}
          text={t(
            "Add filters to find exactly the person you are looking for. Want to find someone that is below a certain height or weight? No problem!"
          )}
        />
        <ListItem
          title={t("Image blurring")}
          text={t(
            "Blur received images until you decide to reveal them, no more unwanted surprises"
          )}
        />
        <ListItem
          title={t("Social Networking")}
          text={t(
            "Community features such as: Public, private and hidden groups, posts, comments and replies"
          )}
        />
        <ListItem
          title={t("Social Integrations")}
          text={t(
            "Connect your profile with Facebook, Instagram, TikTok and Spotify"
          )}
        />
        <ListItem
          title={t("Profile Verification")}
          text={t(
            "You will see verification badges on profiles that have been verified by phone number SMS, photo verification and social networks"
          )}
        />
      </FlexCol>
    </FlexRow>
  );
};
export default FeatureList;
