import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { TextField, useTheme } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const StyledEyeIcon = styled(EyeIcon)`
  cursor: pointer;
`;
const StyledEyeSlashIcon = styled(EyeSlashIcon)`
  cursor: pointer;
`;

const ValidateErrors: React.FC<{ errorsJsonString: string }> = ({
  errorsJsonString,
}) => {
  if (!errorsJsonString || errorsJsonString.length < 1) {
    return null;
  }

  let errors;
  try {
    errors = JSON.parse(errorsJsonString);
  } catch (err) {
    return <>{errorsJsonString}</>;
  }

  let i = 0;

  return (
    <>
      {errors.map((error: any) => {
        i++;
        return (
          <span key={"error_message_key_" + i}>
            {error.message}
            <br />
          </span>
        );
      })}
    </>
  );
};

type Props = {
  constrolId: string;
  label: string;
  type: string;
  name: string;
  value: string;
  handleChange: any;
  handleBlur: any;
  placeholder: string;
  error: any;
  touched: any;
  helperText?: string;
  enableShowPassword?: boolean;
};

const inputStyles = {
  fontSize: 15,
  fontWeight: 400,
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};

const FormikControl: React.FC<Props> = ({ ...props }) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(
    props.type === "password" ? false : true
  );

  let InputProps = undefined;
  if (
    props.type === "password" &&
    (props.enableShowPassword === undefined ||
      props.enableShowPassword === true)
  ) {
    InputProps = {
      endAdornment: (
        <IconContainer>
          {showPassword ? (
            <StyledEyeSlashIcon
              width={24}
              color={theme.colors.gray}
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <StyledEyeIcon
              width={24}
              color={theme.colors.gray}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </IconContainer>
      ),
    };
  }

  let type = props.type;
  if (props.type === "password" && showPassword) {
    type = "text";
  }

  return (
    <>
      <StyledTextField
        variant="outlined"
        label={props.label}
        value={props.value}
        name={props.name}
        onChange={(e) => props.handleChange(e)}
        onBlur={(e) => props.handleBlur(e)}
        placeholder={props.placeholder}
        type={type}
        inputProps={{
          style: inputStyles,
        }}
        InputProps={InputProps}
        InputLabelProps={{
          style: inputStyles,
        }}
        error={props.error && props.touched ? true : false}
        helperText={
          props.error && props.touched ? (
            <ValidateErrors errorsJsonString={props.error} />
          ) : props.helperText ? (
            props.helperText
          ) : null
        }
        //color={!props.error && props.value ? "success" : "secondary"}
        color="secondary"
        //focused={props.touched}
      >
        <IconContainer>
          <EyeIcon width={24} color={theme.colors.gray} />
        </IconContainer>
      </StyledTextField>
    </>
  );

  // return (
  //   <>
  //     <FloatingLabel controlId={props.constrolId} label={props.label}>
  //       <Form.Control
  //         type={props.type}
  //         name={props.name}
  //         value={props.value}
  //         onChange={(e) => props.handleChange(e)}
  //         onBlur={(e) => props.handleBlur(e)}
  //         placeholder={props.placeholder}
  //         isInvalid={props.touched && !!props.error}
  //         isValid={props.touched && !props.error}
  //       />
  //       <Form.Control.Feedback type="invalid">
  //         {props.error}
  //       </Form.Control.Feedback>
  //     </FloatingLabel>
  //   </>
  // );
};

export default FormikControl;
