import * as amplitude from "@amplitude/analytics-browser";

export const ampInit = async (username?: string) => {
  if (process.env.NODE_ENV === "production") {
    amplitude.init("b691ab25d97c85141eb31ea36081c0dd", username);
  } else {
    amplitude.init("7f87951af17cf6f2af2d1b741dcbc4ba", username);
  }
};

// Track events to Amplitude.
export const track = async (
  event: string,
  eventProperies?: Record<string, any>,
  username?: string
) => {
  ampInit(username);

  //logger.log("Amplitude event: ", event, eventProperies);
  amplitude.track(event, eventProperies);
  //logger.log("Amplitude result: ", result);
};
