import AppInstall from "pages/app-install/AppInstall";
import VideoDating from "pages/seo-pages/VideoDating";
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/error-page/error-page";
import { RequireAuth } from "./require-auth/RequireAuth";
import Root from "./root/Root";

const PurchasePremium = lazy(
  () => import("pages/purchase-premium/PurchasePremium")
);
const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const Home = lazy(() => import("../pages/home/Home"));
const InternationalDating = lazy(
  () => import("../pages/seo-pages/InternationalDating")
);
const SingleWomenDating = lazy(
  () => import("../pages/seo-pages/SingleWomenDating")
);
const AsianWomenDating = lazy(
  () => import("../pages/seo-pages/AsianWomenDating")
);
const SingleMenDating = lazy(
  () => import("../pages/seo-pages/SingleMenDating")
);
const DeepLinkPage = lazy(() => import("../pages/deep-link/DeepLinkPage"));
const AppPages = lazy(() => import("../pages/app/AppPages"));
const PurchaseCredits = lazy(
  () => import("../pages/purchase-credits/PurchaseCredits")
);
const PasswordResetPage = lazy(
  () => import("../pages/password-reset/PasswordResetPage")
);
const SettingsPage = lazy(() => import("../pages/settings/SettingsPage"));
const GiftsPage = lazy(() => import("../pages/gifts/GiftsPage"));
const GroupPage = lazy(() => import("../pages/group/GroupPage"));
const GroupsPage = lazy(() => import("../pages/groups/GroupsPage"));
const PostPage = lazy(() => import("../pages/post/PostPage"));
const HelpCenter = lazy(() => import("../pages/help-center/HelpCenter"));
const PeopleNearby = lazy(() => import("../pages/people-nearby/PeopleNearby"));
const PeoplePage = lazy(() => import("../pages/people/PeoplePage"));
const ChatPortal = lazy(() => import("../components/ChatPortal/ChatPortal"));
const SearchPage = lazy(() => import("../pages/search/SearchPage"));
const FABMessages = lazy(() => import("../components/FABMessages/FABMessages"));
const UserPage = lazy(() => import("../pages/user/UserPage"));
const PhotoMatch = lazy(() => import("../pages/photo-match/PhotoMatch"));
const DoubleSidebarLayout = lazy(() => import("../layout/DoubleSidebarLayout"));
const MyProfilePage = lazy(() => import("../pages/my-profile/MyProfilePage"));
const VerifyEmailPage = lazy(
  () => import("../pages/verify-email/VerifyEmailPage")
);

// const LangRouter = () => {
//   const { i18n } = useTranslation();
//   const { lang } = useParams();
//   const navigate = useNavigate();
//   const location = useLocation();
//   const curPath = location.pathname;

//   logger.log("lang", lang);

//   useEffect(() => {
//     if (lang) {
//       // If this is not a supported language, redirect to the default language.
//       if (
//         i18n.options.supportedLngs &&
//         i18n.options.supportedLngs.indexOf(lang) === -1
//       ) {
//         // Not existing language. Redirect to default language.
//         logger.log(i18n.languages);
//         logger.log("i18n", JSON.stringify(i18n));
//         const redirect = "/en" + curPath;
//         logger.log("Non existing language: " + lang);
//         logger.log("Redirecting to: " + redirect);
//         navigate(redirect, { replace: true });
//         return;
//       }

//       logger.log("Changing lang to: " + lang);
//       i18n.changeLanguage(lang);
//       localStorage.setItem("lang", lang);
//     }
//   }, [curPath, i18n, lang, navigate]);

//   return <Outlet />;
// };

// Relative paths to the pages
const pages = [
  {
    path: "friends/:page",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "friends",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "matches",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "favorites",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "following",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "followers",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "blocks",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "possible-matches",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "private-media/access",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "visitors",
    element: (
      <RequireAuth>
        <>
          <PeoplePage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "photo-match",
    element: (
      <RequireAuth>
        <>
          <DoubleSidebarLayout>
            <PhotoMatch />
            <FABMessages />
            <ChatPortal />
          </DoubleSidebarLayout>
        </>
      </RequireAuth>
    ),
  },
  {
    path: "people-nearby",
    element: (
      <RequireAuth>
        <>
          <DoubleSidebarLayout>
            <PeopleNearby />
            <FABMessages />
            <ChatPortal />
          </DoubleSidebarLayout>
        </>
      </RequireAuth>
    ),
  },
  {
    path: "purchase-credits",
    element: (
      <RequireAuth>
        <>
          <DoubleSidebarLayout>
            <PurchaseCredits />
            <FABMessages />
            <ChatPortal />
          </DoubleSidebarLayout>
        </>
      </RequireAuth>
    ),
  },
  {
    path: "purchase-premium",
    element: (
      <RequireAuth>
        <>
          <DoubleSidebarLayout>
            <PurchasePremium />
            <FABMessages />
            <ChatPortal />
          </DoubleSidebarLayout>
        </>
      </RequireAuth>
    ),
  },
  {
    path: "verifyemail/:token",
    element: (
      <>
        <VerifyEmailPage />
      </>
    ),
  },
  {
    path: "passwordreset/:token",
    element: (
      <>
        <PasswordResetPage />
      </>
    ),
  },
  {
    path: "my-profile/:tab",
    element: (
      <RequireAuth>
        <>
          <DoubleSidebarLayout>
            <MyProfilePage />
            <FABMessages />
            <ChatPortal />
          </DoubleSidebarLayout>
        </>
      </RequireAuth>
    ),
  },
  {
    path: "my-profile",
    element: (
      <RequireAuth>
        <>
          <DoubleSidebarLayout>
            <MyProfilePage />
            <FABMessages />
            <ChatPortal />
          </DoubleSidebarLayout>
        </>
      </RequireAuth>
    ),
  },
  {
    path: "user/:id",
    element: (
      <RequireAuth>
        <DoubleSidebarLayout>
          <UserPage />
          <FABMessages />
          <ChatPortal />
        </DoubleSidebarLayout>
      </RequireAuth>
    ),
  },
  {
    path: "post/:id",
    element: (
      <RequireAuth>
        <DoubleSidebarLayout>
          <PostPage />
          <FABMessages />
          <ChatPortal />
        </DoubleSidebarLayout>
      </RequireAuth>
    ),
  },
  {
    path: "group/:id",
    element: (
      <RequireAuth>
        <DoubleSidebarLayout>
          <GroupPage />
          <FABMessages />
          <ChatPortal />
        </DoubleSidebarLayout>
      </RequireAuth>
    ),
  },
  {
    path: "groups",
    element: (
      <RequireAuth>
        <DoubleSidebarLayout>
          <GroupsPage />
          <FABMessages />
          <ChatPortal />
        </DoubleSidebarLayout>
      </RequireAuth>
    ),
  },
  {
    path: "gifts",
    element: (
      <RequireAuth>
        <DoubleSidebarLayout>
          <GiftsPage />
          <FABMessages />
          <ChatPortal />
        </DoubleSidebarLayout>
      </RequireAuth>
    ),
  },
  {
    path: "search",
    element: (
      <RequireAuth>
        <DoubleSidebarLayout>
          <SearchPage />
          <FABMessages />
          <ChatPortal />
        </DoubleSidebarLayout>
      </RequireAuth>
    ),
  },
  {
    path: "help-center/:page",
    element: (
      <>
        <HelpCenter />
        <FABMessages />
        <ChatPortal />
      </>
    ),
  },
  {
    path: "help-center",
    element: (
      <>
        <HelpCenter />
        <FABMessages />
        <ChatPortal />
      </>
    ),
  },
  {
    path: "settings",
    element: (
      <RequireAuth>
        <>
          <SettingsPage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },
  {
    path: "settings/:page",
    element: (
      <RequireAuth>
        <>
          <SettingsPage />
          <FABMessages />
          <ChatPortal />
        </>
      </RequireAuth>
    ),
  },

  // disable login page for now with RequireAuth that redirects to app-install
  {
    path: "login",
    element: (
      <RequireAuth>
        <LoginPage />
      </RequireAuth>
    ),
  },
  {
    path: "login/:lang",
    element: (
      <RequireAuth>
        <LoginPage />
      </RequireAuth>
    ),
  },
  {
    path: "international-dating",
    element: <InternationalDating />,
  },
  {
    path: "international-dating/:lang",
    element: <InternationalDating />,
  },
  {
    path: "single-women-dating",
    element: <SingleWomenDating />,
  },
  {
    path: "single-women-dating/:lang",
    element: <SingleWomenDating />,
  },
  {
    path: "single-men-dating",
    element: <SingleMenDating />,
  },
  {
    path: "single-men-dating/:lang",
    element: <SingleMenDating />,
  },
  {
    path: "find-asian-women",
    element: <AsianWomenDating />,
  },
  {
    path: "find-asian-women/:lang",
    element: <AsianWomenDating />,
  },
  {
    path: "video-dating",
    element: <VideoDating />,
  },
  {
    path: "video-dating/:lang",
    element: <VideoDating />,
  },
];

const router = createBrowserRouter([
  {
    path: "/app/:page",
    element: (
      <>
        <AppPages />
      </>
    ),
  },
  {
    path: "/app-install/:affiliate",
    element: <AppInstall />,
  },
  {
    path: "/deep-link/promo/:code",
    element: <DeepLinkPage pathId="promo" />,
  },
  {
    path: "/deep-link/group/:group",
    element: <DeepLinkPage pathId="group" />,
  },
  {
    path: "/deep-link/user/:id",
    element: <DeepLinkPage pathId="user" />,
  },
  {
    path: "/deep-link/promote-reel/:id",
    element: <DeepLinkPage pathId="promote-reel" />,
  },
  {
    path: "/deep-link/premium-offer", // for introductory offers
    element: <DeepLinkPage pathId="premium-offer" />,
  },
  {
    path: "/deep-link/premium-promo", // for special promotions
    element: <DeepLinkPage pathId="premium-promo" />,
  },
  {
    path: "/deep-link/premium-winback", // for winback promotions
    element: <DeepLinkPage pathId="premium-winback" />,
  },
  {
    path: "/deep-link/my-reels",
    element: <DeepLinkPage pathId="my-reels" />,
  },
  {
    path: "/deep-link/oauth/redirect",
    element: <DeepLinkPage pathId="oauth" />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        // element: (
        //   <RequireAuth>
        //     <DoubleSidebarLayout>
        //       <Home />
        //       <FABMessages />
        //       <ChatPortal />
        //     </DoubleSidebarLayout>
        //   </RequireAuth>
        // ),
        element: <AppInstall />,
      },
      ...pages,
    ],
  },
]);

export default router;
