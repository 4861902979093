import { lazy } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { useAuthStore } from "../../hooks/useAuth";

const PopularityDialog = lazy(
  () => import("../../components/dialogs/PopularityDialog/PopularityDialog")
);
const BoostDialog = lazy(
  () => import("../../components/dialogs/BoostDialog/BoostDialog")
);
const MoreVotesDialog = lazy(
  () => import("../../components/dialogs/MoreVotesDialog/MoreVotesDialog")
);
const HotLikeDialog = lazy(
  () => import("../../components/dialogs/HotLikeDialog/HotLikeDialog")
);
const ReelDialog = lazy(
  () => import("../../components/dialogs/ReelDialog/ReelDialog")
);
const UploadReelDialog = lazy(
  () => import("../../components/dialogs/UploadReelDialog/UploadReelDialog")
);
const VideoDialog = lazy(
  () => import("../../components/dialogs/VideoDialog/VideoDialog")
);
const SpotlightDialog = lazy(
  () => import("../../components/dialogs/SpotlightDialog/SpotlightDialog")
);
const PeopleFilterDialog = lazy(
  () => import("../../components/dialogs/PeopleFilterDialog/PeopleFilterDialog")
);
const PremiumDialog = lazy(
  () => import("../../components/dialogs/PremiumDialog/PremiumDialog")
);
const InviteDialog = lazy(
  () => import("../../components/dialogs/InviteDialog/InviteDialog")
);
const CreditsDialog = lazy(
  () => import("../../components/dialogs/CreditsDialog/CreditsDialog")
);
const ReportDialog = lazy(
  () => import("../../components/dialogs/ReportDialog/ReportDialog")
);
const NavbarHome = lazy(() => import("../../components/NavbarHome/NavbarHome"));
const NavbarPublic = lazy(
  () => import("../../components/NavbarPublic/NavbarPublic")
);

const FullHeightPageContainer = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.bodyBackground};
  margin-top: -56px;
  padding-top: 56px;
  //padding-left: 20px;
  //padding-right: 20px;

  @media (max-width: 720px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

export default function Root() {
  const authenticated = useAuthStore.use.authenticated();

  if (authenticated) {
    return (
      <>
        <NavbarHome />
        <ReportDialog />
        <CreditsDialog />
        <InviteDialog />
        <PremiumDialog />
        <PeopleFilterDialog />
        <SpotlightDialog />
        <VideoDialog />
        <UploadReelDialog />
        <ReelDialog />
        <HotLikeDialog />
        <MoreVotesDialog />
        <BoostDialog />
        <PopularityDialog />

        <FullHeightPageContainer>
          <div id="page-container-wrapper">
            <Outlet />
          </div>
        </FullHeightPageContainer>
      </>
    );
  } else {
    return (
      <>
        {/* <NavbarPublic /> */}

        <div id="page-container">
          <Outlet />
        </div>
      </>
    );
  }
}
