import { useMutation } from "@apollo/client";
import { CircularProgress, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { logger } from "util/log";
import Button from "../../components/Button/Button";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import FormikControl from "../../components/Form/FormikControl";
import HrDivider from "../../components/HrDivider/HrDivider";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { StyledLink } from "../../components/Styled/StyledLink";
import Text from "../../components/Styled/Text";
import { LOGIN } from "../../graphql/mutations-gql";
import { useAuthStore } from "../../hooks/useAuth";
import { track } from "../../util/amplitude";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import { SignupModal } from "./SignupModal";

const SignupButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.green};
  width: "fit-content" !important;
  align-self: center;
`;

const LoginButton = styled(Button)`
  width: 304px;
`;

const LoginForm: React.FC<{
  invite: string | null;
  signupRef: string | null;
}> = ({ invite, signupRef }) => {
  const [showSignup, setShowSignup] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [login, { loading, error, reset }] = useMutation(LOGIN);
  const authenticated = useAuthStore.use.authenticated();
  const setAuthenticated = useAuthStore.use.setAuthenticated();
  const [captcha, setCaptcha] = useState<string | null>(null);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const onCaptchaChange = (value: any) => {
    logger.log("captcha change", value);
    setCaptcha(value);
  };

  if (authenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <SignupModal
        show={showSignup}
        hide={() => setShowSignup(false)}
        invite={invite}
        signupRef={signupRef}
      />
      <ForgotPasswordModal
        show={showForgotPasswordModal}
        hide={() => setShowForgotPasswordModal(false)}
      />

      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors: {
            username?: string;
            password?: string;
          } = {};

          if (!values.username || values.username.length < 3) {
            errors.username =
              t("You must type a username of atleast 3 characters.") ??
              "You must type a username of atleast 3 characters.";
          }
          if (!values.password || values.password.length < 1) {
            errors.password =
              t("You must type a valid password.") ??
              "You must type a valid password.";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          login({
            context: { service: "api-auth" },
            variables: {
              args: {
                username: values.username,
                password: values.password,
                captcha: captcha,
                appPlatform: "web",
              },
            },
            onCompleted: (login) => {
              logger.log("login", login);
              track("Web Login Success", { loginType: "E-mail" });
              setAuthenticated(login.login.authenticated);
              localStorage.setItem("auth_token", login.login.token);
            },
            onError: (error) => {
              logger.log("got an error: " + error);
              recaptchaRef.current?.reset();
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          /* and other goodies */
        }) => {
          return (
            <Form id="loginForm" noValidate onSubmit={handleSubmit}>
              <FlexCol>
                <FlexRow>
                  <FormikControl
                    type="text"
                    constrolId="loginEmail"
                    label={t("Email address or username")}
                    name="username"
                    value={values.username}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.username}
                    touched={touched.username}
                    placeholder={t("Type your email or username")}
                  />
                </FlexRow>
                <FlexRow>
                  <FormikControl
                    type="password"
                    constrolId="loginPassword"
                    enableShowPassword={false}
                    label={t("Password")}
                    name="password"
                    value={values.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    placeholder={t("Type your password")}
                  />
                </FlexRow>
                <FlexRow justifyContent="center">
                  {theme.name === "dark" && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      className="recaptcha-class"
                      theme={"dark"}
                      sitekey="6Lfzc-4SAAAAAByyUavMBhn0QmfShVQunuiCupto"
                      onChange={(value: any) => onCaptchaChange(value)}
                      hl={i18n.language}
                    />
                  )}
                  {theme.name !== "dark" && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      theme={"light"}
                      sitekey="6Lfzc-4SAAAAAByyUavMBhn0QmfShVQunuiCupto"
                      onChange={(value: any) => onCaptchaChange(value)}
                      hl={i18n.language}
                    />
                  )}
                </FlexRow>

                <FlexRow justifyContent="center">
                  <LoginButton type="submit" disabled={loading || !captcha}>
                    {loading ? (
                      <>
                        {t("Signing in...")} <CircularProgress size={18} />
                      </>
                    ) : (
                      <>{t("Sign me in!")}</>
                    )}
                  </LoginButton>
                </FlexRow>

                <FlexRow>
                  <Text size={12} color={theme.colors.gray}>
                    {t("By clicking")} {t("Sign me in!")},{" "}
                    {t("you agree to our")}{" "}
                    <StyledLink to="/help-center/terms">
                      {t("Terms")}
                    </StyledLink>
                    {". "}{" "}
                    {t("Learn how we collect, use and share your data in our")}{" "}
                    <StyledLink to="/help-center/privacy">
                      {t("Privacy policy")}
                    </StyledLink>{" "}
                    {t("and how we use cookies and similar technology in our")}{" "}
                    <StyledLink to="/help-center/cookies">
                      {t("Cookie policy")}
                    </StyledLink>
                    .
                  </Text>
                </FlexRow>

                {error && (
                  <FlexRow>
                    <ErrorAlert
                      key="error_alert"
                      error={error}
                      onClose={() => reset()}
                    />
                  </FlexRow>
                )}
              </FlexCol>
            </Form>
          );
        }}
      </Formik>

      <FlexCol padding={0} rowGap={15}>
        <FlexRow justifyContent="center">
          <StyledLink to="" onClick={() => setShowForgotPasswordModal(true)}>
            <Text>{t("Forgotten password?")}</Text>
          </StyledLink>
        </FlexRow>

        <HrDivider text={t("or sign up") ?? "or sign up"} />

        <SignupButton onClick={() => setShowSignup(true)}>
          {t("Signup with e-mail")}
        </SignupButton>
      </FlexCol>
      <div style={{ height: "10px" }} />
    </>
  );
};

export default LoginForm;
