import styled, { css } from "styled-components";

export const FlexRow = styled.div<{
  justifyContent?: string;
  marginLeft?: number;
  marginRight?: number;
  colGap?: number;
  rowGap?: number;
  selected?: boolean;
  selectedBackgroundColor?: string;
  wrap?: string;
  alignItems?: string;
  onClick?: (event: any) => void;
  width?: string;
  textAlign?: string;
  maxWidth?: string;
}>`
  //margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  column-gap: ${(props) =>
    props.colGap !== undefined ? props.colGap + "px" : "5px"};
  row-gap: ${(props) =>
    props.rowGap !== undefined ? props.rowGap + "px" : "10px"};
  flex-wrap: ${(props) => (props.wrap !== undefined ? props.wrap : "wrap")};
  background-color: ${(props) =>
    props.selected !== undefined &&
    props.selected &&
    props.selectedBackgroundColor !== undefined
      ? props.selectedBackgroundColor
      : "inherit"};
  ${(props) =>
    props.onClick
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : null};
  ${(props) =>
    props.marginLeft !== undefined
      ? "margin-left: " + props.marginLeft + "px;"
      : ""}
  ${(props) =>
    props.marginRight !== undefined
      ? "margin-right: " + props.marginRight + "px;"
      : ""}
  ${(props) => (props.width !== undefined ? "width: " + props.width + ";" : "")}
  ${(props) =>
    props.textAlign !== undefined ? "text-align: " + props.textAlign + ";" : ""}
  ${(props) =>
    props.maxWidth !== undefined ? "max-width: " + props.maxWidth + ";" : ""}
`;

export const FlexRowHover = styled(FlexRow)`
  border-radius: 8px;
  padding: 10px;
  //background-color: "inherit";
  //column-gap: 10px;

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.buttonBgSelected
        : props.theme.inputBg} !important;
    transition: 0.2s;
    cursor: pointer;
  }
`;

export const ContainerItemRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FlexCol = styled.div<{
  padding?: number;
  justifyContent?: string;
  alignItems?: string;
  rowGap?: number;
  height?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  paddingRight?: number;
  paddingTop?: number;
  paddingBottom?: number;
  maxWidth?: number;
}>`
  padding: ${(props) =>
    props.padding || props.padding === 0 ? props.padding : 10}px;
  ${(props) =>
    props.paddingLeft !== undefined &&
    "padding-left: " + props.paddingLeft + "px;"}
  ${(props) =>
    props.paddingBottom !== undefined &&
    "padding-bottom: " + props.paddingBottom + "px;"}
  ${(props) =>
    props.maxWidth !== undefined && "max-width: " + props.maxWidth + "px;"}
  ${(props) =>
    props.paddingRight !== undefined &&
    "padding-right: " + props.paddingRight + "px;"}
  ${(props) =>
    props.paddingTop !== undefined &&
    "padding-top: " + props.paddingTop + "px;"}
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "auto")};
  row-gap: ${(props) =>
    props.rowGap !== undefined ? props.rowGap + "px" : "12px"};
  ${(props) =>
    props.height !== undefined ? "height: " + props.height + ";" : ""};
  ${(props) =>
    props.backgroundColor !== undefined
      ? "background-color: " + props.backgroundColor + ";"
      : ""};
`;
export const Row = FlexRow;
export const Col = FlexCol;

export const ContainerMaxWidth = styled(FlexCol)`
  width: 100%;
`;

export const Title = styled.div`
  //margin-bottom: 0px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
