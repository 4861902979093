import { Theme } from "@mui/material/styles";
import styled, { useTheme } from "styled-components";
import Text from "../Styled/Text";

const DividerContainerDark = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;

  .hr-before {
    flex-grow: 5;

    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to left,
      rgba(50, 50, 50, 0.8),
      rgba(50, 50, 50, 0.2)
    );
  }
  .hr-after {
    flex-grow: 5;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(50, 50, 50, 0.8),
      rgba(50, 50, 50, 0.2)
    );
  }
`;

const DividerContainerLight = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;

  .hr-before {
    flex-grow: 5;

    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.25)
    );
  }
  .hr-after {
    flex-grow: 5;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.25)
    );
  }
`;

const HrBefore = styled.hr`
  flex-grow: 5;

  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.25)
  );
`;

const HrAfter = styled.hr`
  flex-grow: 5;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.25)
  );
`;

const HrText = styled(Text)<{ textOpacity?: number }>`
  opacity: ${(props) =>
    props.textOpacity !== undefined ? props.textOpacity : 0.3};
  padding-left: 10px;
  padding-right: 10px;
`;

const HrDivider: React.FC<{
  text?: string | null;
  color?: string;
  textOpacity?: number;
}> = ({ text, color, textOpacity }) => {
  const theme = useTheme() as Theme;

  return (
    <>
      {theme.name === "dark" && (
        <DividerContainerDark>
          <HrBefore />
          {text && (
            <HrText
              size={12}
              color={color ? color : theme.buttonFg}
              textOpacity={textOpacity}
            >
              {text}
            </HrText>
          )}
          <HrAfter />
        </DividerContainerDark>
      )}
      {theme.name === "light" && (
        <DividerContainerLight>
          <HrBefore />
          {text && (
            <HrText
              size={12}
              color={color ? color : theme.buttonFg}
              textOpacity={textOpacity}
            >
              {text}
            </HrText>
          )}
          <HrAfter />
        </DividerContainerLight>
      )}
    </>
  );
};

export default HrDivider;
