import { useState } from "react";
import LocationDialog from "../components/dialogs/LocationDialog/LocationDialog";
import { ProfileLocationInput } from "../graphql/graphql";

export type UseLocationDialog = {
  showDialog: () => void;
  component: JSX.Element;
};

function useLocationDialog(
  initLocation?: ProfileLocationInput | null,
  onSave?: (location: ProfileLocationInput) => void
) {
  const [open, setOpen] = useState(false);

  const showDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return {
    showDialog,
    closeDialog,
    component: (
      <LocationDialog
        open={open}
        setOpen={setOpen}
        initLocation={initLocation}
        onSave={onSave}
      />
    ),
  };
}
export default useLocationDialog;
