import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

declare module "@mui/material/styles" {
  interface Theme {
    name: string;
    bodyBackground: string;
    pageBackground: string;
    textColor: string;
    secondaryTextColor: string;
    buttonBg: string;
    buttonBgSelected: string;
    buttonFg: string;
    buttonHoverBg: string;
    badgeBg: string;
    badgeFg: string;
    linkHoverBg: string;
    linkColor: string;
    linkHoverColor: string;
    appearChipBg: string;
    appearChipFg: string;
    listItemHoverBg: string;
    listItemOnBodyHoverBg: string;
    typingIndicator: {
      bg: string;
      fg: string;
    };
    inputBg: string;
    colors: {
      primary: string;
      secondary: string;
      green: string;
      purple: string;
      gray: string;
      blue: string;
      error: string;
      red: string;
      orange: string;
      white: string;
      pink: string;
    };
    popularity: {
      veryLow: string;
      low: string;
      medium: string;
      high: string;
      popular: string;
      veryPopular: string;
    };
    divider: {
      rgba: number;
    };
    bg1: string;
    bg2: string;
    bg3: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    name: string;
    bodyBackground: string;
    pageBackground: string;
    textColor: string;
    secondaryTextColor: string;
    buttonBg: string;
    buttonBgSelected: string;
    buttonFg: string;
    buttonHoverBg: string;
    badgeBg: string;
    badgeFg: string;
    linkHoverBg: string;
    linkColor: string;
    linkHoverColor: string;
    appearChipBg: string;
    appearChipFg: string;
    listItemHoverBg: string;
    listItemOnBodyHoverBg: string;
    typingIndicator: {
      bg: string;
      fg: string;
    };
    inputBg: string;
    colors: {
      primary: string;
      secondary: string;
      green: string;
      purple: string;
      gray: string;
      blue: string;
      error: string;
      red: string;
      orange: string;
      white: string;
      pink: string;
    };
    popularity: {
      veryLow: string;
      low: string;
      medium: string;
      high: string;
      popular: string;
      veryPopular: string;
    };
    divider: {
      rgba: number;
    };
    bg1: string;
    bg2: string;
    bg3: string;
  }
}

export const lightTheme = createTheme({
  name: "light",
  //bodyBackground: "#F3F2EF",
  bodyBackground: "#f0f2f5",
  pageBackground: "#FFF",
  textColor: "#050505",
  secondaryTextColor: "#8a8a8a",
  buttonBg: "#E5E6EB",
  buttonBgSelected: "#E9F3FF",
  buttonFg: "#050505",
  buttonHoverBg: "#d5d7dc",
  badgeBg: "#F6F6F6",
  badgeFg: "#8a8a8a",
  linkHoverBg: "#e5e5e5",
  linkColor: "#9034ae",
  linkHoverColor: "#9034ae",
  appearChipBg: "#FDEDF3",
  appearChipFg: "#E0357D",
  listItemHoverBg: "#F2F2F2",
  listItemOnBodyHoverBg: "#E4E6E8",
  typingIndicator: {
    bg: "#b4b5b9",
    fg: "#e6e7ed",
  },
  inputBg: "#f1f2f5",
  palette: {
    // primary: {
    //   main: "#E0357D",
    // },
    secondary: {
      main: "#E0357D",
    },
  },
  colors: {
    //primary: "#9034ae",
    primary: "#f711b9", // original
    //primary: "#F2597A",
    //primary: "#fb034c",
    secondary: "#dedede",
    green: "#42b72a",
    purple: "#9c09db",
    gray: "#66676B",
    blue: "#3472CF",
    error: "#CD5146",
    red: "#CD5146",
    orange: "#F2B42C",
    white: "#FFF",
    pink: "#cc0884",
  },
  popularity: {
    veryLow: "#CD5146",
    low: "#F2B42C",
    medium: "#3472CF",
    high: "#42b72a",
    popular: "#E0357D",
    veryPopular: "#E0357D",
  },
  divider: {
    rgba: 0,
  },
  bg1: "#fafbfc",
  bg2: "#f5f5f5",
  //bg2: "#f9f9f9",
  bg3: "#f5f6f9",
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   main: "#C995D6",
    // },
    secondary: {
      main: "#C995D6",
      contrastText: "#FFF",
    },
  },
  name: "dark",
  textColor: "#FFF",
  secondaryTextColor: "#8a8a8a",
  bodyBackground: "#18191A",
  pageBackground: "#242526",
  buttonBg: "#3A3B3C",
  buttonBgSelected: "#2B3850",
  buttonFg: "#FFF",
  buttonHoverBg: "#494a4b",
  badgeBg: "#3A3B3C",
  badgeFg: "#FFF",
  linkHoverBg: "#27292a",
  linkColor: "#c06cdc",
  linkHoverColor: "#c06cdc",
  appearChipBg: "#BC6D9C",
  appearChipFg: "#FFF",
  listItemHoverBg: "#3A3B3C",
  listItemOnBodyHoverBg: "#303031",
  typingIndicator: {
    bg: "#18191A",
    fg: "#3A3B3C",
  },
  inputBg: "#3A3B3C",
  colors: {
    //primary: "#9034ae",
    //primary: "#C995D6",
    primary: "#f711b9",
    //primary: "#fb034c",
    secondary: "#dedede",
    green: "#42b72a",
    //purple: "#6c38fa",
    purple: "#9c09db",
    gray: "#787A7D",
    blue: "#9AC8F7",
    error: "#E5543D",
    red: "#E5543D",
    orange: "#F2B42C",
    white: "#FFF",
    pink: "#cc0884",
  },
  popularity: {
    veryLow: "#E5543D",
    low: "#F2B42C",
    medium: "#9AC8F7",
    high: "#42b72a",
    popular: "#F2597A",
    veryPopular: "#F2597A",
  },
  divider: {
    rgba: 200,
  },
  bg1: "#18191A",
  bg2: "#3A3B3C",
  bg3: "#18191A",
});
