import { gql } from "@apollo/client";
import * as fragments from "./fragments-gql";

export const HELLO = gql`
  query hello($name: String) {
    hello(name: $name)
  }
`;

export const PHOTO = gql`
  ${fragments.PHOTO_FRAGMENT}
  query photo($uploadId: String!) {
    photo(uploadId: $uploadId) {
      ...PhotoFragment
    }
  }
`;

export const ME = gql`
  ${fragments.PHOTO_FRAGMENT}
  query me($currentAppVersion: String) {
    me(currentAppVersion: $currentAppVersion) {
      _id
      username
      email
      lastActive
      readyToChatDate
      deactivated
      profilePhotoUploadId
      profilePhotoApproved
      mood
      moodDate
      admin
      numberOfVotes
      votesDate
      snapFlirts
      invitedMemberCount
      popularity
      phoneNumber
      verifiedPhone
      boosting
      boostExpiresDate
      freeSpinDate
      locationUpdateDate
      hotLikes
      hotLikesDate
      matchScore
      language
      showOnlineStatus
      profilePhoto {
        ...PhotoFragment
      }
      account {
        credits
        premium
        vip
        premiumDate
        availableTranslationAmount
      }
      rewardedAdsAvailable
      boostOfferExpireDate
      spotlightOfferExpireDate
    }
  }
`;

export const ME_PROFILE = gql`
  query meProfile {
    meProfile {
      _id
      firstName
      lastName
      birthDate
      gender
      lookingFor
      birthDateChanged
      hereTo
      interests
      personality
      languages
      eyeColor
      hairColor
      alcohol
      smoking
      etnicity
      relationshipStatus
      sexuality
      length
      weight
      beard
      tattoo
      kids
      describeYourself
      living
      religion
      zodiac
      work {
        title
        company
        education
      }
      location {
        _id
        country
        county
        city
        geo {
          _id
          type
          coordinates
        }
      }
    }
  }
`;

export const ME_SETTINGS = gql`
  query meSettings {
    meSettings {
      freeSpinDate
      settings {
        language
        notifications {
          newMessages {
            push
            email
            sms
          }
          comments {
            push
            email
            sms
          }
          replies {
            push
            email
            sms
          }
          invites {
            push
            email
            sms
          }
          friendRequests {
            push
            email
            sms
          }
          newPosts {
            push
            email
            sms
          }
          matches {
            push
            email
            sms
          }
          dailySpinReminder {
            push
            email
            sms
          }
          updatesFollowing {
            push
            email
            sms
          }
          updatesFriends {
            push
            email
            sms
          }
          updatesGroups {
            push
            email
            sms
          }
          updatesMatches {
            push
            email
            sms
          }
          signupNotifications {
            push
            email
            sms
          }
          updatesOnline {
            push
            email
            sms
          }
        }
        messages {
          allowNewFrom
        }
        showOnlineStatus
        allowGroupInvites
        allowFriendRequests
        allowBlindDateInvites
        incognito
      }
    }
  }
`;

export const ME_PHOTO_VERIFICATION = gql`
  ${fragments.PHOTO_FRAGMENT}
  query mePhotoVerification {
    mePhotoVerification {
      status
      photo {
        ...PhotoFragment
      }
    }
  }
`;

export const ME_MATCH_VIDEO = gql`
  ${fragments.VIDEO_FRAGMENT}
  query meMatchVideo {
    meMatchVideo {
      ...VideoFragment
    }
  }
`;

export const ME_INTEGRATIONS = gql`
  query meIntegrations {
    meIntegrations {
      instagram {
        _id
        updatedDate
        media {
          id
          url
          type
          thumbnailUrl
          visible
        }
        instagramUserId
        username
      }
      tiktok {
        _id
        updatedDate
        media {
          id
          embed_link
          cover_image_url
        }
      }
      spotify {
        _id
        display_name
        id
        media {
          recent_track {
            name
            played_at
            external_url
            image
            artist
          }
          top_artists {
            name
            image
            external_url
            followers
            popularity
          }
          top_tracks {
            name
            played_at
            external_url
            image
            artist
          }
        }
      }
      facebook {
        _id
        facebookId
        email
      }
      google {
        _id
        email
      }
    }
  }
`;

export const ME_VOICE_PROMPTS = gql`
  query meVoicePrompts {
    meVoicePrompts {
      text
      uploadId
      url
      uploadComplete
    }
  }
`;

export const ME_PHOTOS = gql`
  ${fragments.PHOTO_FRAGMENT}
  query mePhotos {
    mePhotos {
      ...PhotoFragment
    }
  }
`;

export const ME_PRIVATE_PHOTOS = gql`
  ${fragments.PHOTO_FRAGMENT}
  query mePrivatePhotos {
    mePrivatePhotos {
      ...PhotoFragment
    }
  }
`;

export const ME_VIDEOS = gql`
  ${fragments.VIDEO_FRAGMENT}
  query meVideos {
    meVideos {
      ...VideoFragment
    }
  }
`;

export const ME_PRIVATE_VIDEOS = gql`
  ${fragments.VIDEO_FRAGMENT}
  query mePrivateVideos {
    mePrivateVideos {
      ...VideoFragment
    }
  }
`;

export const ME_FAVORITES = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query meFavorites {
    meFavorites {
      created
      user {
        ...UserCardFragment
      }
    }
  }
`;

export const ME_FILTER = gql`
  query meFilter {
    meFilter {
      distance
      maxAge
      minAge
      genders
      sexuality
      minLength
      maxLength
      minWeight
      maxWeight
      hereTo
      relationshipStatus
      eyeColor
      hairColor
      tattoo
      beard
      kids
      smoking
      alcohol
      languages
    }
  }
`;

export const ME_FOLLOWING = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query meFollowing {
    meFollowing {
      created
      user {
        ...UserCardFragment
      }
    }
  }
`;

export const ME_BLOCKS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query meBlocks {
    meBlocks {
      created
      user {
        ...UserCardFragment
      }
    }
  }
`;

export const ME_FRIENDS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query meFriends {
    meFriends {
      friends {
        created
        user {
          ...UserCardFragment
        }
      }
      friendRequestsSent {
        created
        user {
          ...UserCardFragment
        }
      }
      friendRequestsReceived {
        created
        user {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const IS_MATCH = gql`
  query isMatch($userId: String!) {
    isMatch(userId: $userId) {
      hasVoted
      userId
      meVote
      userVote
      match
      userHasVoted
    }
  }
`;

export const YES_MATCHES = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query yesMatches($page: Int!, $limit: Int!) {
    yesMatches(
      input: { type: "page", pageArgs: { page: $page, limit: $limit } }
    ) {
      paging {
        totalPages
      }
      nodes {
        ...UserCardFragment
      }
    }
  }
`;

export const MATCHING = gql`
  ${fragments.USER_MATCHING_FRAGMENT}
  query matching($amount: Int!, $skipUserIds: [String!]!) {
    matching(input: { amount: $amount, skipUserIds: $skipUserIds }) {
      yesVotesLeft
      hotLikes
      users {
        ...UserMatchingFragment
      }
    }
  }
`;

export const MATCHES = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query matches($page: Int!, $limit: Int!) {
    matches(input: { type: "page", pageArgs: { page: $page, limit: $limit } }) {
      paging {
        totalPages
      }
      nodes {
        ...UserCardFragment
      }
    }
  }
`;

export const NEARBY_PEOPLE = gql`
  ${fragments.USER_CARD_FRAGMENT}
  ${fragments.PAGING_DATA}
  query nearbyPeople($page: Int!, $limit: Int!) {
    nearbyPeople(
      paging: { type: "page", pageArgs: { page: $page, limit: $limit } }
    ) {
      paging {
        ...PagingData
      }
      nodes {
        ...UserCardFragment
      }
    }
  }
`;

export const FOLLOWERS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query followers($search: String) {
    followers(search: $search) {
      totalFollowers
      followers {
        created
        user {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const ACTIVE_FRIENDS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query activeFriends {
    activeFriends {
      ...UserCardFragment
    }
  }
`;
export const ACTIVE_CONTACTS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query activeContacts {
    activeContacts {
      ...UserCardFragment
    }
  }
`;

export const VISITORS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query profileVisitors {
    profileVisitors {
      user {
        ...UserCardFragment
      }
      when
    }
  }
`;

export const PRIVATE_PHOTOS_ACCESS_USERS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query privatePhotosAccessUsers {
    privatePhotosAccessUsers {
      users {
        ...UserCardFragment
      }
    }
  }
`;

export const USERNAME_AVAILABLE = gql`
  query usernameAvailable($username: String!) {
    usernameAvailable(username: $username)
  }
`;

export const NEW_USERS = gql`
  ${fragments.USER_AVATAR_FRAGMENT}
  query newUsers {
    newUsers {
      users {
        ...UserAvatarFragment
      }
    }
  }
`;

export const RECENT_LOGINS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query recentLogins {
    recentLogins {
      ...UserCardFragment
    }
  }
`;

export const RECENT_ACTIVE = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query recentActive {
    recentActive {
      ...UserCardFragment
    }
  }
`;

export const POPULAR_PEOPLE = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query popularPeople {
    popularPeople {
      ...UserCardFragment
    }
  }
`;

export const SPOTLIGHTS = gql`
  ${fragments.PHOTO_FRAGMENT}
  ${fragments.USER_SPOTLIGHT_FRAGMENT}
  query spotlight($amount: Int!, $country: String) {
    spotlights(amount: $amount, country: $country) {
      nodes {
        _id
        user {
          ...UserSpotlightFragment
        }
        created
        country
        photo {
          ...PhotoFragment
        }
      }
    }
  }
`;

export const SEARCH_USERS = gql`
  ${fragments.USER_SEARCH_FRAGMENT}
  query searchUsers($regex: String, $useFilter: Boolean) {
    searchUsers(regex: $regex, useFilter: $useFilter) {
      users {
        ...UserSearchFragment
      }
    }
  }
`;

export const REVERSE_GEOCODE = gql`
  query reverseGeocode($lat: String!, $lng: String!) {
    reverseGeocode(lat: $lat, lng: $lng) {
      city
      county
      country
    }
  }
`;

export const PLACE_AUTOCOMPLETE = gql`
  query placeAutocomplete($text: String!) {
    placeAutocomplete(text: $text) {
      city
      country
      county
      geo {
        coordinates
      }
    }
  }
`;

export const USER = gql`
  ${fragments.PHOTO_FRAGMENT}
  query user($username: String!) {
    user(username: $username) {
      _id
      username
      lastActive
      created
      premium
      mood
      admin
      moodDate
      popularity
      profilePhoto {
        ...PhotoFragment
      }
      photos {
        ...PhotoFragment
      }
      videos {
        url
        uploadId
        uploadComplete
        isPrivate
      }
      profile {
        _id
        firstName
        lastName
        birthDate
        gender
        lookingFor
        hereTo
        interests
        languages
        eyeColor
        hairColor
        alcohol
        smoking
        etnicity
        relationshipStatus
        sexuality
        length
        weight
        beard
        tattoo
        kids
        describeYourself
        living
        work {
          title
          company
          education
        }
        location {
          country
          county
          city
          geo {
            type
            coordinates
          }
        }
      }
    }
  }
`;

export const CHATS = gql`
  ${fragments.USER_LIST_FRAGMENT}
  query chats($page: Int!, $limit: Int!) {
    chats {
      totalChats
      items(input: { type: "page", pageArgs: { page: $page, limit: $limit } }) {
        paging {
          totalPages
        }
        nodes {
          chatId
          accepted
          updatedDate
          lastMessage {
            _id
            chatId
            clientId
            fromId
            text
            created
            deletedByIds
            viewedByIds
            reactions {
              user {
                ...UserListFragment
              }
              userId
              created
              reaction
            }
            attachments {
              contentType
              url
              thumbnailUrl
              expired
              deleted
            }
          }
          members {
            ...UserListFragment
          }
        }
      }
    }
  }
`;

export const NEW_MESSAGES = gql`
  query newMessages {
    newMessages {
      nodes {
        _id
        chatId
        clientId
        fromId
      }
    }
  }
`;

export const NOTIFICATIONS = gql`
  ${fragments.USER_LIST_FRAGMENT}
  query notifications {
    notifications {
      totalUnviewed
      nodes {
        __typename
        _id
        read
        viewed
        created
        type
        message
        actionUrl
        user {
          ...UserListFragment
        }
        messageReaction {
          messageId
          reaction
        }
      }
    }
  }
`;

export const FETCH_UPDATES = gql`
  ${fragments.USER_LIST_FRAGMENT}
  query fetchUpdates($notificationsFromDate: DateTime) {
    fetchUpdates(notificationsFromDate: $notificationsFromDate) {
      newMessages {
        nodes {
          _id
          chatId
          clientId
          fromId
        }
      }
      notifications {
        totalUnviewed
        nodes {
          __typename
          _id
          read
          viewed
          created
          type
          message
          actionUrl
          user {
            ...UserListFragment
          }
          messageReaction {
            messageId
            reaction
          }
        }
      }
    }
  }
`;

export const MESSAGE_ATTACHMENTS = gql`
  query messageAttachments($messageId: String!) {
    messageAttachments(messageId: $messageId) {
      contentType
      url
      thumbnailUrl
      expired
      deleted
      uploadComplete
      uploadId
    }
  }
`;

export const NEW_MESSAGES_FOR_CHAT = gql`
  ${fragments.PHOTO_FRAGMENT}
  query newMessagesForChat($chatId: String!, $lastMessageDate: DateTime!) {
    newMessagesForChat(chatId: $chatId, lastMessageDate: $lastMessageDate) {
      typingUserIds
      availableTranslationAmount
      lastViewed {
        userId
        messageId
        lastViewedDate
      }
      nodes {
        _id
        clientId
        text
        translated {
          toLang
          translated
        }
        created
        fromId
        viewedByIds
        reactions {
          userId
          created
          reaction
          user {
            _id
            username
            deleted
            lastActive
            created
            premium
            profilePhoto {
              ...PhotoFragment
            }
            profile {
              _id
              firstName
              lastName
              birthDate
            }
          }
        }
        attachments {
          contentType
          url
          thumbnailUrl
          expired
          deleted
          uploadComplete
          uploadId
        }
      }
    }
  }
`;

export const CHAT_MESSAGES = gql`
  ${fragments.PHOTO_FRAGMENT}
  query chat($chatId: String!, $page: Int!, $limit: Int!) {
    chat(chatId: $chatId) {
      _id
      messages(
        input: { type: "page", pageArgs: { page: $page, limit: $limit } }
      ) {
        paging {
          totalPages
        }
        nodes {
          _id
          clientId
          text
          translated {
            toLang
            translated
          }
          created
          fromId
          viewedByIds
          reactions {
            userId
            created
            reaction
            user {
              _id
              username
              deleted
              lastActive
              created
              premium
              profilePhoto {
                ...PhotoFragment
              }
              profile {
                _id
                firstName
                lastName
                birthDate
              }
            }
          }
          attachments {
            contentType
            url
            thumbnailUrl
            expired
            deleted
            uploadComplete
            uploadId
          }
        }
      }
    }
  }
`;

export const CHAT_USER_INFO = gql`
  ${fragments.PHOTO_FRAGMENT}
  query chat($chatId: String!) {
    chat(chatId: $chatId) {
      _id
      members {
        _id
        username
        lastActive
        profilePhoto {
          ...PhotoFragment
        }
        profile {
          birthDate
          firstName
          lastName
        }
      }
    }
  }
`;

export const CHAT = gql`
  ${fragments.PHOTO_FRAGMENT}
  query chat($chatId: String!, $page: Int!, $limit: Int!) {
    chat(chatId: $chatId) {
      _id
      deletedByIds
      acceptedByIds
      updatedDate
      lastMessageId
      blocked
      allowNewFrom
      allowNew
      useTranslation
      messages(
        input: { type: "page", pageArgs: { page: $page, limit: $limit } }
      ) {
        paging {
          totalPages
        }
        nodes {
          _id
          clientId
          text
          translated {
            toLang
            translated
          }
          created
          fromId
          viewedByIds
          reactions {
            userId
            created
            reaction
            user {
              _id
              username
              deleted
              lastActive
              created
              premium
              profilePhoto {
                ...PhotoFragment
              }
              profile {
                _id
                firstName
                lastName
                birthDate
              }
            }
          }
          attachments {
            contentType
            url
            thumbnailUrl
            expired
            deleted
            uploadComplete
            uploadId
          }
        }
      }
      members {
        username
        _id
        deleted
        lastActive
        created
        premium
        profilePhoto {
          ...PhotoFragment
        }
        profile {
          _id
          firstName
          lastName
          birthDate
          location {
            country
            county
            city
            geo {
              type
              coordinates
            }
          }
        }
      }
    }
  }
`;

export const CHAT_FOR_USER = gql`
  query chatForUser($userId: String!) {
    chatForUser(userId: $userId) {
      _id
      # deletedByIds
      # acceptedByIds
      # updatedDate
      # lastMessageId
      # messages(
      #   input: { type: "page", pageArgs: { page: $page, limit: $limit } }
      # ) {
      #   paging {
      #     totalPages
      #   }
      #   nodes {
      #     _id
      #     clientId
      #     text
      #     created
      #     fromId
      #   }
      # }
      # members {
      #   username
      #   _id
      #   deleted
      # }
    }
  }
`;

export const GET_COMMENT_REPLIES = gql`
  ${fragments.COMMENT_DATA}
  ${fragments.PAGING_DATA}
  ${fragments.USER_LIST_FRAGMENT}
  query getComment(
    $commentId: String!
    $repliesPage: Int!
    $repliesLimit: Int!
  ) {
    getComment(commentId: $commentId) {
      _id
      replies(
        input: {
          type: "page"
          pageArgs: { page: $repliesPage, limit: $repliesLimit }
        }
      ) {
        paging {
          ...PagingData
        }
        nodes {
          ...CommentData
          user {
            ...UserListFragment
          }
        }
      }
    }
  }
`;

export const GET_COMMENT = gql`
  ${fragments.COMMENT_DATA}
  ${fragments.PAGING_DATA}
  ${fragments.USER_LIST_FRAGMENT}
  query getComment($commentId: String!, $repliesLimit: Int!) {
    getComment(commentId: $commentId) {
      ...CommentData
      user {
        ...UserListFragment
      }
      replies(
        input: { type: "page", pageArgs: { page: 1, limit: $repliesLimit } }
      ) {
        paging {
          ...PagingData
        }
        nodes {
          ...CommentData
          user {
            ...UserListFragment
          }
        }
      }
    }
  }
`;

// Use to get more comments for a post with the paging. Initially page 1 is loaded with limit 20,
// to get 20 more comments, use page = 2.
export const GET_POST_COMMENTS = gql`
  ${fragments.COMMENT_DATA}
  ${fragments.PAGING_DATA}
  ${fragments.USER_LIST_FRAGMENT}
  query getPost(
    $postId: String!
    $page: Int!
    $commentLimit: Int!
    $repliesLimit: Int!
  ) {
    getPost(postId: $postId) {
      _id
      comments(
        input: { type: "page", pageArgs: { page: $page, limit: $commentLimit } }
      ) {
        paging {
          ...PagingData
        }
        nodes {
          ...CommentData
          user {
            ...UserListFragment
          }
          replies(
            input: { type: "page", pageArgs: { page: 1, limit: $repliesLimit } }
          ) {
            paging {
              ...PagingData
            }
            nodes {
              ...CommentData
              user {
                ...UserListFragment
              }
            }
          }
        }
      }
    }
  }
`;

// Used to reload the post in the PostItem component.
// This will reset the post and all data previously fetched (like more comments and replies)
export const GET_POST = gql`
  ${fragments.PAGING_DATA}
  ${fragments.COMMENT_DATA}
  ${fragments.POST_DATA}
  ${fragments.USER_LIST_FRAGMENT}
  query getPost($postId: String!, $commentLimit: Int!, $repliesLimit: Int!) {
    getPost(postId: $postId) {
      ...PostData
      comments(
        input: { type: "page", pageArgs: { page: 1, limit: $commentLimit } }
      ) {
        paging {
          ...PagingData
        }
        nodes {
          ...CommentData
          user {
            ...UserListFragment
          }
          replies(
            input: { type: "page", pageArgs: { page: 1, limit: $repliesLimit } }
          ) {
            paging {
              ...PagingData
            }
            nodes {
              ...CommentData
              user {
                ...UserListFragment
              }
            }
          }
        }
      }
    }
  }
`;

export const POSTS_FEED = gql`
  ${fragments.PAGING_DATA}
  ${fragments.COMMENT_DATA}
  ${fragments.POST_DATA}
  ${fragments.USER_LIST_FRAGMENT}
  query postsFeed(
    $audience: [PostAudience!]!
    $userId: String
    $groupId: String
    $paging: PagingArgs!
  ) {
    postsFeed(
      audience: $audience
      userId: $userId
      groupId: $groupId
      paging: $paging
    ) {
      paging {
        ...PagingData
      }
      nodes {
        ...PostData
        comments(input: { type: "page", pageArgs: { page: 1, limit: 2 } }) {
          paging {
            ...PagingData
          }
          nodes {
            ...CommentData
            user {
              ...UserListFragment
            }
            replies(input: { type: "page", pageArgs: { page: 1, limit: 1 } }) {
              paging {
                ...PagingData
              }
              nodes {
                ...CommentData
                user {
                  ...UserListFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_REACTIONS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.USER_CARD_FRAGMENT}
  query getReactions(
    $parentId: String!
    $type: String!
    $reaction: String
    $paging: PagingArgs!
  ) {
    getReactions(
      parentId: $parentId
      type: $type
      reaction: $reaction
      paging: $paging
    ) {
      paging {
        ...PagingData
      }
      nodes {
        _id
        userId
        user {
          ...UserCardFragment
        }
        created
        reaction
        type
      }
    }
  }
`;

export const GROUP_MEMBER_INVITES = gql`
  ${fragments.PAGING_DATA}
  ${fragments.USER_CARD_FRAGMENT}
  query getGroup($groupId: String!, $paging: PagingArgs!) {
    getGroup(groupId: $groupId) {
      _id
      memberInvites(paging: $paging) {
        paging {
          ...PagingData
        }
        nodes {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const GROUP_MEMBER_REQUESTS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.USER_CARD_FRAGMENT}
  query getGroup($groupId: String!, $paging: PagingArgs!) {
    getGroup(groupId: $groupId) {
      _id
      memberRequests(paging: $paging) {
        paging {
          ...PagingData
        }
        nodes {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const GET_GROUP_MEMBERS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.USER_CARD_FRAGMENT}
  query getGroup($groupId: String!, $regex: String, $paging: PagingArgs!) {
    getGroup(groupId: $groupId) {
      _id
      members(regex: $regex, paging: $paging) {
        paging {
          ...PagingData
        }
        nodes {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const GET_GROUP_BANNED_USERS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.USER_CARD_FRAGMENT}
  query getGroup($groupId: String!, $paging: PagingArgs!) {
    getGroup(groupId: $groupId) {
      _id
      bannedUsers(paging: $paging) {
        paging {
          ...PagingData
        }
        nodes {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const GET_GROUP = gql`
  ${fragments.GROUP_COMMON_DATA}
  ${fragments.PAGING_DATA}
  ${fragments.USER_CARD_FRAGMENT}
  ${fragments.PHOTO_FRAGMENT}
  query getGroup($groupId: String!) {
    getGroup(groupId: $groupId) {
      ...GroupCommonData
      photo {
        ...PhotoFragment
      }
      admins(paging: { type: "page", pageArgs: { page: 1, limit: 10 } }) {
        paging {
          ...PagingData
        }
        nodes {
          ...UserCardFragment
        }
      }
    }
  }
`;

export const GROUPS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.GROUP_COMMON_DATA}
  ${fragments.PHOTO_FRAGMENT}
  query groups($regex: String, $sort: String, $paging: PagingArgs!) {
    groups(regex: $regex, sort: $sort, paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...GroupCommonData
        photo {
          ...PhotoFragment
        }
      }
    }
  }
`;

export const JOINED_GROUPS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.GROUP_COMMON_DATA}
  ${fragments.PHOTO_FRAGMENT}
  query joinedGroups($regex: String, $paging: PagingArgs!) {
    joinedGroups(regex: $regex, paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...GroupCommonData
        photo {
          ...PhotoFragment
        }
      }
    }
  }
`;

export const MANAGED_GROUPS = gql`
  ${fragments.PAGING_DATA}
  ${fragments.GROUP_COMMON_DATA}
  ${fragments.PHOTO_FRAGMENT}
  query managedGroups($paging: PagingArgs!) {
    managedGroups(paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...GroupCommonData
        photo {
          ...PhotoFragment
        }
      }
    }
  }
`;

export const GROUP_INVITES = gql`
  ${fragments.PAGING_DATA}
  ${fragments.GROUP_COMMON_DATA}
  ${fragments.PHOTO_FRAGMENT}
  query groupInvites($paging: PagingArgs!) {
    groupInvites(paging: $paging) {
      paging {
        ...PagingData
      }
      nodes {
        ...GroupCommonData
        photo {
          ...PhotoFragment
        }
      }
    }
  }
`;

export const RECEIVED_GIFTS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query receivedGifts {
    receivedGifts {
      _id
      byUser {
        ...UserCardFragment
      }
      created
      message
      credits
      claimed
      claimedDate
    }
  }
`;

export const SENT_GIFTS = gql`
  ${fragments.USER_CARD_FRAGMENT}
  query sentGifts {
    sentGifts {
      _id
      toUser {
        ...UserCardFragment
      }
      created
      message
      credits
      claimed
      claimedDate
    }
  }
`;

export const GIFTS_BADGE = gql`
  query giftsBadge {
    giftsBadge
  }
`;

export const FREE_PREMIUM_AVAILABLE = gql`
  query freePremiumAvailable {
    freePremiumAvailable
  }
`;

export const GET_TRANSLATED_TEXT = gql`
  query getTranslatedText($messageId: String!) {
    getTranslatedText(messageId: $messageId) {
      translatedText {
        toLang
        translated
      }
      availableTranslationAmount
    }
  }
`;

export const PUBLIC_REELS = gql`
  ${fragments.USER_REEL_FRAGMENT}
  query reels($limit: Int!) {
    reels(limit: $limit) {
      _id
      url
      thumbnailUrl
      thumbnailBlurhash
      uploadId
      uploadComplete
      user {
        ...UserReelFragment
      }
      views
    }
  }
`;

export const GET_REEL = gql`
  ${fragments.USER_REEL_FRAGMENT}
  query reel($reelId: String!) {
    reel(reelId: $reelId) {
      _id
      url
      thumbnailUrl
      thumbnailBlurhash
      uploadId
      uploadComplete
      user {
        ...UserReelFragment
      }
      views
    }
  }
`;

export const EMAIL_SUBSCRIPTION_SETTINGS = gql`
  query emailSubscriptionSettings($emailUuid: String!) {
    emailSubscriptionSettings(emailUuid: $emailUuid) {
      newsLetter
      weeklyMatches
      weeklyNewLikes
      weeklyNewMessages
      systemNotifications
      advertisements
    }
  }
`;

export const ACHIEVEMENTS = gql`
  query achievements {
    achievements {
      achievementId
      title
      text
      rewardCredits
      completed
      rewardClaimedDate
    }
  }
`;

export const VALID_AFFILIATE_LINK = gql`
  query validAffiliateLink($affiliateLink: String!) {
    validAffiliateLink(affiliateLink: $affiliateLink) {
      valid
      signupCreditsBonus
      affiliateId
    }
  }
`;
