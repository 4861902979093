import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import { BackendModule } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import common_en from "./translations/en/common.json";
// import common_sv from "./translations/sv/common.json";
// import common_de from "./translations/de/common.json";
// import common_th from "./translations/th/common.json";
// import common_fr from "./translations/fr/common.json";
// import common_es from "./translations/es/common.json";
// import common_ru from "./translations/ru/common.json";
// import common_fi from "./translations/fi/common.json";
// import common_hi from "./translations/hi/common.json";
// import common_pt from "./translations/pt/common.json";
// import common_el from "./translations/el/common.json";
// import common_it from "./translations/it/common.json";
// import common_tr from "./translations/tr/common.json";
// import common_ja from "./translations/ja/common.json";
// import common_ko from "./translations/ko/common.json";
// import common_ph from "./translations/ph/common.json";
// import common_id from "./translations/id/common.json";
// import common_km from "./translations/km/common.json";
// import common_bg from "./translations/bg/common.json";
// import common_hr from "./translations/hr/common.json";
// import common_cs from "./translations/cs/common.json";
// import common_da from "./translations/da/common.json";
// import common_nl from "./translations/nl/common.json";
// import common_et from "./translations/et/common.json";
// import common_hu from "./translations/hu/common.json";
// import common_lv from "./translations/lv/common.json";
// import common_lt from "./translations/lt/common.json";
// import common_ms from "./translations/ms/common.json";
// import common_no from "./translations/no/common.json";
// import common_pl from "./translations/pl/common.json";
// import common_ro from "./translations/ro/common.json";
// import common_uk from "./translations/uk/common.json";
// import common_vi from "./translations/vi/common.json";
// import common_sk from "./translations/sk/common.json";
// import common_ca from "./translations/ca/common.json";
// import common_fil from "./translations/ph/common.json";
// import common_zh from "./translations/zh/common.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   en: common_en,
//   sv: common_sv,
//   de: common_de,
//   th: common_th,
//   fr: common_fr,
//   es: common_es,
//   ru: common_ru,
//   fi: common_fi,
//   hi: common_hi,
//   pt: common_pt,
//   it: common_it,
//   el: common_el,
//   tr: common_tr,
//   ja: common_ja,
//   ko: common_ko,
//   ph: common_ph,
//   id: common_id,
//   km: common_km,
//   bg: common_bg,
//   hr: common_hr,
//   cs: common_cs,
//   da: common_da,
//   nl: common_nl,
//   et: common_et,
//   hu: common_hu,
//   lv: common_lv,
//   lt: common_lt,
//   ms: common_ms,
//   no: common_no,
//   pl: common_pl,
//   ro: common_ro,
//   uk: common_uk,
//   vi: common_vi,
//   sk: common_sk,
//   fil: common_fil,
//   ca: common_ca,
//   zh: common_zh,
// };

const LazyImportPlugin = {
  type: "backend",
  init: function (services, backendOptions, i18nextOptions) {},
  read: function (language, namespace, callback) {
    //console.log("Plugin read", language, namespace);
    import(`./translations/${language}/common.json`).then((obj) => {
      //console.log("Plugin read", obj);
      callback(null, obj.translation);
    });
  },

  save: function (language, namespace, data) {},

  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
  },
};

i18n
  .use(LazyImportPlugin)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    ns: ["translation"],
    // saveMissing: true,
    // missingKeyHandler: function (lng, ns, key, fallbackValue) {
    //   //console.log(key);
    // },
    supportedLngs: [
      "en",
      "sv",
      "de",
      "th",
      "fr",
      "fil",
      "fi",
      "ru",
      "hi",
      "pt",
      "it",
      "el",
      "tr",
      "ja",
      "ko",
      "id",
      "km",
      "bg",
      "hr",
      "cs",
      "da",
      "nl",
      "es",
      "et",
      "hu",
      "lv",
      "lt",
      "ms",
      "no",
      "pl",
      "ro",
      "uk",
      "vi",
      "sk",
      "ca",
      "zh",
    ],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
