import { gql } from "@apollo/client";

export const VIDEO_FRAGMENT = gql`
  fragment VideoFragment on Video {
    _id
    url
    thumbnailUrl
    thumbnailBlurhash
    uploadId
    uploadComplete
    isPrivate
    verified
    approved
  }
`;

export const PHOTO_FRAGMENT = gql`
  fragment PhotoFragment on Photo {
    _id
    url
    thumbnailUrl
    approved
    verified
    uploadId
    uploadComplete
    isPrivate
    blurhash
  }
`;

export const PAGING_DATA = gql`
  fragment PagingData on PagingResult {
    total
    limit
    page
    totalPages
    offset
    hasPrevPage
    hasNextPage
    prevPage
    nextPage
    pagingCounter
  }
`;

export const COMMENT_DATA = gql`
  fragment CommentData on Comment {
    _id
    parentId
    rootId
    byUserId
    replyCount
    text
    created
    reactionCounts
    totalReactions
    meReaction
    repliesAllowed
  }
`;

export const USER_SEARCH_FRAGMENT = gql`
  ${PHOTO_FRAGMENT}
  fragment UserSearchFragment on User {
    _id
    username
    lastActive
    mood
    moodDate
    premium
    admin
    popularity
    showOnlineStatus
    sendingLive
    profilePhoto {
      ...PhotoFragment
    }
    profile {
      _id
      birthDate
      firstName
      lastName
      gender
      location {
        _id
        country
        county
        city
        geo {
          _id
          type
          coordinates
        }
      }
    }
  }
`;

export const USER_LIST_FRAGMENT = gql`
  fragment UserListFragment on User {
    _id
    username
    lastActive
    mood
    admin
    showOnlineStatus
    profile {
      _id
      firstName
      lastName
      birthDate
    }
    profilePhoto {
      _id
      thumbnailUrl
      blurhash
    }
  }
`;

export const USER_REEL_FRAGMENT = gql`
  fragment UserReelFragment on User {
    _id
    username
    lastActive
    admin
    showOnlineStatus
    profile {
      _id
      firstName
      lastName
      birthDate
    }
    profilePhoto {
      _id
      thumbnailUrl
      blurhash
    }
  }
`;

// data needed for users to display an avatar
export const USER_AVATAR_FRAGMENT = gql`
  fragment UserAvatarFragment on User {
    _id
    username
    mood
    lastActive
    showOnlineStatus
    profilePhoto {
      _id
      thumbnailUrl
      blurhash
    }
  }
`;

export const USER_SPOTLIGHT_FRAGMENT = gql`
  fragment UserSpotlightFragment on User {
    _id
    username
    mood
    lastActive
    sendingLive
    showOnlineStatus
    profile {
      firstName
      lastName
      birthDate
    }
  }
`;

export const USER_CARD_FRAGMENT = gql`
  fragment UserCardFragment on User {
    _id
    username
    mood
    sendingLive
    lastActive
    showOnlineStatus
    profile {
      _id
      firstName
      birthDate
      location {
        _id
        geo {
          coordinates
        }
      }
    }
    profilePhoto {
      _id
      thumbnailUrl
      blurhash
    }
  }
`;

export const USER_MATCHING_FRAGMENT = gql`
  fragment UserMatchingFragment on User {
    _id
    username
    mood
    sendingLive
    lastActive
    showOnlineStatus
    profile {
      _id
      birthDate
      firstName
      interests
      languages
      hereTo
      interests
      languages
      length
      weight
      eyeColor
      hairColor
      beard
      tattoo
      describeYourself
      relationshipStatus
      etnicity
      sexuality
      smoking
      alcohol
      kids
      living
      gender
      location {
        _id
        geo {
          coordinates
        }
      }
    }
    profilePhoto {
      _id
      url
      thumbnailUrl
      blurhash
    }
  }
`;

export const POST_DATA = gql`
  ${PHOTO_FRAGMENT}
  ${USER_LIST_FRAGMENT}
  fragment PostData on Post {
    _id
    byUserId
    language
    user {
      ...UserListFragment
    }
    groupId
    groupName
    groupPhoto {
      ...PhotoFragment
    }
    audience
    created
    text
    commentsCount
    reactionCounts
    totalReactions
    meReaction
    commentsAllowed
    locked
    attachments {
      contentType
      url
      thumbnailUrl
      expired
      deleted
      uploadComplete
      uploadId
      blurhash
    }
  }
`;

export const USER_DATA_FULL = gql`
  ${PHOTO_FRAGMENT}
  ${VIDEO_FRAGMENT}
  fragment UserDataFull on User {
    _id
    username
    deleted
    lastActive
    created
    premium
    admin
    mood
    moodDate
    popularity
    isFollowing
    isBlocked
    isFavorite
    isFriend
    hasSentFriendRequest
    hasReceivedFriendRequest
    likesMe {
      vote
      hot
      date
    }
    showOnlineStatus
    sendingLive
    verifiedPhoto
    profilePhoto {
      ...PhotoFragment
    }
    matchVideo {
      ...VideoFragment
    }
    instagram {
      _id
      updatedDate
      # dont fetch media here, it is included in videos
    }
    tiktok {
      _id
      updatedDate
      media {
        id
        embed_link
        cover_image_url
      }
    }
    profile {
      _id
      birthDate
      firstName
      lastName
      gender
      relationshipStatus
      hereTo
      lookingFor
      interests
      personality
      languages
      eyeColor
      hairColor
      alcohol
      smoking
      etnicity
      sexuality
      length
      weight
      beard
      tattoo
      kids
      describeYourself
      living
      work {
        title
        company
        education
      }
      location {
        _id
        country
        county
        city
        geo {
          type
          coordinates
        }
      }
    }
  }
`;

export const GROUP_COMMON_DATA = gql`
  fragment GroupCommonData on Group {
    _id
    created
    privacy
    visibility
    memberVisibility
    memberPostsAllowed
    memberCommentsAllowed
    name
    about
    rules
    genderLimited
    minAgeLimited
    maxAgeLimited
    memberCount
    isMember
    isAdmin
    isAdminInvited
    hasSentRequest
    isInvited
    canJoin
    canRequest
    inviteLimit
    memberInvitesIds
  }
`;
