import PasswordValidator from "password-validator";

export function validatePassword(password: string): boolean | any[] {
  const schema = new PasswordValidator();

  schema
    .is()
    .min(10)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .not()
    .spaces();

  return schema.validate(password, { details: true });
}
