import { ClickAwayListener, Popper, TextField } from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { logger } from "../../util/log";
import { FlexCol, FlexRow } from "../Styled/Container";
import { MenuPaper } from "../Styled/Paper";
import Text from "../Styled/Text";

const inputStyles = {
  width: "100%",
  fontSize: 15,
  fontWeight: 400,
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};

const StyledTextField = styled(TextField)`
  width: 100% !important;
`;

const OptionsPaper = styled(MenuPaper)`
  padding: 0px !important;
  min-width: 200px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 200000;
`;

const ListItem = styled(FlexRow)`
  border-radius: 8px;
  padding: 5px;

  &:hover {
    background-color: ${(props) => props.theme.listItemHoverBg} !important;
    transition: 0.2s;
    cursor: pointer;
  }
`;

const ListItemContainer = styled.div``;

const StyledPopper = styled(Popper)`
  z-index: 200000;
`;

type CachedSearch = {
  [key: string]: any[];
};

const AutoCompleteSearch = (props: {
  placeholder?: string | null;
  minLength?: number;
  autoFocus?: boolean;
  fetchData?: (text: string) => Promise<any[]>;
  loading?: boolean;
  onChange?: (item: any) => void;
  optionStringValue?: (item: any) => string;
  multiline?: boolean;
  width?: number;
  name: string;
  label: string;
  helperText?: string;
  debounce?: number;
}) => {
  const { optionStringValue, fetchData, minLength, onChange, debounce } = props;
  const [text, setText] = useState("");
  const debouncedValue = useDebounce(text, debounce ?? 800);
  const [data, setData] = useState<any[]>([]);
  const cache = useRef<CachedSearch>({});
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleFetchData = useCallback(
    async (enteredValue: string) => {
      if (cache.current[enteredValue]) {
        logger.log("Found data for " + enteredValue + " in cache");
        setData(cache.current[enteredValue]);
      } else if (fetchData) {
        const newData = await fetchData(enteredValue);
        logger.log("Fetched data for " + enteredValue);

        // Turn the objects into strings
        cache.current[enteredValue] = newData;
        setData(newData);
      }
    },
    [fetchData]
  );

  useEffect(() => {
    if (debouncedValue.trim().length < (minLength ?? 3)) {
      return;
    }
    handleFetchData(debouncedValue);
  }, [debouncedValue, handleFetchData, minLength]);

  const handleChange = async (newValue: string) => {
    //logger.log("handleChange", newValue);
    if (newValue.length < (minLength ?? 3)) {
      setData([]);
    }
    setText(() => newValue);
  };

  return (
    <>
      <StyledTextField
        ref={anchorRef}
        name={props.name}
        color="secondary"
        label={props.label}
        value={text}
        //value={props.value}
        InputLabelProps={{
          style: inputStyles,
        }}
        onChange={(event) => {
          //logger.log("input onchange", event.target.value);
          handleChange(event.target.value);
        }}
        helperText={props.helperText}
      />
      <StyledPopper
        open={data && data.length > 0}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        //transition
      >
        <OptionsPaper>
          <ClickAwayListener onClickAway={() => handleChange("")}>
            <FlexCol>
              {data.map((item, index) => {
                return (
                  <ListItemContainer key={"menu-option" + index}>
                    <ListItem
                      onClick={() => {
                        onChange && onChange(item);
                        setText("");
                        setData([]);
                      }}
                    >
                      <Text weight={600}>
                        {optionStringValue ? optionStringValue(item) : item}
                      </Text>
                    </ListItem>
                  </ListItemContainer>
                );
              })}
            </FlexCol>
          </ClickAwayListener>
        </OptionsPaper>
      </StyledPopper>
    </>
  );
};
export default AutoCompleteSearch;
