import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// Import bootstrap css
//import "bootstrap/dist/css/bootstrap.css";

// Importing Sass with Bootstrap CSS
import "./sass/styles.scss";

import "react-virtualized/styles.css"; // only needs to be imported once

import { enableAllPlugins } from "immer";
import App from "./App";
import { logger } from "./util/log";

import "./i18n";

enableAllPlugins();

logger.log("window.location.hostname", window.location.hostname);

if (
  window.location.hostname === "mingleflirt.com" ||
  window.location.hostname === "www.mingleflirt.com" ||
  window.location.hostname === "https://mingleflirt.com" ||
  window.location.hostname === "https://www.mingleflirt.com" ||
  window.location.hostname === "minglify.se" ||
  window.location.hostname === "www.minglify.se"
) {
  window.location.replace("https://minglify.io");
}

const devApiUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:4000/graphql";

const prodApiUrl = "https://api.minglify.io/graphql";

const apiUrl = process.env.NODE_ENV === "development" ? devApiUrl : prodApiUrl;

logger.log("apiUrl", apiUrl);

// Need to fetch the theme before we render, so we do not render a light background when
// using the dark theme.
const themeStorageString = localStorage.getItem("theme-storage");
logger.log("themeStorageString", themeStorageString);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Init dark mode based on browser settings.
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  document.documentElement.setAttribute("darkMode", "");
}

if (themeStorageString) {
  const themeStorage = JSON.parse(themeStorageString);
  if (themeStorage?.state?.theme === "dark") {
    document.documentElement.setAttribute("darkMode", "");
  } else {
    document.documentElement.removeAttribute("darkMode");
  }

  if (themeStorage?.state?.theme === "light") {
    document.documentElement.setAttribute("lightMode", "");
  } else {
    document.documentElement.removeAttribute("lightMode");
  }
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
