//import { Modal } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import Button from "../Button/Button";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
`;

export const SubmitButton = styled(Button)`
  margin-left: 10px;
`;

export const StyledModal = styled(Dialog).attrs((props: any) => ({
  width: props.width,
  disableScrollLock: true,
}))`
  //background-color: red;

  .MuiPaper-root {
  }
  .MuiDialog-paper {
    padding: 20px !important;
    background-color: ${(props) => props.theme.pageBackground} !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ) !important;
    width: ${(props) => props.width}px !important;
  }
`;

export const ReelModal = styled(Dialog).attrs((props: any) => ({
  width: props.width,
}))`
  //background-color: red;

  .MuiPaper-root {
  }
  .MuiDialog-paper {
    padding: 0px !important;
    margin: 0px !important;
    background-color: ${(props) => props.theme.pageBackground} !important;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ) !important;
    width: ${(props) => props.width}px !important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
`;
