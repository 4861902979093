import { useMutation, useQuery } from "@apollo/client";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CurrencyDollarIcon,
  DevicePhoneMobileIcon,
  GlobeAltIcon,
  LinkIcon,
  ShieldCheckIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { Box, useTheme } from "@mui/material";
import { StdButton } from "components/Button/Button";
import OptionMenu, { MenuOption } from "components/OptionMenu/OptionMenu";
import { StyledSpinner } from "components/Reels/styled";
import { Col, FlexCol, FlexRow, Row } from "components/Styled/Container";
import { StyledLink } from "components/Styled/StyledLink";
import Text, { StyledText } from "components/Styled/Text";
import { AFFILIATE_INSTALL_CLICK } from "graphql/mutations-gql";
import { VALID_AFFILIATE_LINK } from "graphql/queries-gql";
import { useDismissableSnackbar } from "hooks/useDismissableSnackbar";
import useWindowSize from "hooks/useWindowSize";
import LoginFooter from "pages/login/LoginFooter";
import { getLanguage } from "pages/login/LoginPage";
import {
  PaperBox,
  StyledA,
  WhyImage,
  WhyImageContainer,
  WhyImageTextContainer,
  WhyWrapper,
  WrapContainer,
} from "pages/login/styled";
import { useCallback, useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logger } from "util/log";
import {
  InfoHeader,
  InfoHeaderWrapper,
  InfoItem,
  PageContainer,
  ScreensContainer,
  ScreensImage,
  StoreImage,
  TopContainer,
  TopLogo,
  TopLogoText,
} from "./styled";

const FlagImage = styled.img`
  width: 20px;
  height: 20px;
`;

const FlagIcon: React.FC<{ countryCode: string }> = ({ countryCode }) => {
  return <FlagImage src={`/images/countryflags/${countryCode}.svg`} />;
};

const AppInstall = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const path = location.pathname;
  const query = new URLSearchParams(location.search);
  const lang = query.get("lang");
  console.log("path", path);
  const referer = path.replace("/app-install/", "");
  const affiliateLink =
    !referer || referer === "" || referer === "/" ? "web-minglify" : referer;

  console.log(
    "referer [" + referer + "] affiliateLink [" + affiliateLink + "]"
  );
  const [validAffiliate, setValidAffiliate] = useState(false);
  const [signupCreditsBonus, setSignupCreditsBonus] = useState(0);
  const { showSnackbar } = useDismissableSnackbar();
  const { loading } = useQuery(VALID_AFFILIATE_LINK, {
    context: { service: "api-auth" },
    variables: {
      affiliateLink: affiliateLink,
    },
    onCompleted: (data) => {
      logger.log("validAffiliateLink", JSON.stringify(data));
      setValidAffiliate(data.validAffiliateLink.valid);
      setSignupCreditsBonus(data.validAffiliateLink.signupCreditsBonus);
      setAffiliateFetched(true);
    },
    onError: (error) => {
      logger.error(error);
      setAffiliateFetched(true);
    },
  });
  const [affiliateInstallClick] = useMutation(AFFILIATE_INSTALL_CLICK);
  const [affiliateFetched, setAffiliateFetched] = useState(false);
  const [clicked, setClicked] = useState(false);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const whyImage = "bgImage3.jpg";

  const getCurrentLangText = (lang: string) => {
    if (i18n.language === lang) {
      return " (" + t("current") + ")";
    }

    return "";
  };

  useEffect(() => {
    if (
      lang &&
      i18n.options.supportedLngs &&
      i18n.options.supportedLngs.includes(lang)
    ) {
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }
  }, [i18n, lang]);

  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  const getLanguageOptions = (): MenuOption[] => {
    const options =
      i18n.options.supportedLngs &&
      i18n.options.supportedLngs
        ?.map((s: string) => s)
        .sort((a: string, b: string) => {
          const langA = t(getLanguage(a) ?? "") ?? "";
          const langB = t(getLanguage(b) ?? "") ?? "";
          return langA < langB ? -1 : 1;
        })
        .map((lang) => {
          if (!lang || lang === "cimode" || !getLanguage(lang)) {
            return null;
          }
          const option: MenuOption = {
            text: t(getLanguage(lang) ?? "") + getCurrentLangText(lang),
            dividerAfter: false,
            onClick: () => setLanguage(lang),
            icon: <FlagIcon countryCode={lang} />,
          };
          return option;
        });
    if (!options) {
      return [];
    }
    return options.filter((o) => o !== null) as MenuOption[];
  };

  const appStoreClick = useCallback(
    (platform?: string, checkClick?: boolean) => {
      console.log("appStoreClick");
      if (
        !loading &&
        affiliateFetched &&
        validAffiliate &&
        (checkClick === false || !clicked)
      ) {
        setClicked(true);
        affiliateInstallClick({
          context: { service: "api-auth" },
          variables: {
            affiliateLink: affiliateLink,
          },
          onCompleted: (data) => {
            logger.log("affiliateInstallClick", JSON.stringify(data));

            if (isIOS) {
              window.location.href = "http://appstore.com/minglify";
            } else if (isAndroid) {
              window.location.href =
                "http://play.google.com/store/apps/details?id=se.minglify.app";
            } else {
              if (platform === "google") {
                window.location.href =
                  "http://play.google.com/store/apps/details?id=se.minglify.app";
              } else {
                window.location.href =
                  "https://apps.apple.com/us/app/social-dating-chat-minglify/id6447954430?platform=iphone";
              }
            }
          },
          onError: (error) => {
            showSnackbar(t("Failed to register affiliate install"), "error");
          },
        });
      } else {
        // Simply open the app store link. The affiliate is not valid.
        if (isIOS) {
          window.location.href = "http://appstore.com/minglify";
        } else if (isAndroid) {
          window.location.href =
            "http://play.google.com/store/apps/details?id=se.minglify.app";
        }
      }
    },
    [
      affiliateInstallClick,
      showSnackbar,
      t,
      validAffiliate,
      clicked,
      loading,
      affiliateFetched,
      setClicked,
      affiliateLink,
    ]
  );

  const getLinkIcon = () => {
    if (loading) {
      return <StyledSpinner />;
    }
    return <CurrencyDollarIcon width={40} color={theme.colors.primary} />;
  };

  // If we are on an iOS or android device, we will perform the affiliate link click automatically and
  // open up app store or google play immediately.
  useEffect(() => {
    if (
      !loading &&
      (isIOS || isAndroid) &&
      affiliateFetched &&
      validAffiliate &&
      !clicked
    ) {
      appStoreClick();
    }
  }, [affiliateFetched, appStoreClick, loading, validAffiliate, clicked]);

  return (
    <div style={{ height: "100vh" }}>
      <PageContainer>
        <TopContainer fixedBg={isIOS || isAndroid ? false : false}>
          <Col maxWidth={800}>
            <Row wrap="nowrap" width="100%" justifyContent="center">
              <TopLogo src="/icon256.png" />
              <TopLogoText src="/logo-text-white.png" />
            </Row>
            <FlexRow justifyContent="center" colGap={20}>
              <StyledText
                size={28}
                weight={900}
                shadow="#000"
                color={theme.colors.primary}
              >
                {t("Get the app now!")}
              </StyledText>
              <OptionMenu
                type="CircIconButton"
                id="langdialog-options"
                icon={<FlagIcon countryCode={i18n.language} />}
                placement="top-start"
                options={getLanguageOptions()}
              />
            </FlexRow>

            <Row justifyContent="center" alignItems="center">
              <StyledLink to="" onClick={() => appStoreClick("apple", false)}>
                <StoreImage src="/appstore.png" alt="appstore" />
              </StyledLink>
              <StyledLink to="" onClick={() => appStoreClick("google", false)}>
                <StoreImage src="/googleplay.png" alt="googleplay" />
              </StyledLink>
            </Row>
          </Col>
          <ScreensContainer>
            <ScreensImage src="/images/homescreens.png" />
          </ScreensContainer>
        </TopContainer>

        <InfoHeaderWrapper>
          <InfoHeader>
            <InfoItem
              title={t("Affiliate link")}
              text={
                validAffiliate
                  ? t("This is an affiliate link for:") + " " + affiliateLink
                  : t("This is not a valid affiliate link.")
              }
              icon={<LinkIcon width={40} color={theme.colors.primary} />}
            />
            <InfoItem
              title={t("Free credits")}
              text={
                t(
                  "Installing and signing up with this affiliate link will give you"
                ) +
                " " +
                signupCreditsBonus +
                " " +
                t("free credits") +
                "."
              }
              icon={getLinkIcon()}
            />
            <InfoItem
              title={t("Sign up now!")}
              text={t(
                "Press on the download buttons above to install the app and sign up."
              )}
              icon={
                <DevicePhoneMobileIcon
                  width={40}
                  color={theme.colors.primary}
                />
              }
            />
          </InfoHeader>
        </InfoHeaderWrapper>

        {true && (
          <>
            <Box sx={{ width: 20, height: 40 }} />

            <WhyWrapper padding={40}>
              <FlexRow justifyContent="center">
                <Text size={32} weight={900} color={theme.colors.primary}>
                  {t("Why Minglify?")}
                </Text>
              </FlexRow>
              <FlexRow justifyContent="center">
                <Text size={24} weight={900}>
                  {t("Meet people that match you")}
                </Text>
              </FlexRow>
              <WhyImageContainer justifyContent="center">
                <WhyImage src={"/images/" + whyImage} />
                {windowSize[0] > 280 && (
                  <WhyImageTextContainer>
                    <StyledText
                      size={windowSize[0] < 380 ? 18 : 28}
                      weight={900}
                      color="#FFF"
                      shadow="1px 1px #000"
                    >
                      {t("Mingle, meet and find your true match!")}
                    </StyledText>
                  </WhyImageTextContainer>
                )}
              </WhyImageContainer>
            </WhyWrapper>

            <FlexCol alignItems="center">
              <WrapContainer padding={20}>
                <FlexRow justifyContent="center" wrap="nowrap">
                  <FlexRow colGap={50}>
                    <FlexCol>
                      <FlexRow>
                        <Text size={24} weight={600}>
                          {t("Trust and security")}
                        </Text>
                      </FlexRow>
                      <FlexRow>
                        <Text size={16}>
                          {t(
                            "At Minglify, our primary objective is to ensure that our users have a pleasant and secure online dating experience. We strive to provide a platform where sincere and serious-minded singles can connect and pursue meaningful relationships. Our commitment to user safety is unwavering, and we have taken measures to safeguard our dating site and app. Every profile and picture is thoroughly reviewed to maintain the highest level of privacy and security for our users. We also offer a reporting system that allows users to flag any profiles or messages that make them feel uncomfortable. Our team is always available to address any concerns and provide support whenever needed."
                          )}
                        </Text>
                      </FlexRow>
                    </FlexCol>
                  </FlexRow>
                  <FlexRow>
                    <ShieldCheckIcon width={100} color={theme.colors.primary} />
                  </FlexRow>
                </FlexRow>
              </WrapContainer>

              <WrapContainer padding={20}>
                <FlexRow justifyContent="center" wrap="nowrap" colGap={10}>
                  <FlexRow>
                    <UsersIcon width={100} color={theme.colors.primary} />
                  </FlexRow>
                  <FlexRow colGap={50}>
                    <FlexCol>
                      <FlexRow>
                        <Text size={24} weight={600}>
                          {t("Detailed profiles")}
                        </Text>
                      </FlexRow>
                      <FlexRow>
                        <Text size={16}>
                          {t(
                            "At Minglify, the user profiles are highly detailed. You can search for matches by using specific criteria or simply by browsing through member profiles."
                          )}
                        </Text>
                      </FlexRow>
                    </FlexCol>
                  </FlexRow>
                </FlexRow>
              </WrapContainer>
            </FlexCol>

            <FlexCol
              backgroundColor={"#E0357D"}
              padding={40}
              alignItems="center"
              rowGap={20}
            >
              <FlexRow
                justifyContent="center"
                textAlign="center"
                maxWidth="900px"
              >
                <Text size={32} weight={900} color="#FFF">
                  {t(
                    "Minglify is the perfect place for singles find their partners, could you be next?"
                  )}
                </Text>
              </FlexRow>
            </FlexCol>

            <FlexCol alignItems="center">
              <WrapContainer padding={20}>
                <FlexRow justifyContent="center" wrap="nowrap">
                  <FlexRow colGap={50}>
                    <FlexCol>
                      <FlexRow textAlign="center">
                        <Text size={24} weight={600}>
                          {t("Multi-language chat")}
                        </Text>
                      </FlexRow>
                      <FlexRow>
                        <Text size={16}>
                          {t(
                            "Engage in conversations with individuals who speak a different language, and have their messages automatically translated into your native language as you communicate with them in real-time. This is an effortless and seamless experience like never before."
                          )}
                        </Text>
                      </FlexRow>
                    </FlexCol>
                  </FlexRow>
                  <FlexRow>
                    <ChatBubbleOvalLeftEllipsisIcon
                      width={100}
                      color={theme.colors.primary}
                    />
                  </FlexRow>
                </FlexRow>
              </WrapContainer>

              <WrapContainer padding={20}>
                <FlexRow justifyContent="center" wrap="nowrap" colGap={10}>
                  <FlexRow>
                    <GlobeAltIcon width={100} color={theme.colors.primary} />
                  </FlexRow>
                  <FlexRow colGap={50}>
                    <FlexCol>
                      <FlexRow textAlign="center">
                        <StyledA
                          href={"/international-dating/" + i18n.language}
                        >
                          <Text size={24} weight={600}>
                            {t("Global online community")}
                          </Text>
                        </StyledA>
                      </FlexRow>
                      <FlexRow>
                        <Text size={16}>
                          {t(
                            "Minglify welcomes registrations from all individuals and boasts of its multi-lingual platform. This feature enables you to explore potential matches beyond your immediate surroundings, expanding your search worldwide. Who knows, your soulmate could be residing in a different country altogether!"
                          )}
                        </Text>
                      </FlexRow>
                    </FlexCol>
                  </FlexRow>
                </FlexRow>
              </WrapContainer>
            </FlexCol>

            <FlexCol alignItems="center">
              <FlexRow maxWidth="900px" justifyContent="center">
                <PaperBox>
                  <FlexRow>
                    <FlexCol>
                      <Text size={32} weight={900} color={theme.colors.blue}>
                        {t("Active Support")}
                      </Text>
                      <Text size={16}>
                        {t(
                          "Minglify prioritize your safety and security. Our team, based in Sweden is always readily available to assist you. If you require any assistance, please do not hesitate to visit our customer service portal or contact us at support@minglify.io. We conduct manual reviews of all uploaded images, and every new member must be approved by us. These measures are put in place to ensure a safe and secure environment for you!"
                        )}
                      </Text>
                    </FlexCol>
                  </FlexRow>
                  <FlexRow justifyContent="center">
                    <StdButton
                      onClick={() => navigate("/help-center/contact-form")}
                    >
                      {t("Contact support")}
                    </StdButton>
                  </FlexRow>
                </PaperBox>
              </FlexRow>
            </FlexCol>

            <Box sx={{ width: 20, height: 100 }} />

            <LoginFooter page="/" />
          </>
        )}
      </PageContainer>
    </div>
  );
};
export default AppInstall;
