import { useTheme } from "@mui/material";
import { Col, Row } from "components/Styled/Container";
import { StyledText } from "components/Styled/Text";
import styled from "styled-components";

export const InfoHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background-color: ${(props) => props.theme.bodyBackground};
  position: relative;
`;

export const InfoHeader = styled.div`
  postion: absolute;
  max-width: 1000px;
  width: 80vw;
  background-color: ${(props) => props.theme.pageBackground};
  display: flex;
  flex-direction: row;
  justify-content: center;

  border-radius: 12px;
  margin-top: -30px;
  column-gap: 40px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 10%);
  padding-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 8px;
  padding-right: 8px;
  flex-basis: 100%;

  @media (max-width: 700px) {
    column-gap: 10px;
  }

  @media (max-width: 600px) {
    align-items: center;
    flex-direction: column;
    row-gap: 60px;
  }
`;
// #18191a
// background-color: ${(props) => props.theme.pageBackground};
export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;

  flex-direction: column;
`;

// height: 80vw;
//   min-height: 600px;
//   max-height: 900px;

export const TopContainer = styled.div<{ fixedBg: boolean }>`
  position: relative;
  width: 100%;
  height: 600px;
  min-height: 600px;

  background: ${(props) => `url(/images/bgImage7.jpg)`} no-repeat center center
    ${(props) => (props.fixedBg === true ? "fixed" : "")};
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 700px) {
    min-height: 600px;
  }
  @media (min-width: 840px) {
    min-height: 700px;
  }
  @media (min-width: 1000px) {
    min-height: 800px;
  }
  @media (min-width: 1200px) {
    min-height: 900px;
  }
`;

export const TopLogo = styled.img`
  width: 15vw;
  maxwidth: 80px;
`;
export const TopLogoText = styled.img`
  width: 50vw;
  max-width: 600px;
`;

export const TopRowEnd = styled(Row)`
  width: 100%;
  justify-content: flex-end;

  @media (max-width: 550px) {
    justify-content: center;
  }
`;

export const ScreensContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ScreensImage = styled.img`
  width: 50vw;
  min-width: 350px;
  max-width: 600px;
`;

export const InfoContainer = styled(Col)`
  align-items: center;
  max-width: 280px;
  text-align: center;
  flex-basis: 100%;

  @media (max-width: 600px) {
    max-width: 80%;
  }
`;

export const InfoBubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-top: -40px;
  background-color: ${(props) => props.theme.bg1};
  row-gap: 3px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 1000px;
  width: 80vw;
`;

export const ContentColumn = styled(Col)`
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

export const StoreImage = styled.img`
  width: 220px;
`;

export const InfoItem = (props: {
  title: string;
  text: string;
  icon: JSX.Element;
}) => {
  const theme = useTheme();
  const { title, text, icon } = props;

  return (
    <InfoContainer>
      <InfoBubble>{icon}</InfoBubble>
      <StyledText size={24} weight={600}>
        {title}
      </StyledText>
      <StyledText size={18} color={theme.colors.gray}>
        {text}
      </StyledText>
    </InfoContainer>
  );
};
