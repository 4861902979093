import create from "zustand";
import { persist } from "zustand/middleware";
import { createSelectors } from "../util/createSelectors";

interface ThemeState {
  theme: string;
  setTheme: (theme: string) => void;
}

// Init dark mode based on browser settings.
let initMode = "light";
if (
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches
) {
  initMode = "dark";
}

const useThemeStoreBase = create<ThemeState>()(
  persist(
    (set) => ({
      theme: initMode,
      setTheme: (theme: string) => set((state) => ({ theme })),
    }),
    {
      name: "theme-storage", // name of item in the storage (must be unique)
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export const useThemeStore = createSelectors(useThemeStoreBase);
