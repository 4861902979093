import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StyledButton from "../../components/Button/Button";
import Text from "../../components/Styled/Text";

const IconImage = styled.img`
  width: 26px;
  height: 26px;
`;

const GoogleButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <StyledButton
        width="100%"
        borderRadius="8px"
        justifyContent="flex-start"
        backgroundColor="#FFF"
        color={theme.colors.gray}
        height="44px"
        colGap="10px"
      >
        <IconImage src="/googleicon.png" />
        <Text size={16} weight={600}>
          {t("Continue with Google")}
        </Text>
      </StyledButton>
    </>
  );
};
export default GoogleButton;
