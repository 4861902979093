import React from "react";
import { useSnackbar, VariantType } from "notistack";
import { XButton } from "../components/Styled/XButton";
import { colonsToEmoji } from "../util/emoji";
import { useTranslation } from "react-i18next";
const uuid = require("uuid");

export const SnackbarAction: React.FC<{ snackbarId: string }> = ({
  snackbarId,
}) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <>
      <XButton
        color="#FFF"
        width={18}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      />
    </>
  );
};

export function useDismissableSnackbar() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showSnackbar = (message: string, variant: VariantType) => {
    const snackbarId = uuid.v4();
    enqueueSnackbar(colonsToEmoji(t(message)), {
      key: snackbarId,
      variant,
      preventDuplicate: true,
      action: <SnackbarAction snackbarId={snackbarId} />,
    });
  };

  return {
    showSnackbar: showSnackbar,
  };
}
