import { Autocomplete, TextField } from "@mui/material";
import styled from "styled-components";
import Text from "../Styled/Text";

const inputStyles = {
  width: "100%",
  fontSize: 15,
  fontWeight: 400,
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
};

const StyledTextField = styled(TextField)`
  width: 100% !important;
`;

const AutoCompleteInput: React.FC<{
  options: string[];
  label: string;
  onChange: any;
  value?: string | string[];
  name?: string;
  clearInputOnSelect?: boolean;
  error?: boolean;
  stringConverter?: (
    value: string,
    translate?: (text: string) => string
  ) => string;
  helperText?: string;
  width?: string;
  multiple?: boolean;
  translator?: (text: string) => string;
}> = (props) => {
  // const [internalValue, setInternalValue] = useState(null);
  const { stringConverter, translator } = props;

  // const clearInputOnSelect =
  //   props.clearInputOnSelect === undefined ? true : props.clearInputOnSelect;

  // const getValue = (): string | string[] | null | undefined => {
  //   logger.log("getValue", props.value);
  //   if (props.value === "") {
  //     return "";
  //   }
  //   if (stringConverter) {
  //     if (typeof props.value === "string") {
  //       return stringConverter(props.value ?? "");
  //     } else {
  //       return props.value;
  //     }
  //   }
  //   return internalValue;
  // };

  const options = props.options;
  if (!options.includes("")) {
    options.push("");
  }

  // const isOptionEqualToValue = (option: unknown, value: unknown) => {
  //   //logger.log("option " + option + ", value " + value);
  //   if (stringConverter) {
  //     return value === stringConverter(option as string);
  //   } else {
  //     return option === value;
  //   }
  // };

  return (
    <>
      <Autocomplete
        //value={props.value || props.value === "" ? props.value : internalValue}
        multiple={props.multiple !== undefined ? props.multiple : false}
        value={props.value}
        //value={props.value}
        blurOnSelect={true}
        //disablePortal
        //id="combo-box-demo"
        options={options}
        //isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={(option: string | string[]): string => {
          //logger.log("getOptionLabel: ", option);
          if (stringConverter) {
            return stringConverter(option as string, translator);
          } else {
            return option as string;
          }
        }}
        sx={{ width: props.width ?? 300 }}
        // onInputChange={(event, newInputValue) => {
        //   logger.log("onInputChange", newInputValue);

        //   if (newInputValue === "") {
        //     return;
        //   }

        //   if (props.options.includes(newInputValue)) {
        //     logger.log("onChange ", newInputValue);
        //     props.onChange(newInputValue);

        //     if (clearInputOnSelect) {
        //       logger.log("Clear input", clearInputOnSelect);
        //       setInternalValue(null);
        //     }
        //   }
        // }}
        onChange={(event, value) => {
          //logger.log("onChange " + value);
          if (value != null) {
            props.onChange(value);
          }
        }}
        renderOption={(props, option) => {
          if (option === "") {
            return null;
          }
          return (
            <li {...props}>
              <Text key={option as string}>
                {stringConverter
                  ? stringConverter(option as string, translator)
                  : (option as string)}
              </Text>
            </li>
          );
        }}
        // getOptionLabel={(option) => option}
        renderInput={(params) => (
          <StyledTextField
            name={props.name}
            color="secondary"
            {...params}
            label={props.label}
            value={props.value}
            //value={props.value}
            InputLabelProps={{
              style: inputStyles,
            }}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </>
  );
};
export default AutoCompleteInput;
