import { Button } from "@mui/material";
import styled from "styled-components";
import { hexToRgbString } from "../../util/helpers";

const StyledButton = styled.button<{
  width?: string;
  borderRadius?: string;
  justifyContent?: string;
  alignItems?: string;
  backgroundColor?: string;
  color?: string;
  height?: string;
  colGap?: string;
}>`
  ${(props) => (props.width !== undefined ? `width: ${props.width};` : "")}
  background-color: ${(props) =>
    props.backgroundColor !== undefined
      ? `${props.backgroundColor}`
      : props.theme.colors.primary};
  color: ${(props) => (props.color !== undefined ? `${props.color}` : "#FFF")};

  //padding: 10px 60px;
  height: ${(props) =>
    props.height !== undefined ? `${props.height}` : "38px"};
  border-radius: ${(props) =>
    props.borderRadius !== undefined ? `${props.borderRadius}` : "10px"};
  //margin: 10px 0px;
  padding-left: 15px;
  padding-right: 15px;
  border: 0;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: ${(props) =>
    props.alignItems !== undefined ? `${props.alignItems}` : "center"};
  justify-content: ${(props) =>
    props.justifyContent !== undefined ? `${props.justifyContent}` : "center"};
  column-gap: ${(props) =>
    props.colGap !== undefined ? `${props.colGap}` : "5px"};

  &:disabled {
    background-color: ${(props) =>
      hexToRgbString(
        props.backgroundColor !== undefined
          ? props.backgroundColor
          : props.theme.colors.primary,
        0.8
      )};
    color: ${(props) => hexToRgbString("#FFF", 0.6)};
    opacity: 0.7;
    cursor: default;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const CloseButton = styled(StyledButton)`
  color: ${(props) => props.theme.secondaryTextColor};
  background-color: ${(props) => props.theme.colors.secondary};
`;

export const StdButton = styled(Button)`
  text-transform: none !important;

  .MuiButtonBase-root {
    text-transform: none !important;
  }
`;

export default StyledButton;
