import { XMarkIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";

export const XButton = styled(XMarkIcon).attrs((props: any) => ({
  color: props.color,
}))`
  color: ${(props) => (props.color ? props.color : props.theme.textColor)};

  &:hover {
    cursor: pointer;
    transition: 0.2s;
    opacity: 0.6;
  }
`;
