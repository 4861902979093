import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";
import { logger } from "util/log";

const libraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];

// göteborg
export const defaultLocation = {
  lat: 57.70887,
  lng: 11.97456,
};

const StyledInput = styled.input`
  border: 0;
  border-color: ${(props) => props.theme.inputBg};
  border-radius: 50px;
  height: 40px;
  background-color: ${(props) => props.theme.inputBg};
  color: ${(props) => props.theme.textColor};
  padding-left: 20px;
  padding-right: 20px;
  outline: 0 !important;
  width: 100%;
`;

type GooglePlacesSearchResult = {
  city: string;
  country: string;
  county: string;
  geo: {
    lat: number;
    lng: number;
  };
};

interface GooglePlacesSearchProps {
  onSelect?: (result: GooglePlacesSearchResult) => void;
}

const GooglePlacesSearch: React.FC<GooglePlacesSearchProps> = (props) => {
  const { isLoaded: isLoadedMapsApi, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA7jBJuZSVoyM4ZTJXfWAT4RsAQHp5lRpE",
    libraries: libraries,
  });
  const { t } = useTranslation();

  //const inputRef = useRef<google.maps.places.SearchBox>();
  const inputRef = useRef<google.maps.places.Autocomplete>();

  const handlePlaceChanged = () => {
    const place = inputRef.current?.getPlace();
    if (place) {
      logger.log(place.formatted_address);
      logger.log(place.geometry?.location?.lat());
      logger.log(place.geometry?.location?.lng());

      const newCenter = {
        lat: place.geometry?.location?.lat() ?? 0,
        lng: place.geometry?.location?.lng() ?? 0,
      };

      // Find city and country
      let address_city = "";
      let address_country = "";
      let address_county = "";
      const address_components = place.address_components;
      address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes("country")) {
          address_country = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          address_county = component.long_name;
        }

        if (types.includes("locality")) {
          address_city = component.long_name;
        } else if (types.includes("postal_town")) {
          address_city = component.long_name;
        }
      });
      logger.log("address_components", address_components);

      if (props.onSelect) {
        props.onSelect({
          city: address_city,
          country: address_country,
          county: address_county,
          geo: newCenter,
        });
      }
    }
  };

  return (
    <>
      {isLoadedMapsApi && (
        <>
          <Autocomplete
            onLoad={(ref) => (inputRef.current = ref)}
            onPlaceChanged={handlePlaceChanged}
            options={{
              fields: ["address_components", "geometry"],
              types: ["(cities)"],
            }}
          >
            <StyledInput
              autoFocus
              className="form-control"
              placeholder={t("Enter Location") ?? "Enter Location"}
            />
          </Autocomplete>
        </>
      )}
      {loadError && <ErrorAlert error={loadError} />}
    </>
  );
};
export default GooglePlacesSearch;
