import { ApolloProvider } from "@apollo/client";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { Suspense, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import router from "./routes/router";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GoogleOAuthProvider } from "@react-oauth/google";
import useGQLClient from "hooks/useGQLClient";
import { SnackbarProvider } from "notistack";
import { useTranslation } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { useThemeStore } from "./hooks/useTheme";
import { darkTheme, lightTheme } from "./theme/MuiTheme"; // styled components theme
import { ampInit } from "./util/amplitude";

export const MAX_UPLOAD_FILE_SIZE = 128000000; // 128MB

const Fallback = () => {
  return null;
};

const App = () => {
  const theme = useThemeStore.use.theme();
  const { i18n } = useTranslation();
  const client = useGQLClient();

  useEffect(() => {
    theme === "dark"
      ? document.documentElement.setAttribute("darkMode", "")
      : document.documentElement.removeAttribute("darkMode");
    theme === "light"
      ? document.documentElement.setAttribute("lightMode", "")
      : document.documentElement.removeAttribute("lightMode");
  }, [theme]);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  ampInit();

  return (
    <Suspense fallback={<Fallback />}>
      <ApolloProvider client={client}>
        <GoogleOAuthProvider clientId="1027171008430-juhsmldquismg4kvek5kpfiqqdtmspcr.apps.googleusercontent.com">
          <MuiThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
            <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider maxSnack={3}>
                  <RouterProvider router={router} />
                </SnackbarProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </GoogleOAuthProvider>
      </ApolloProvider>
    </Suspense>
  );
};
export default App;
