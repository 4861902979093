import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import Cookies from "js-cookie";
import { useAuthStore } from "./useAuth";

const useGQLClient = () => {
  const setAuthenticated = useAuthStore.use.setAuthenticated();
  // const apiDomain =
  //   process.env.NODE_ENV === "development" ? "localhost:4000" : "minglify.io"; // Default to empty string if not set
  const apiDomain = "minglify.io";

  // Default the api urls if the API_DOMAIN is set to production (minglify.io).
  let apiUrl = `https://api-main.${apiDomain}/graphql`;
  let apiAiUrl = `https://api-ai.${apiDomain}/graphql`;
  let apiAuthUrl = `https://api-auth.${apiDomain}/graphql`;
  let apiMediaUrl = `https://api-media.${apiDomain}/graphql`;
  let apiStoreUrl = `https://api-store.${apiDomain}/graphql`;

  if (apiDomain !== "minglify.io") {
    // Not production, then we will use urls specific for the proxy-service, which will proxy requests to
    // the correct locally running service. The proxy-service is running on localhost:4199
    apiUrl = `https://${apiDomain}/api`;
    apiAiUrl = `https://${apiDomain}/api-ai`;
    apiAuthUrl = `https://${apiDomain}/api-auth`;
    apiMediaUrl = `https://${apiDomain}/api-media`;
    apiStoreUrl = `https://${apiDomain}/api-store`;
  }

  console.debug("Using API_URL: " + JSON.stringify(apiUrl));
  console.debug("Using API_AI_URL: " + JSON.stringify(apiAiUrl));
  console.debug("Using API_AUTH_URL: " + JSON.stringify(apiAuthUrl));
  console.debug("Using API_MEDIA_URL: " + JSON.stringify(apiMediaUrl));
  console.debug("Using API_STORE_URL: " + JSON.stringify(apiStoreUrl));

  const clearAuth = () => {
    console.debug("Clearing auth...");
    Cookies.remove("session_token");
    sessionStorage.removeItem("auth-storage");
    sessionStorage.removeItem("chat-storage");
    sessionStorage.removeItem("notification-storage");
    setAuthenticated(false);
  };

  const errorLink = onError(
    ({ forward, graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (message === "Missing authorization token in request.") {
            clearAuth();
          }
          if (
            message ===
            "Your account has been banned. Account is banned for not following the user terms and policy."
          ) {
            // Banned account, lets handle that.
            clearAuth();
          }
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
        console.error("error", networkError);
        if (
          networkError.message ===
          "Response not successful: Received status code 401"
        ) {
          // Means we are not authorized. We should then redirect to the login screen.
          clearAuth();
        }
        // else {
        //   Toast.show(
        //     colonsToEmoji(t("Network error occurred. Please try again.")),
        //     {
        //       duration: 3500,
        //       backgroundColor: "#CD5146",
        //       position: Toast.positions.TOP + 50,
        //     }
        //   );
        // }
      }
    }
  );

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // TODO, maybe calling getItemAsync is slow.. perhaps should store the token in a state.
    // but then we need to make sure that the state is updated when the token is updated.
    const token = localStorage.getItem("auth_token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const apiLink = createHttpLink({
    uri: apiUrl,
  });
  const apiAiLink = createHttpLink({
    uri: apiAiUrl,
  });
  const apiAuthLink = createHttpLink({
    uri: apiAuthUrl,
  });
  const apiMediaLink = createHttpLink({
    uri: apiMediaUrl,
  });
  const apiStoreLink = createHttpLink({
    uri: apiStoreUrl,
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: 3000,
    },
  });

  const client = new ApolloClient({
    //link: authLink.concat(httpLink),
    //uri: "http://localhost:4000",
    cache: new InMemoryCache(),
    link: from([
      errorLink,
      retryLink,
      authLink.concat(
        ApolloLink.split(
          (operation) => operation.getContext().service === "api-ai",
          apiAiLink, // if true, use api-ai link
          ApolloLink.split(
            (operation) => operation.getContext().service === "api-auth",
            apiAuthLink, // if true, use api-auth link
            ApolloLink.split(
              (operation) => operation.getContext().service === "api-media",
              apiMediaLink, // if true, use api-media link
              ApolloLink.split(
                (operation) => operation.getContext().service === "api-store",
                apiStoreLink, // if true, use api-media link
                apiLink // defaults to apiLink
              )
            )
          )
        )
      ),
    ]),
  });

  return client;
};
export default useGQLClient;
