import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  max-width: 400px;
  min-height: 200px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;

  /* @keyframes avatar-bounce {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }
  @media (prefers-reduced-motion: no-preference) {
    .match-dialog-avatar {
      animation: avatar-bounce infinite 3s linear;
    }
  } */
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
