import { useMutation, useQuery } from "@apollo/client";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { TextField, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { logger } from "util/log";
import AutoCompleteInput from "../../components/AutoCompleteInput/AutoCompleteInput";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import FormikControl from "../../components/Form/FormikControl";
import HrDivider from "../../components/HrDivider/HrDivider";
import { ModalHeader, StyledModal } from "../../components/Modal/Modal";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import { StyledLink } from "../../components/Styled/StyledLink";
import Text from "../../components/Styled/Text";
import { XButton } from "../../components/Styled/XButton";
import { Gender, ProfileLocationInput } from "../../graphql/graphql";
import { GOOGLE_LOGIN, GOOGLE_SIGNUP } from "../../graphql/mutations-gql";
import { USERNAME_AVAILABLE } from "../../graphql/queries-gql";
import { useAuthStore } from "../../hooks/useAuth";
import { useDismissableSnackbar } from "../../hooks/useDismissableSnackbar";
import useLocationDialog from "../../hooks/useLocationDialog";
import i18n from "../../i18n";
import GoogleLoginImage from "../../svg/GoogleLoginImage";
import { track } from "../../util/amplitude";
import { calculateAge } from "../../util/dates";
import { genderPluralString, genderString } from "../../util/helpers";

// Script from index.html in public. Used for tracking signup success with google ads.
declare const gtag_report_signup: any;

const SignupForm = styled(Form)`
  width: 100%;
`;

const genderOptions = () => {
  const options = Object.values(Gender).filter(
    (option) => option !== Gender.Undisclosed
  );
  return options;
};

const SocialLoginButtonWrapper = styled.div`
  max-width: 280px;
  width: 280px;
  &:hover {
    cursor: pointer;
  }
`;

export const UsernameAvailable: React.FC<{
  username: string;
  setUsernameAvailable: (available: boolean) => void;
}> = ({ username, setUsernameAvailable }) => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(USERNAME_AVAILABLE, {
    variables: { username },
    onCompleted: (data) => {
      logger.log("USERNAME_AVAILABLE onCompleted", data);
      if (data.usernameAvailable === true) {
        setUsernameAvailable(true);
      } else {
        setUsernameAvailable(false);
      }
    },
  });

  if (!username || username.length < 3) {
    return null;
  }

  if (loading) {
    return null;
  }

  if (data.usernameAvailable === true) {
    return null;
  }

  return (
    <>
      <ErrorAlert
        error={t("Username is not available") ?? "Username is not available"}
        //onClose={() => logger.log("close")}
      />
    </>
  );
};

const GoogleSignupModal: React.FC<{
  show: boolean;
  hide: () => void;
  token: string;
  invite: string | null;
  signupRef: string | null;
}> = ({ show, hide, token, invite, signupRef }) => {
  const [usernameToValidate, setUsernameToValidate] = useState("");
  const [usernameAvailable, setUsernameAvailable] = useState(true);
  const [signup, { loading, error, reset }] = useMutation(GOOGLE_SIGNUP);
  const theme = useTheme();
  const [googleLoginMutation, { error: googleLoginError }] =
    useMutation(GOOGLE_LOGIN);
  const setAuthenticated = useAuthStore.use.setAuthenticated();
  const { t } = useTranslation();
  const [location, setLocation] = useState<ProfileLocationInput | null>(null);
  const { showDialog: showLocationDialog, component: locationDialog } =
    useLocationDialog(location, (location) => {
      logger.log("location", location);
      setLocation(location);
    });
  const { showSnackbar } = useDismissableSnackbar();

  const close = () => {
    setUsernameToValidate("");
    setUsernameAvailable(true);
    //setValidatedOk(false);
    hide();
  };

  const renderLocation = () => {
    if (!location) {
      return (
        <Text
          onClick={() => showLocationDialog()}
          size={12}
          color={theme.colors.gray}
        >
          {t("You must select your location.")}
        </Text>
      );
    } else {
      return (
        <FlexRow>
          <Text size={15} color={theme.colors.gray}>
            {location.city}, {location.county}, {location.country}
          </Text>
        </FlexRow>
      );
    }
  };

  return (
    <>
      {locationDialog}
      <StyledModal
        width={400}
        open={show}
        onClose={() => {
          close();
        }}
      >
        <ModalHeader>
          <Text weight={600} size={18}>
            {t("Continue with Google")}
          </Text>
          <XButton width={24} onClick={() => hide()} />
        </ModalHeader>
        <HrDivider />

        <FlexCol padding={0} justifyContent="center" alignItems="center">
          <FlexRow justifyContent="center">
            <Text size={16} color={theme.colors.gray}>
              {t(
                "To continue login using Google you must provide the following data for your new profile at Minglify."
              )}
            </Text>
          </FlexRow>

          <Formik
            initialValues={{
              username: "",
              birthdate: "",
              gender: "",
              interestedIn: [],
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validate={(values) => {
              const errors: {
                username?: string;
                birthdate?: string;
                gender?: string;
                interestedIn?: string[];
              } = {};

              if (!values.username) {
                errors.username =
                  t("Enter a desired username") ?? "Enter a desired username";
              } else if (values.username.length < 3) {
                errors.username =
                  t("Too short username. It must be atleast 3 characters.") ??
                  "Too short username. It must be atleast 3 characters.";
              } else if (!/^[a-zA-Z0-9]*$/i.test(values.username)) {
                errors.username =
                  t("Invalid characters in username.") ??
                  "Invalid characters in username.";
              }

              if (!errors.username) {
                setUsernameToValidate(values.username ?? "");
              }

              // if (!errors.username || errors.username.length < 1) {
              //   setUsernameToValidate(values.username ?? "");
              // }

              if (!values.birthdate) {
                errors.birthdate =
                  t("You must select your birthdate") ??
                  "You must select your birthdate";
              } else if (values.birthdate === "Invalid Date") {
                errors.birthdate =
                  t("Invalid date format") ?? "Invalid date format";
              } else {
                const age = calculateAge(values.birthdate);
                if (!age) {
                  errors.birthdate =
                    t("Invalid date format") ?? "Invalid date format";
                } else if (age < 18) {
                  errors.birthdate =
                    t("You must be at least 18 years to sign up.") ??
                    "You must be at least 18 years to sign up.";
                }
              }

              if (!values.gender) {
                errors.gender =
                  t("You must select a gender") ?? "You must select a gender";
              }

              if (!values.interestedIn || values.interestedIn.length < 1) {
                errors.interestedIn = [
                  t("Please select what you are interested in") ??
                    "Please select what you are interested in",
                ];
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (!usernameAvailable) {
                setSubmitting(false);
                return;
              }

              if (!location) {
                setSubmitting(false);
                showSnackbar(t("You must select your location."), "error");
                return;
              }

              signup({
                variables: {
                  context: { service: "api-auth" },
                  args: {
                    token: token,
                    username: values.username,
                    birthdate: values.birthdate,
                    gender: values.gender,
                    interestedIn: values.interestedIn,
                    invite,
                    signupRef,
                    location: location,
                    lang: i18n.language,
                  },
                },
                onCompleted: (data) => {
                  setSubmitting(false);
                  track("Web Signup Success", { signupType: "Google" });
                  gtag_report_signup();

                  // Now we should perform the actual google login as well.
                  googleLoginMutation({
                    variables: {
                      context: { service: "api-auth" },
                      args: {
                        token,
                        appPlatform: "web",
                      },
                    },
                    onCompleted: (data) => {
                      logger.log("googleLogin onCompleted", data);
                      if (data.googleLogin.authenticated) {
                        setAuthenticated(true);
                      }
                    },
                    onError: (error) => {
                      logger.log("googleLogin onError", error);
                    },
                  });
                },
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValidating,
              setFieldValue,
              /* and other goodies */
            }) => {
              //logger.log("touched", touched);
              //logger.log("values", values);
              return (
                <>
                  <SignupForm
                    id="signupForm"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <FlexCol padding={10}>
                      <FlexRow justifyContent="center">
                        <FormikControl
                          type="text"
                          constrolId="signupUsername"
                          label={t("New username")}
                          name="username"
                          value={values.username}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={
                            errors.username
                              ? errors.username
                              : usernameAvailable
                              ? ""
                              : t("Username not available.")
                          }
                          touched={touched.username}
                          placeholder={t("Type a desired username")}
                          helperText={
                            "Unique username. Allowed characters are letters and digits."
                          }
                        />
                      </FlexRow>
                      <FlexRow>
                        {usernameToValidate &&
                          usernameToValidate.length > 2 && (
                            <UsernameAvailable
                              username={usernameToValidate}
                              setUsernameAvailable={setUsernameAvailable}
                            />
                          )}
                      </FlexRow>

                      <FlexRow>
                        <DatePicker
                          label={"Your birthdate"}
                          inputFormat="yyyy-MM-dd"
                          value={values.birthdate}
                          onChange={(newValue) => {
                            logger.log("newValue", newValue);

                            //handleChange(e);
                            setFieldValue("birthdate", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: "100%" }}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              color="secondary"
                              //focused={touched.birthdate}
                              {...params}
                              error={
                                errors.birthdate && touched.birthdate
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.birthdate && touched.birthdate
                                  ? errors.birthdate
                                  : null
                              }
                            />
                          )}
                        />
                      </FlexRow>

                      <FlexRow>
                        <AutoCompleteInput
                          multiple={false}
                          width={"100%"}
                          name="gender"
                          stringConverter={genderString}
                          translator={t}
                          value={values.gender}
                          options={genderOptions()}
                          label={t("Your gender")}
                          clearInputOnSelect={false}
                          error={errors.gender ? true : false}
                          onChange={(value: string) =>
                            setFieldValue("gender", value)
                          }
                          helperText={errors.gender}
                        />
                      </FlexRow>

                      <FlexRow>
                        <AutoCompleteInput
                          multiple={true}
                          width={"100%"}
                          name="interestedIn"
                          stringConverter={genderPluralString}
                          translator={t}
                          value={values.interestedIn}
                          options={genderOptions()}
                          label={t("I'm interested in")}
                          clearInputOnSelect={false}
                          error={errors.interestedIn ? true : false}
                          onChange={(value: string) =>
                            setFieldValue("interestedIn", value)
                          }
                          helperText={errors.interestedIn as string}
                        />
                      </FlexRow>

                      <FlexCol padding={0} paddingBottom={10} paddingTop={10}>
                        <FlexRow justifyContent="space-between">
                          <Text size={15}>{t("Your location")}</Text>
                          <FlexRow>
                            <MapPinIcon
                              width={18}
                              color={theme.colors.green}
                              onClick={() => showLocationDialog()}
                            />
                            <Text
                              onClick={() => showLocationDialog()}
                              size={15}
                              color={theme.colors.primary}
                            >
                              {t("Select location")}
                            </Text>
                          </FlexRow>
                        </FlexRow>
                        <FlexRow>{renderLocation()}</FlexRow>
                      </FlexCol>
                    </FlexCol>
                  </SignupForm>
                  {error && (
                    <FlexCol>
                      <ErrorAlert
                        key="error_alert"
                        error={error}
                        onClose={() => reset()}
                      />
                    </FlexCol>
                  )}
                  {googleLoginError && (
                    <FlexCol>
                      <ErrorAlert key="error_alert" error={googleLoginError} />
                    </FlexCol>
                  )}

                  <FlexRow>
                    <Text size={12} color={theme.colors.gray}>
                      {t("By clicking")} {t("Continue with Google")},{" "}
                      {t("you agree to our")}{" "}
                      <StyledLink to="/help-center/terms">
                        {t("Terms")}
                      </StyledLink>
                      {". "}{" "}
                      {t(
                        "Learn how we collect, use and share your data in our"
                      )}{" "}
                      <StyledLink to="/help-center/privacy">
                        {t("Privacy policy")}
                      </StyledLink>{" "}
                      {t(
                        "and how we use cookies and similar technology in our"
                      )}{" "}
                      <StyledLink to="/help-center/cookies">
                        {t("Cookie policy")}
                      </StyledLink>
                      .
                    </Text>
                  </FlexRow>

                  <FlexCol padding={0}>
                    <FlexRow justifyContent="center">
                      <SocialLoginButtonWrapper
                        onClick={() => {
                          if (loading) {
                            return; // Dont trigger more submits during loading
                          }
                          handleSubmit();
                        }}
                      >
                        <GoogleLoginImage />
                      </SocialLoginButtonWrapper>
                    </FlexRow>
                  </FlexCol>
                </>
              );
            }}
          </Formik>
        </FlexCol>
      </StyledModal>
    </>
  );
};
export default GoogleSignupModal;
