import styled, { css } from "styled-components";

const Text = styled.span.attrs((props: any) => ({
  size: props.size || 15,
  weight: props.weight || 400,
  color: props.color
    ? props.color + " !important"
    : props.theme.textColor + " default",
}))`
  white-space: pre-line;
  overflow-wrap: break-word;
  font-size: ${(props) => props.size}px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: ${(props) => props.weight};
  -webkit-font-smoothing: antialiased;
  color: ${(props) => props.color ?? ""};
  ${(props) =>
    props.onClick
      ? css`
          &:hover {
            cursor: pointer;
          }
        `
      : null};
`;

export const SpanItalic = styled.span`
  font-style: italic;
`;

export const CapText = styled(Text)`
  text-transform: capitalize;
`;

export const StyledText = styled(Text)<{ shadow?: string; stroke?: string }>`
  ${(props) =>
    props.shadow
      ? css`
          text-shadow: ${props.shadow};
        `
      : null};
  ${(props) =>
    props.stroke
      ? css`
          -webkit-text-stroke: ${props.stroke};
        `
      : null};
`;

export default Text;
