import { gql } from "@apollo/client";

export const GOOGLE_LOGIN = gql`
  mutation googleLogin($args: GoogleLoginArgs!) {
    googleLogin(args: $args) {
      authenticated
      userExists
      token
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation facebookLogin($args: FacebookLoginArgs!) {
    facebookLogin(args: $args) {
      authenticated
      userExists
      token
    }
  }
`;

export const LOGIN = gql`
  mutation login($args: LoginArgs!) {
    login(args: $args) {
      authenticated
      token
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword(
    $token: String!
    $newPassword: String!
    $captcha: String!
  ) {
    updatePassword(token: $token, newPassword: $newPassword, captcha: $captcha)
  }
`;

export const DELETE_PHOTO = gql`
  mutation deletePhoto($uploadId: String!) {
    deletePhoto(uploadId: $uploadId)
  }
`;

export const DELETE_PRIVATE_PHOTO = gql`
  mutation deletePrivatePhoto($uploadId: String!) {
    deletePrivatePhoto(uploadId: $uploadId)
  }
`;

export const DELETE_VIDEO = gql`
  mutation deleteVideo($uploadId: String!) {
    deleteVideo(uploadId: $uploadId)
  }
`;

export const DELETE_PRIVATE_VIDEO = gql`
  mutation deletePrivateVideo($uploadId: String!) {
    deletePrivateVideo(uploadId: $uploadId)
  }
`;

export const SET_PROFILE_IMAGE = gql`
  mutation setProfileImage($uploadId: String!) {
    setProfileImage(uploadId: $uploadId)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile($profile: ProfileInput!) {
    updateProfile(profile: $profile)
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($settings: SettingsInput!) {
    updateSettings(settings: $settings)
  }
`;

export const UPDATE_FILTER = gql`
  mutation updateFilter($input: PeopleFilterInput!) {
    updateFilter(input: $input)
  }
`;

export const ADD_PRIVATE_PHOTOS_ACCESS = gql`
  mutation addPrivatePhotoAccess($userId: String!) {
    addPrivatePhotoAccess(userId: $userId)
  }
`;

export const REMOVE_PRIVATE_PHOTOS_ACCESS = gql`
  mutation removePrivatePhotoAccess($userId: String!) {
    removePrivatePhotoAccess(userId: $userId)
  }
`;

export const CLEAR_ALL_NO_VOTES = gql`
  mutation clearAllNoVotes {
    clearAllNoVotes
  }
`;

export const ADD_SPOTLIGHT = gql`
  mutation addSpotlight {
    addSpotlight
  }
`;

export const SET_MOOD = gql`
  mutation setMood($emojiString: String!) {
    setMood(emojiString: $emojiString)
  }
`;

export const UPLOAD_VIDEO = gql`
  mutation uploadVideo($args: VideoUploadArgs!) {
    uploadVideo(args: $args) {
      uploadId
      uploadPostInfo {
        fields {
          key
          value
        }
        postUrl
      }
    }
  }
`;

export const UPLOAD_PRIVATE_VIDEO = gql`
  mutation uploadPrivateVideo($args: VideoUploadArgs!) {
    uploadPrivateVideo(args: $args) {
      uploadId
      uploadPostInfo {
        fields {
          key
          value
        }
        postUrl
      }
    }
  }
`;

export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($args: PhotoUploadArgs!) {
    uploadPhoto(args: $args) {
      uploadId
      uploadPostInfo {
        fields {
          key
          value
        }
        postUrl
      }
    }
  }
`;

export const UPLOAD_PRIVATE_PHOTO = gql`
  mutation uploadPrivatePhoto($args: PhotoUploadArgs!) {
    uploadPrivatePhoto(args: $args) {
      uploadId
      uploadPostInfo {
        fields {
          key
          value
        }
        postUrl
      }
    }
  }
`;

export const REMOVE_FRIEND = gql`
  mutation removeFriend($friendId: String!) {
    removeFriend(friendId: $friendId)
  }
`;

export const SEND_FRIEND_REQUEST = gql`
  mutation sendFriendRequest($friendId: String!) {
    sendFriendRequest(friendId: $friendId)
  }
`;

export const ACCEPT_FRIEND_REQUEST = gql`
  mutation acceptFriendRequest($friendId: String!) {
    acceptFriendRequest(friendId: $friendId)
  }
`;

export const DENY_FRIEND_REQUEST = gql`
  mutation denyFriendRequest($friendId: String!) {
    denyFriendRequest(friendId: $friendId)
  }
`;

export const REMOVE_FRIEND_REQUEST = gql`
  mutation removeFriendRequest($friendId: String!) {
    removeFriendRequest(friendId: $friendId)
  }
`;

export const ADD_FOLLOW = gql`
  mutation addFollow($userId: String!) {
    addFollow(userId: $userId)
  }
`;
export const REMOVE_FOLLOW = gql`
  mutation removeFollow($userId: String!) {
    removeFollow(userId: $userId)
  }
`;

export const ADD_BLOCK = gql`
  mutation addBlock($userId: String!) {
    addBlock(userId: $userId)
  }
`;
export const REMOVE_BLOCK = gql`
  mutation removeBlock($userId: String!) {
    removeBlock(userId: $userId)
  }
`;

export const ADD_FAVORITE = gql`
  mutation addFavorite($userId: String!) {
    addFavorite(userId: $userId)
  }
`;
export const REMOVE_FAVORITE = gql`
  mutation removeFavorite($userId: String!) {
    removeFavorite(userId: $userId)
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation readAllNotifications {
    readAllNotifications
  }
`;

export const READ_NOTIFICATION = gql`
  mutation readNotification($notificationId: String!) {
    readNotification(notificationId: $notificationId)
  }
`;

export const VIEW_ALL_NOTIFICATIONS = gql`
  mutation viewAllNotifications {
    viewAllNotifications
  }
`;

export const ADD_MESSAGE_REACTION = gql`
  mutation addMessageReaction($messageId: String!, $reaction: String!) {
    addMessageReaction(messageId: $messageId, reaction: $reaction)
  }
`;

export const REMOVE_MESSAGE_REACTION = gql`
  mutation removeMessageReaction($messageId: String!) {
    removeMessageReaction(messageId: $messageId)
  }
`;

export const TYPING_IN_CHAT = gql`
  mutation typingInChat($chatId: String!, $status: Boolean!) {
    typingInChat(chatId: $chatId, status: $status)
  }
`;

export const VIEW_CHAT = gql`
  mutation viewChat($chatId: String!) {
    viewChat(chatId: $chatId)
  }
`;

export const SEND_MESSAGE_TO_CHAT = gql`
  mutation sendMessageToChat($args: SendMessageToChatArgs!) {
    sendMessageToChat(args: $args) {
      message {
        _id
        clientId
        text
        created
        fromId
        viewedByIds
        attachments {
          contentType
          url
          thumbnailUrl
          expired
          deleted
        }
      }
      newMessageId
      attachments {
        postUrl
        fields {
          key
          value
        }
      }
    }
  }
`;

export const GOOGLE_SIGNUP = gql`
  mutation googleSignup($args: GoogleSignupArgs!) {
    googleSignup(args: $args)
  }
`;

export const FACEBOOK_SIGNUP = gql`
  mutation facebookSignup($args: FacebookSignupArgs!) {
    facebookSignup(args: $args)
  }
`;

export const SIGNUP = gql`
  mutation signup($args: EmailSignupArgs!) {
    signup(args: $args)
  }
`;

export const MATCH = gql`
  mutation match($userId: String!, $value: Boolean!, $hotLike: Boolean) {
    match(userId: $userId, value: $value, hotLike: $hotLike) {
      isMatch
      user {
        _id # need more info?
      }
      yesVotesLeft
      hotLikes
      hotLikesDate
    }
  }
`;

export const DEACTIVATE_ACCOUNT = gql`
  mutation deactivate($value: Boolean!) {
    deactivate(value: $value)
  }
`;

export const ACTIVATE_PREMIUM = gql`
  mutation activatePremium {
    activatePremium
  }
`;

export const NEW_POST = gql`
  mutation newPost($args: NewPostArgs!) {
    newPost(args: $args) {
      newPostId
      attachments {
        fileName
        postUrl
        fields {
          key
          value
        }
      }
    }
  }
`;

export const NEW_COMMENT = gql`
  mutation newComment($args: NewCommentArgs!) {
    newComment(args: $args) {
      newCommentId
      attachments {
        postUrl
        fields {
          key
          value
        }
      }
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($postId: String!) {
    deletePost(postId: $postId)
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: String!) {
    deleteComment(commentId: $commentId)
  }
`;

export const REACT_TO_POST = gql`
  mutation reactToPost($postId: String!, $value: String!) {
    reactToPost(postId: $postId, value: $value)
  }
`;

export const REACT_TO_COMMENT = gql`
  mutation reactToComment($commentId: String!, $value: String!) {
    reactToComment(commentId: $commentId, value: $value)
  }
`;

export const NEW_GROUP = gql`
  mutation newGroup($args: NewGroupArgs!) {
    newGroup(args: $args) {
      groupId
    }
  }
`;

export const JOIN_GROUP = gql`
  mutation joinGroup($groupId: String!) {
    joinGroup(groupId: $groupId)
  }
`;

export const LEAVE_GROUP = gql`
  mutation leaveGroup($groupId: String!) {
    leaveGroup(groupId: $groupId)
  }
`;

export const REQUEST_MEMBERSHIP = gql`
  mutation requestMembership($groupId: String!) {
    requestMembership(groupId: $groupId)
  }
`;

export const UPLOAD_GROUP_IMAGE = gql`
  mutation uploadGroupImage($groupId: String!, $args: FileUploadArgs!) {
    uploadGroupImage(groupId: $groupId, args: $args) {
      uploadId
      uploadPostInfo {
        fields {
          key
          value
        }
        postUrl
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($groupId: String!, $args: UpdateGroupArgs!) {
    updateGroup(groupId: $groupId, args: $args)
  }
`;

export const ADD_YES_VOTES = gql`
  mutation addYesVotes {
    addYesVotes
  }
`;

export const SEND_GIFT = gql`
  mutation sendGift($toUserId: String!, $message: String, $credits: Int!) {
    sendGift(toUserId: $toUserId, message: $message, credits: $credits)
  }
`;

export const CLAIM_GIFT = gql`
  mutation claimGift($giftId: String!) {
    claimGift(giftId: $giftId)
  }
`;

export const ACCEPT_GROUP_INVITE = gql`
  mutation acceptGroupInvite($groupId: String!) {
    acceptGroupInvite(groupId: $groupId)
  }
`;
export const REJECT_GROUP_INVITE = gql`
  mutation rejectGroupInvite($groupId: String!) {
    rejectGroupInvite(groupId: $groupId)
  }
`;

export const INVITE_MEMBER = gql`
  mutation inviteMember($userId: String!, $groupId: String!) {
    inviteMember(userId: $userId, groupId: $groupId)
  }
`;

export const REMOVE_INVITATION = gql`
  mutation removeInvitation($userId: String!, $groupId: String!) {
    removeInvitation(userId: $userId, groupId: $groupId)
  }
`;

export const ACCEPT_GROUP_REQUEST = gql`
  mutation acceptGroupRequest($userId: String!, $groupId: String!) {
    acceptGroupRequest(userId: $userId, groupId: $groupId)
  }
`;

export const REJECT_GROUP_REQUEST = gql`
  mutation rejectGroupRequest($userId: String!, $groupId: String!) {
    rejectGroupRequest(userId: $userId, groupId: $groupId)
  }
`;

export const BAN_USER = gql`
  mutation banUser($userId: String!, $groupId: String!) {
    banUser(userId: $userId, groupId: $groupId)
  }
`;

export const UNBAN_USER = gql`
  mutation unbanUser($userId: String!, $groupId: String!) {
    unbanUser(userId: $userId, groupId: $groupId)
  }
`;

export const KICK_USER = gql`
  mutation kickUser($userId: String!, $groupId: String!) {
    kickUser(userId: $userId, groupId: $groupId)
  }
`;

export const CLAIM_FREE_PREMIUM = gql`
  mutation claimFreePremium {
    claimFreePremium
  }
`;

export const SET_TRANSLATION = gql`
  mutation setTranslation($chatId: String!, $status: Boolean!) {
    setTranslation(chatId: $chatId, status: $status)
  }
`;

export const PURCHASE_TRANSLATION_TOKENS = gql`
  mutation purchaseTranslationTokens($credits: Int!) {
    purchaseTranslationTokens(credits: $credits)
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const SEND_REPORT = gql`
  mutation sendReport($args: ReportArgs!) {
    sendReport(args: $args)
  }
`;

export const READY_TO_CHAT = gql`
  mutation readyToChat {
    readyToChat
  }
`;

export const CREATE_PAYPAL_ORDER = gql`
  mutation createPaypalOrder($credits: Int!) {
    createPaypalOrder(credits: $credits) {
      id
    }
  }
`;

export const CAPTURE_PAYPAL_ORDER = gql`
  mutation capturePaypalOrder($orderID: String!) {
    capturePaypalOrder(orderID: $orderID) {
      success
      credits
    }
  }
`;

export const CREATE_STRIPE_PAYMENT_CHECKOUT_SESSION = gql`
  mutation createStripePaymentCheckoutSession($credits: Int!) {
    createStripePaymentCheckoutSession(credits: $credits) {
      redirectUrl
    }
  }
`;

export const CREATE_STRIPE_SUBSCRIPTION_CHECKOUT_SESSION = gql`
  mutation createStripeSubscriptionCheckoutSession($period: String!) {
    createStripeSubscriptionCheckoutSession(period: $period) {
      redirectUrl
    }
  }
`;

export const UPLOAD_REEL = gql`
  mutation uploadReel($args: ReelUploadArgs!) {
    uploadReel(args: $args) {
      uploadId
      uploadPostInfo {
        fields {
          key
          value
        }
        postUrl
      }
    }
  }
`;

export const VIEW_REEL = gql`
  mutation viewReel($reelId: String!) {
    viewReel(reelId: $reelId)
  }
`;

export const DELETE_REEL = gql`
  mutation deleteReel($reelId: String!) {
    deleteReel(reelId: $reelId)
  }
`;

export const UPDATE_EMAIL_SUBSCRIPTION_SETTINGS = gql`
  mutation updateEmailSubscriptionSettings(
    $input: EmailSubscriptionSettingsInput!
  ) {
    updateEmailSubscriptionSettings(input: $input)
  }
`;

export const BOOST_PROFILE = gql`
  mutation boostProfile {
    boostProfile
  }
`;

export const PURCHASE_HOT_LIKES = gql`
  mutation purchaseHotLikes($amount: Int!) {
    purchaseHotLikes(amount: $amount)
  }
`;

export const CLAIM_ACHIEVEMENT = gql`
  mutation claimAchievement($achievementId: AchievementId!) {
    claimAchievement(achievementId: $achievementId)
  }
`;

export const PERMANENTLY_DELETE = gql`
  mutation permanentlyDelete($args: DeleteArgs) {
    permanentlyDelete(args: $args)
  }
`;

export const AFFILIATE_INSTALL_CLICK = gql`
  mutation affiliateInstallClick($affiliateLink: String!) {
    affiliateInstallClick(affiliateLink: $affiliateLink)
  }
`;
