import EmojiConvertor from "emoji-js";

//https://github.com/iamcal/js-emoji

export const emojisToColons = (text: string): string => {
  const emoji = new EmojiConvertor();
  emoji.img_set = "facebook";

  //emoji.replace_mode = "colons";
  //emoji.allow_native = true;
  emoji.colons_mode = true;

  return emoji.replace_unified(text);

  // return emoji.replace_emoticons_with_colons(text);
  //return emoji.replace_emoticons_with_colons(text);
};

export const colonsToEmoji = (text: string, mode?: "img" | "css"): string => {
  const emoji = new EmojiConvertor();
  emoji.img_set = "facebook";
  let result = text;
  if (mode !== undefined) {
    emoji.replace_mode = mode;
  }
  emoji.replace_mode = "unified";

  // First replace things like :) into colons emoji
  result = result.replaceAll("<3", ":heart:");
  result = emoji.replace_emoticons_with_colons(result);
  //let result = text.replaceAll(":)", ":smile:");
  //result = result.replaceAll("<3", ":heart:");

  // Now turn colons into emojis
  result = emoji.replace_colons(result);

  //logger.log("colonsToEmoji", result);

  return result;
};
