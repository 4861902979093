import { useLazyQuery, useMutation } from "@apollo/client";
import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Box, CircularProgress, Dialog, IconButton } from "@mui/material";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logger } from "util/log";
import {
  PlaceAutocomplete,
  ProfileLocationInput,
} from "../../../graphql/graphql";
import { UPDATE_PROFILE } from "../../../graphql/mutations-gql";
import {
  MATCHING,
  ME,
  ME_PROFILE,
  NEARBY_PEOPLE,
  NEW_USERS,
  PLACE_AUTOCOMPLETE,
  RECENT_LOGINS,
  REVERSE_GEOCODE,
  SPOTLIGHTS,
} from "../../../graphql/queries-gql";
import { useDismissableSnackbar } from "../../../hooks/useDismissableSnackbar";
import { useGeoLocation } from "../../../hooks/useGeoLocation";
import AutoCompleteSearch from "../../AutoCompleteSearch/AutoCompleteSearch";
import { StdButton } from "../../Button/Button";
import { ErrorAlert } from "../../ErrorAlert/ErrorAlert";
import { defaultLocation } from "../../GooglePlacesSearch/GooglePlacesSearch";
import { Alert } from "../../Styled/Alert";
import { FlexCol, FlexRow } from "../../Styled/Container";
import { StyledLink } from "../../Styled/StyledLink";
import Text from "../../Styled/Text";
import { CloseButtonContainer, Content } from "./styled";

const libraries: (
  | "places"
  | "drawing"
  | "geometry"
  | "localContext"
  | "visualization"
)[] = ["places"];

const getInitLocation = (location: ProfileLocationInput | undefined | null) => {
  if (
    location &&
    location.geo &&
    location.geo.coordinates &&
    location.geo.coordinates &&
    location.geo.coordinates.length > 1 &&
    location.geo.coordinates[0] !== 0 &&
    location.geo.coordinates[1] !== 0
  ) {
    const lat = location.geo.coordinates[1] ?? defaultLocation.lat;
    const lng = location.geo.coordinates[0] ?? defaultLocation.lng;
    return {
      lat,
      lng,
    };
  } else {
    return defaultLocation;
  }
};

const containerStyle = {
  width: "100%",
  height: "150px",
};

const LocationDialog: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  onSave?: (location: ProfileLocationInput) => void;
  initLocation?: ProfileLocationInput | null;
}> = ({ open, setOpen, onSave, initLocation }) => {
  const { showSnackbar } = useDismissableSnackbar();
  const { isLoaded: isLoadedMapsApi, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA7jBJuZSVoyM4ZTJXfWAT4RsAQHp5lRpE",
    libraries: libraries,
  });
  const [center, setCenter] = useState(getInitLocation(initLocation));
  const [country, setCountry] = useState(initLocation?.country ?? "");
  const [city, setCity] = useState(initLocation?.city ?? "");
  const [county, setCounty] = useState(initLocation?.county ?? "");
  const { error, fetchLocation, reset } = useGeoLocation();
  const [updateProfile] = useMutation(UPDATE_PROFILE);
  const [reverseGeocode, { loading: geocodeLoading }] =
    useLazyQuery(REVERSE_GEOCODE);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const { t } = useTranslation();
  const [placeAutocomplete, { loading: loadingAutocomplete }] =
    useLazyQuery(PLACE_AUTOCOMPLETE);

  const init = useCallback(() => {
    setCenter(getInitLocation(initLocation));
    setCountry(initLocation?.country ?? "");
    setCity(initLocation?.city ?? "");
    setCounty(initLocation?.county ?? "");
  }, [initLocation]);

  useEffect(() => {
    init();
  }, [initLocation, init]);

  const handleClickLocationService = () => {
    setLoadingLocation(true);
    fetchLocation((data) => {
      logger.log("Received geo", data);
      setCenter({
        lat: data.coords.latitude,
        lng: data.coords.longitude,
      });
      reverseGeocode({
        variables: {
          lat: "" + data.coords.latitude,
          lng: "" + data.coords.longitude,
        },
        onCompleted: (data) => {
          logger.log("onCompleted reverseGeocode", data);
          if (data.reverseGeocode) {
            setCity(data.reverseGeocode.city);
            setCounty(data.reverseGeocode.county);
            setCountry(data.reverseGeocode.country);
          } else {
            // null returned, means geocode failed. So we need to show
            // an error alert
            // TODO
          }
          setLoadingLocation(false);
        },
        onError: (error) => {
          logger.log("onError reverseGeocode", error);
          setLoadingLocation(false);
        },
      });
    });
  };

  const save = () => {
    const location = {
      country: country,
      city: city,
      county: county,
      geo: {
        coordinates: [center.lng, center.lat],
      },
    };

    if (onSave) {
      onSave(location);
      cancel();
    } else {
      // Call the update profile directly.
      const profile = {
        location: location,
      };
      updateProfile({
        variables: {
          profile,
        },
        onCompleted: (data) => {
          cancel();
          showSnackbar("Location updated.", "success");
        },
        onError: (error) => {
          reset();
          showSnackbar(error.message, "error");
        },
        refetchQueries: [
          ME,
          ME_PROFILE,
          SPOTLIGHTS,
          NEW_USERS,
          MATCHING,
          RECENT_LOGINS,
          NEARBY_PEOPLE,
        ],
      });
    }
  };

  const isDirty = () => {
    return (
      city !== initLocation?.city ||
      county !== initLocation.county ||
      country !== initLocation.country
    );
  };

  const cancel = () => {
    reset();
    init();
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <Dialog
        open={open}
        //TransitionComponent={Transition}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Content>
          <CloseButtonContainer>
            <IconButton onClick={() => setOpen(false)}>
              <XMarkIcon width={20} />
            </IconButton>
          </CloseButtonContainer>
          <FlexCol justifyContent="center" rowGap={20}>
            <FlexRow justifyContent="center">
              <Text size={20} weight={600}>
                {t("Where are you now?")}
              </Text>
            </FlexRow>
            <FlexRow justifyContent="center">
              <Text>
                {t(
                  "Update your location to where you currently are so you can find nearby people, and they can find you!"
                )}
              </Text>
            </FlexRow>
            <FlexRow>
              {/* <GooglePlacesSearch
                onSelect={(result) => {
                  setCity(result.city);
                  setCounty(result.county);
                  setCountry(result.country);
                  setCenter(result.geo);
                }}
              /> */}
              <AutoCompleteSearch
                label={t("Select location")}
                name={"select-location"}
                placeholder={
                  t("Where are you now?") + " (" + t("In English only") + ")"
                }
                debounce={800}
                optionStringValue={(item) =>
                  item.city + ", " + item.county + " " + item.country
                }
                loading={loadingAutocomplete}
                multiline={false}
                onChange={(item: PlaceAutocomplete) => {
                  setCity(item.city ?? "");
                  setCounty(item.county ?? "");
                  setCountry(item.country ?? "");

                  let location = defaultLocation;
                  if (
                    item.geo &&
                    item.geo.coordinates &&
                    item.geo.coordinates.length > 1
                  ) {
                    location = {
                      lng: item.geo.coordinates[0] ?? defaultLocation.lng,
                      lat: item.geo.coordinates[1] ?? defaultLocation.lat,
                    };
                  }

                  setCenter(location);
                }}
                fetchData={async (text) => {
                  const result = await placeAutocomplete({
                    variables: {
                      text,
                    },
                  });

                  if (result.data.placeAutocomplete) {
                    // Filter away those that are missing city, county or country
                    const filtered = result.data.placeAutocomplete.filter(
                      (place: PlaceAutocomplete) => {
                        if (
                          !place.city ||
                          place.city.length < 1 ||
                          !place.county ||
                          place.county.length < 1 ||
                          !place.country ||
                          place.country.length < 1
                        ) {
                          return false;
                        }
                        return true;
                      }
                    );
                    return filtered;
                  }
                  if (result.error) {
                    logger.log("placeAutocomplete error", result.error);
                    showSnackbar(result.error.message, "error");
                  }
                  return [];
                }}
              />
            </FlexRow>
            <FlexRow>
              {isLoadedMapsApi && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  // onLoad={onLoad}
                  // onUnmount={onUnmount}
                >
                  {/* Child components, such as markers, info windows, etc. */}

                  {geocodeLoading || loadingLocation ? (
                    <>
                      <FlexCol
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        padding={0}
                      >
                        <FlexRow justifyContent="center">
                          <CircularProgress />
                        </FlexRow>
                      </FlexCol>
                    </>
                  ) : (
                    <FlexCol
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      padding={0}
                    >
                      <FlexRow justifyContent="center">
                        <StdButton
                          variant="contained"
                          onClick={() => handleClickLocationService()}
                        >
                          <MapPinIcon width={18} />
                          <Box sx={{ width: 5, height: 10 }} />
                          <Text>{t("Use Location Service")}</Text>
                        </StdButton>
                      </FlexRow>
                    </FlexCol>
                  )}
                </GoogleMap>
              )}
              {loadError && <ErrorAlert error={loadError} />}
            </FlexRow>

            {error && (
              <FlexCol padding={0}>
                <ErrorAlert error={error} />
                <Alert severity="warning">
                  <Text size={13}>
                    {t(
                      "You must turn on geo location in your browser for the location service to work."
                    )}{" "}
                    <StyledLink to="/help-center/feature-location-service">
                      {t("More information")}
                    </StyledLink>
                    .
                  </Text>
                </Alert>
              </FlexCol>
            )}

            <FlexRow>
              <Text weight={800}>{t("Selected location")}:</Text>
            </FlexRow>
            <FlexCol padding={0}>
              <FlexRow>
                <Text>
                  {city}
                  {county ? ", " + county : ""}
                  {country ? ", " + country : ""}
                </Text>
              </FlexRow>
            </FlexCol>
          </FlexCol>
          <FlexCol>
            <FlexRow justifyContent="flex-end">
              <StdButton onClick={() => cancel()}>
                <Text>{t("Cancel")}</Text>
              </StdButton>
              <StdButton
                disabled={!isDirty() || !city || city.length < 1}
                variant="contained"
                onClick={() => save()}
              >
                <Text>{t("Save")}</Text>
              </StdButton>
            </FlexRow>
          </FlexCol>
        </Content>
      </Dialog>
    </>
  );
};
export default LocationDialog;
