import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  adFree: Scalars['Boolean']['output'];
  availableTranslationAmount: Scalars['Int']['output'];
  blockPurchase: Scalars['Boolean']['output'];
  credits: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  premium: Scalars['Boolean']['output'];
  premiumDate?: Maybe<Scalars['DateTime']['output']>;
  vip: Scalars['Boolean']['output'];
};

export type Achievement = {
  __typename?: 'Achievement';
  achievementId: AchievementId;
  completed?: Maybe<Scalars['Boolean']['output']>;
  rewardClaimedDate?: Maybe<Scalars['DateTime']['output']>;
  rewardCredits: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export enum AchievementId {
  InterestsAdded = 'InterestsAdded',
  Invited10 = 'Invited10',
  LanguagesAdded = 'LanguagesAdded',
  LocationSet = 'LocationSet',
  MatchVideoApproved = 'MatchVideoApproved',
  Matched5 = 'Matched5',
  PersonalInfoCompleted = 'PersonalInfoCompleted',
  PersonalityTraitsAdded = 'PersonalityTraitsAdded',
  Premium30days = 'Premium30days',
  ProfileAppearanceCompleted = 'ProfileAppearanceCompleted',
  ProfilePhotoApproved = 'ProfilePhotoApproved',
  PurchasedCredits = 'PurchasedCredits',
  ReceivedAGift = 'ReceivedAGift',
  SentAGift = 'SentAGift',
  SocialFacebookConnected = 'SocialFacebookConnected',
  SocialInstagramConnected = 'SocialInstagramConnected',
  SocialSpotifyConnected = 'SocialSpotifyConnected',
  SocialTikTokConnected = 'SocialTikTokConnected',
  VerificationPhone = 'VerificationPhone',
  VerificationPhoto = 'VerificationPhoto',
  VoicePromptsUploaded = 'VoicePromptsUploaded'
}

export enum AdNetwork {
  AdMob = 'AdMob',
  Applovin = 'Applovin'
}

export type AdNetworkResult = {
  __typename?: 'AdNetworkResult';
  adNetwork: AdNetwork;
  useAds: Scalars['Boolean']['output'];
};

export type AddMeetInput = {
  location: LocationInput;
  text: Scalars['String']['input'];
  type: MeetType;
  when: MeetWhen;
};

export enum AlcoholHabit {
  HateAlcohol = 'HATE_ALCOHOL',
  No = 'NO',
  Undisclosed = 'UNDISCLOSED',
  YesEveryDay = 'YES_EVERY_DAY',
  YesParties = 'YES_PARTIES',
  YesSometimes = 'YES_SOMETIMES'
}

export type AllowedReelUploadResult = {
  __typename?: 'AllowedReelUploadResult';
  allowed: Scalars['Boolean']['output'];
  needPremium: Scalars['Boolean']['output'];
  reason?: Maybe<Scalars['String']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  blurhash?: Maybe<Scalars['String']['output']>;
  contentType: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  expired: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type AttachmentArgs = {
  contentLength: Scalars['Int']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type BadgesResult = {
  __typename?: 'BadgesResult';
  unreadMessages: Scalars['Int']['output'];
  unreadNotifications: Scalars['Int']['output'];
};

export enum Beard {
  Full = 'FULL',
  Goatee = 'GOATEE',
  Mustache = 'MUSTACHE',
  No = 'NO',
  Short = 'SHORT',
  Undisclosed = 'UNDISCLOSED'
}

export type Blocked = {
  __typename?: 'Blocked';
  created: Scalars['DateTime']['output'];
  user: User;
};

export type BlurredMatch = {
  __typename?: 'BlurredMatch';
  thumbnailBlurhash: Scalars['String']['output'];
};

export type Chat = {
  __typename?: 'Chat';
  _id: Scalars['String']['output'];
  acceptedByIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  allowNew?: Maybe<Scalars['Boolean']['output']>;
  allowNewFrom?: Maybe<Array<Maybe<NewMessageSetting>>>;
  archived: Scalars['Boolean']['output'];
  blocked?: Maybe<Scalars['Boolean']['output']>;
  deletedByIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ended: Scalars['Boolean']['output'];
  endedByUserId?: Maybe<Scalars['String']['output']>;
  endedDate?: Maybe<Scalars['DateTime']['output']>;
  ghostingIds: Array<Scalars['String']['output']>;
  ghostingStatus?: Maybe<GhostingStatus>;
  isSnapFlirt: Scalars['Boolean']['output'];
  lastMessage?: Maybe<Message>;
  lastMessageId?: Maybe<Scalars['String']['output']>;
  members?: Maybe<Array<Maybe<User>>>;
  messages?: Maybe<PagedMessages>;
  typingUserIds: Array<Scalars['String']['output']>;
  unlocked: Scalars['Boolean']['output'];
  updatedDate: Scalars['DateTime']['output'];
  usePushNotification?: Maybe<Scalars['Boolean']['output']>;
  useTranslation?: Maybe<Scalars['Boolean']['output']>;
};


export type ChatMessagesArgs = {
  input: PagingArgs;
};

export type ChatList = {
  __typename?: 'ChatList';
  items?: Maybe<PagedChatList>;
  totalChats: Scalars['Int']['output'];
};


export type ChatListItemsArgs = {
  input: PagingArgs;
  showArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatListItem = {
  __typename?: 'ChatListItem';
  accepted: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  chatId: Scalars['String']['output'];
  ended: Scalars['Boolean']['output'];
  endedByUserId?: Maybe<Scalars['String']['output']>;
  endedDate?: Maybe<Scalars['DateTime']['output']>;
  ghostingIds: Array<Scalars['String']['output']>;
  ghostingStatus?: Maybe<GhostingStatus>;
  lastMessage?: Maybe<Message>;
  members?: Maybe<Array<Maybe<User>>>;
  updatedDate: Scalars['DateTime']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  _id: Scalars['String']['output'];
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  byUserId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  meReaction?: Maybe<ReactionValue>;
  parentId: Scalars['String']['output'];
  reactionCounts: Array<Scalars['Int']['output']>;
  replies?: Maybe<PagedComments>;
  repliesAllowed: Scalars['Boolean']['output'];
  replyCount: Scalars['Int']['output'];
  rootId: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  totalReactions: Scalars['Int']['output'];
  user?: Maybe<User>;
};


export type CommentRepliesArgs = {
  commentType?: InputMaybe<CommentType>;
  input: PagingArgs;
};

export enum CommentType {
  Comment = 'COMMENT',
  Post = 'POST',
  Reel = 'REEL'
}

export type CursorQueryArgs = {
  cursor: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};

export type DeleteArgs = {
  reason: DeleteReason;
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum DeleteReason {
  CostTooMuch = 'CostTooMuch',
  CustomerServicePoor = 'CustomerServicePoor',
  FoundSomeone = 'FoundSomeone',
  NoLongerNeed = 'NoLongerNeed',
  Other = 'Other',
  QualityWasPoor = 'QualityWasPoor',
  TooFewMatches = 'TooFewMatches',
  TooFewPeople = 'TooFewPeople',
  TooManyAds = 'TooManyAds'
}

export type Destination = {
  __typename?: 'Destination';
  _id: Scalars['String']['output'];
  audience?: Maybe<PostAudience>;
  byUser: User;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['Boolean']['output']>;
  fromDate: Scalars['DateTime']['output'];
  location: DestinationLocation;
  push?: Maybe<Scalars['Boolean']['output']>;
  toDate: Scalars['DateTime']['output'];
};

export type DestinationInput = {
  audience?: InputMaybe<PostAudience>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate: Scalars['DateTime']['input'];
  location: DestinationLocationInput;
  push?: InputMaybe<Scalars['Boolean']['input']>;
  toDate: Scalars['DateTime']['input'];
};

export type DestinationLocation = {
  __typename?: 'DestinationLocation';
  _id: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type DestinationLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<GeoInput>;
};

export type EmailSubscriptionSettings = {
  __typename?: 'EmailSubscriptionSettings';
  advertisements: Scalars['Boolean']['output'];
  newsLetter: Scalars['Boolean']['output'];
  systemNotifications: Scalars['Boolean']['output'];
  weeklyMatches: Scalars['Boolean']['output'];
  weeklyNewLikes: Scalars['Boolean']['output'];
  weeklyNewMessages: Scalars['Boolean']['output'];
};

export type EmailSubscriptionSettingsInput = {
  advertisements: Scalars['Boolean']['input'];
  emailUuid: Scalars['String']['input'];
  newsLetter: Scalars['Boolean']['input'];
  systemNotifications: Scalars['Boolean']['input'];
  weeklyMatches: Scalars['Boolean']['input'];
  weeklyNewLikes: Scalars['Boolean']['input'];
  weeklyNewMessages: Scalars['Boolean']['input'];
};

export enum Etnicity {
  AfricanAmerican = 'AFRICAN_AMERICAN',
  AmericanIndian = 'AMERICAN_INDIAN',
  Asian = 'ASIAN',
  Hispanic = 'HISPANIC',
  Undisclosed = 'UNDISCLOSED',
  White = 'WHITE'
}

export enum EyeColor {
  Amber = 'AMBER',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Gray = 'GRAY',
  Green = 'GREEN',
  Hazel = 'HAZEL',
  Mixed = 'MIXED',
  Other = 'OTHER',
  Undisclosed = 'UNDISCLOSED'
}

export type Facebook = {
  __typename?: 'Facebook';
  _id: Scalars['String']['output'];
  email: Scalars['String']['output'];
  facebookId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Favorite = {
  __typename?: 'Favorite';
  created: Scalars['DateTime']['output'];
  user: User;
};

export enum FeatureType {
  FreeSpin = 'FreeSpin',
  Live = 'Live',
  Videocall = 'Videocall'
}

export enum FeedbackType {
  Bug = 'BUG',
  Feature = 'FEATURE',
  Feedback = 'FEEDBACK'
}

export type FileUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type FileUploadResult = {
  __typename?: 'FileUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export type Follower = {
  __typename?: 'Follower';
  created: Scalars['DateTime']['output'];
  user: User;
};

export type FollowersResult = {
  __typename?: 'FollowersResult';
  followers?: Maybe<Array<Maybe<Follower>>>;
  totalFollowers?: Maybe<Scalars['Int']['output']>;
};

export type Following = {
  __typename?: 'Following';
  created: Scalars['DateTime']['output'];
  user: User;
};

export type Friend = {
  __typename?: 'Friend';
  created: Scalars['DateTime']['output'];
  user: User;
};

export type FriendRequest = {
  __typename?: 'FriendRequest';
  created: Scalars['DateTime']['output'];
  user: User;
};

export type FriendsData = {
  __typename?: 'FriendsData';
  friendRequestsReceived?: Maybe<Array<Maybe<FriendRequest>>>;
  friendRequestsSent?: Maybe<Array<Maybe<FriendRequest>>>;
  friends?: Maybe<Array<Maybe<Friend>>>;
};

export enum Gender {
  Man = 'MAN',
  NonBinary = 'NON_BINARY',
  Transgender = 'TRANSGENDER',
  Undisclosed = 'UNDISCLOSED',
  Woman = 'WOMAN'
}

export type Geo = {
  __typename?: 'Geo';
  _id: Scalars['String']['output'];
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeoInput = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type GeocodeResult = {
  __typename?: 'GeocodeResult';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
};

export type GhostingStatus = {
  __typename?: 'GhostingStatus';
  receiver: Scalars['Boolean']['output'];
  you: Scalars['Boolean']['output'];
};

export type Gift = {
  __typename?: 'Gift';
  _id: Scalars['String']['output'];
  byUser?: Maybe<User>;
  byUserId: Scalars['String']['output'];
  claimed: Scalars['Boolean']['output'];
  claimedDate?: Maybe<Scalars['DateTime']['output']>;
  created: Scalars['DateTime']['output'];
  credits: Scalars['Int']['output'];
  message?: Maybe<Scalars['String']['output']>;
  toUser?: Maybe<User>;
  toUserId: Scalars['String']['output'];
};

export type Google = {
  __typename?: 'Google';
  _id: Scalars['String']['output'];
  email: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Group = {
  __typename?: 'Group';
  _id: Scalars['String']['output'];
  about: Scalars['String']['output'];
  admins?: Maybe<PagedUsers>;
  bannedUsers?: Maybe<PagedUsers>;
  canJoin: Scalars['Boolean']['output'];
  canRequest: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  genderLimited: Array<Gender>;
  hasSentRequest: Scalars['Boolean']['output'];
  inviteLimit?: Maybe<Scalars['Int']['output']>;
  inviteLimitDate?: Maybe<Scalars['DateTime']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isAdminInvited: Scalars['Boolean']['output'];
  isBanned: Scalars['Boolean']['output'];
  isInvited: Scalars['Boolean']['output'];
  isMember: Scalars['Boolean']['output'];
  maxAgeLimited: Scalars['Int']['output'];
  memberCommentsAllowed: Scalars['Boolean']['output'];
  memberCount: Scalars['Int']['output'];
  memberInvites?: Maybe<PagedUsers>;
  memberInvitesIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  memberPostsAllowed: Scalars['Boolean']['output'];
  memberRequests?: Maybe<PagedUsers>;
  memberRequestsCount?: Maybe<Scalars['Int']['output']>;
  memberVisibility: MemberVisibility;
  members?: Maybe<PagedUsers>;
  minAgeLimited: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Photo>;
  photoApproved?: Maybe<Scalars['Boolean']['output']>;
  privacy: GroupPrivacy;
  rules: Scalars['String']['output'];
  status?: Maybe<GroupStatus>;
  visibility: GroupVisibility;
};


export type GroupAdminsArgs = {
  paging: PagingArgs;
};


export type GroupBannedUsersArgs = {
  paging: PagingArgs;
};


export type GroupMemberInvitesArgs = {
  paging: PagingArgs;
};


export type GroupMemberRequestsArgs = {
  paging: PagingArgs;
};


export type GroupMembersArgs = {
  paging: PagingArgs;
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type GroupAd = {
  __typename?: 'GroupAd';
  _id: Scalars['String']['output'];
  byUserId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  groupId: Scalars['String']['output'];
  memberCount: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Photo>;
  privacy: GroupPrivacy;
  visibility: GroupVisibility;
};

export enum GroupPrivacy {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum GroupStatus {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Review = 'REVIEW'
}

export enum GroupVisibility {
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}

export enum HairColor {
  Black = 'BLACK',
  Blonde = 'BLONDE',
  Brown = 'BROWN',
  Colored = 'COLORED',
  Gray = 'GRAY',
  Red = 'RED',
  Shaved = 'SHAVED',
  Undisclosed = 'UNDISCLOSED'
}

export type HappyHour = {
  __typename?: 'HappyHour';
  endDate: Scalars['DateTime']['output'];
  isActive: Scalars['Boolean']['output'];
  nextDate: Scalars['DateTime']['output'];
  pollDate: Scalars['DateTime']['output'];
  startDate: Scalars['DateTime']['output'];
};

export enum HereTo {
  Chat = 'CHAT',
  Date = 'DATE',
  Friendship = 'FRIENDSHIP',
  OneNight = 'ONE_NIGHT',
  Relationship = 'RELATIONSHIP'
}

export type Instagram = {
  __typename?: 'Instagram';
  _id: Scalars['String']['output'];
  instagramUserId?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<InstagramMedia>>;
  updatedDate: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type InstagramMedia = {
  __typename?: 'InstagramMedia';
  id: Scalars['String']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  type: InstagramMediaType;
  url: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export enum InstagramMediaType {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type Integrations = {
  __typename?: 'Integrations';
  facebook?: Maybe<Facebook>;
  google?: Maybe<Google>;
  instagram?: Maybe<Instagram>;
  spotify?: Maybe<Spotify>;
  tiktok?: Maybe<Tiktok>;
};

export enum Interest {
  Beaches = 'BEACHES',
  Business = 'BUSINESS',
  Cars = 'CARS',
  Cats = 'CATS',
  Chill = 'CHILL',
  Clothes = 'CLOTHES',
  Coffee = 'COFFEE',
  Comedies = 'COMEDIES',
  Cooking = 'COOKING',
  CultureAndBooks = 'CULTURE_AND_BOOKS',
  Diving = 'DIVING',
  Dogs = 'DOGS',
  Driving = 'DRIVING',
  FashionAndBeauty = 'FASHION_AND_BEAUTY',
  FoodAndDrink = 'FOOD_AND_DRINK',
  Football = 'FOOTBALL',
  Friendship = 'FRIENDSHIP',
  Gaming = 'GAMING',
  Gym = 'GYM',
  Hobbies = 'HOBBIES',
  Horses = 'HORSES',
  IceCream = 'ICE_CREAM',
  Laughing = 'LAUGHING',
  Love = 'LOVE',
  MeetPeople = 'MEET_PEOPLE',
  MotorBikes = 'MOTOR_BIKES',
  MoviesAndSeries = 'MOVIES_AND_SERIES',
  Music = 'MUSIC',
  Painting = 'PAINTING',
  Party = 'PARTY',
  RoadTrips = 'ROAD_TRIPS',
  RockMusic = 'ROCK_MUSIC',
  Running = 'RUNNING',
  Sex = 'SEX',
  Shoes = 'SHOES',
  Skateboard = 'SKATEBOARD',
  Skiing = 'SKIING',
  Snowboard = 'SNOWBOARD',
  Sport = 'SPORT',
  StockMarket = 'STOCK_MARKET',
  Summer = 'SUMMER',
  Surfing = 'SURFING',
  Tea = 'TEA',
  Travel = 'TRAVEL',
  Walking = 'WALKING',
  WeekendTrips = 'WEEKEND_TRIPS',
  Winter = 'WINTER',
  WorkOut = 'WORK_OUT'
}

export type IsMatch = {
  __typename?: 'IsMatch';
  hasVoted: Scalars['Boolean']['output'];
  match: Scalars['Boolean']['output'];
  meVote?: Maybe<Scalars['Boolean']['output']>;
  numberOfVotes: Scalars['Int']['output'];
  userHasVoted: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
  userVote?: Maybe<Scalars['Boolean']['output']>;
};

export enum KidsStatus {
  NoMaybeLater = 'NO_MAYBE_LATER',
  NoNever = 'NO_NEVER',
  Undisclosed = 'UNDISCLOSED',
  YesAdults = 'YES_ADULTS',
  YesFulltime = 'YES_FULLTIME',
  YesParttime = 'YES_PARTTIME',
  YesWantMore = 'YES_WANT_MORE'
}

export enum Living {
  Apartment = 'APARTMENT',
  Hotel = 'HOTEL',
  House = 'HOUSE',
  Undisclosed = 'UNDISCLOSED',
  WithParents = 'WITH_PARENTS'
}

export enum Language {
  Arabic = 'ARABIC',
  Bulgarian = 'BULGARIAN',
  Catalan = 'CATALAN',
  Chinese = 'CHINESE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Estonian = 'ESTONIAN',
  Filipino = 'FILIPINO',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Greek = 'GREEK',
  Hindi = 'HINDI',
  Hungarian = 'HUNGARIAN',
  Indonesian = 'INDONESIAN',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Khmer = 'KHMER',
  Korean = 'KOREAN',
  Latvian = 'LATVIAN',
  Lithuanian = 'LITHUANIAN',
  Malay = 'MALAY',
  Norwegian = 'NORWEGIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Slovak = 'SLOVAK',
  Spanish = 'SPANISH',
  Swedish = 'SWEDISH',
  Thai = 'THAI',
  Turkish = 'TURKISH',
  Ukranian = 'UKRANIAN',
  Vietnamese = 'VIETNAMESE'
}

export type LastViewedMessagesForChat = {
  __typename?: 'LastViewedMessagesForChat';
  lastViewedDate: Scalars['DateTime']['output'];
  messageId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type LikeStreakInfo = {
  __typename?: 'LikeStreakInfo';
  likeStreakCount: Scalars['Int']['output'];
  likeStreakDays: Scalars['Int']['output'];
  likeStreakDaysMax: Scalars['Int']['output'];
};

export type LikesMe = {
  __typename?: 'LikesMe';
  date: Scalars['DateTime']['output'];
  hot: Scalars['Boolean']['output'];
  vote: Scalars['Boolean']['output'];
};

export type ListUserResult = {
  __typename?: 'ListUserResult';
  maxDistanceInMeters: Scalars['Int']['output'];
  nodes: Array<User>;
  paging: PagingResult;
};

export type LiveComment = {
  __typename?: 'LiveComment';
  _id: Scalars['String']['output'];
  comment: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  gift?: Maybe<LiveGift>;
  status?: Maybe<LiveStatusUpdate>;
  user: User;
};

export enum LiveGift {
  Diamond = 'Diamond',
  Heart = 'Heart',
  Kiss = 'Kiss',
  Rose = 'Rose'
}

export enum LiveStatusUpdate {
  Joined = 'Joined',
  Left = 'Left'
}

export type LiveStreamData = {
  __typename?: 'LiveStreamData';
  comments: Array<LiveComment>;
  currentViewers: Scalars['Int']['output'];
  isBanned: Scalars['Boolean']['output'];
  isSending: Scalars['Boolean']['output'];
  lastActiveVideoDate: Scalars['DateTime']['output'];
  lastPollDate: Scalars['DateTime']['output'];
  videoActive: Scalars['Boolean']['output'];
};

export type Location = {
  __typename?: 'Location';
  _id: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<GeoInput>;
};

export type MatchResult = {
  __typename?: 'MatchResult';
  boostOfferExpireDate?: Maybe<Scalars['DateTime']['output']>;
  hotLikes: Scalars['Int']['output'];
  hotLikesDate?: Maybe<Scalars['DateTime']['output']>;
  isMatch: Scalars['Boolean']['output'];
  streak: LikeStreakInfo;
  user?: Maybe<User>;
  yesVotesLeft: Scalars['Int']['output'];
};

export type MatchingArgs = {
  amount: Scalars['Int']['input'];
  skipUserIds: Array<Scalars['String']['input']>;
};

export type MatchingResult = {
  __typename?: 'MatchingResult';
  boostOfferExpireDate?: Maybe<Scalars['DateTime']['output']>;
  hotLikes: Scalars['Int']['output'];
  hotLikesDate?: Maybe<Scalars['DateTime']['output']>;
  streak: LikeStreakInfo;
  users: Array<User>;
  yesVotesLeft: Scalars['Int']['output'];
};

export type Me = {
  __typename?: 'Me';
  _id: Scalars['String']['output'];
  account?: Maybe<Account>;
  admin?: Maybe<Scalars['Boolean']['output']>;
  boostExpiresDate?: Maybe<Scalars['DateTime']['output']>;
  boostOfferExpireDate?: Maybe<Scalars['DateTime']['output']>;
  boosting?: Maybe<Scalars['Boolean']['output']>;
  chatIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  created: Scalars['DateTime']['output'];
  deactivated: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  facebook?: Maybe<Facebook>;
  freeSpinDate?: Maybe<Scalars['DateTime']['output']>;
  google?: Maybe<Google>;
  hideGroupIds?: Maybe<Array<Scalars['String']['output']>>;
  hotLikes: Scalars['Int']['output'];
  hotLikesDate?: Maybe<Scalars['DateTime']['output']>;
  instagram?: Maybe<Instagram>;
  invitedMemberCount: Scalars['Int']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  likeStreak?: Maybe<LikeStreakInfo>;
  locationUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  matchScore: Scalars['Int']['output'];
  moderator?: Maybe<Scalars['Boolean']['output']>;
  mood?: Maybe<Scalars['String']['output']>;
  moodDate?: Maybe<Scalars['DateTime']['output']>;
  numberOfVotes: Scalars['Int']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  popularity?: Maybe<Scalars['Int']['output']>;
  privatePhotosAccessIds?: Maybe<Array<Scalars['String']['output']>>;
  profilePhoto?: Maybe<Photo>;
  profilePhotoApproved?: Maybe<Scalars['Boolean']['output']>;
  profilePhotoUploadId?: Maybe<Scalars['String']['output']>;
  profileVisits?: Maybe<Array<ProfileVisit>>;
  pushAvailable?: Maybe<Scalars['Boolean']['output']>;
  readyToChatDate?: Maybe<Scalars['DateTime']['output']>;
  reelStreakDate?: Maybe<Scalars['DateTime']['output']>;
  reelStreakDays: Scalars['Int']['output'];
  rewardedAdsAvailable: Scalars['Boolean']['output'];
  roles?: Maybe<Array<Scalars['String']['output']>>;
  showOnlineStatus?: Maybe<Scalars['Boolean']['output']>;
  snapFlirtStreakDate?: Maybe<Scalars['DateTime']['output']>;
  snapFlirtStreakDays: Scalars['Int']['output'];
  snapFlirts: Scalars['Int']['output'];
  snapFlirtsDate?: Maybe<Scalars['DateTime']['output']>;
  spotify?: Maybe<Spotify>;
  spotlightOfferExpireDate?: Maybe<Scalars['DateTime']['output']>;
  tiktok?: Maybe<Tiktok>;
  totalReels?: Maybe<Scalars['Int']['output']>;
  username: Scalars['String']['output'];
  verifiedPhone: Scalars['Boolean']['output'];
  votesDate?: Maybe<Scalars['DateTime']['output']>;
};


export type MeChatIdsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type MePush = {
  __typename?: 'MePush';
  deviceToken?: Maybe<Scalars['String']['output']>;
  expoPushToken?: Maybe<Scalars['String']['output']>;
};

export type MeSettings = {
  __typename?: 'MeSettings';
  freeSpinDate?: Maybe<Scalars['DateTime']['output']>;
  settings: Settings;
};

export type Meet = {
  __typename?: 'Meet';
  _id: Scalars['String']['output'];
  interestedCount: Scalars['Int']['output'];
  isInterested: Scalars['Boolean']['output'];
  location: Location;
  text: Scalars['String']['output'];
  type: MeetType;
  user: User;
  when: MeetWhen;
};

export enum MeetType {
  Coffee = 'Coffee',
  Date = 'Date',
  Dinner = 'Dinner',
  Hangout = 'Hangout',
  Hookup = 'Hookup',
  OneNightStand = 'OneNightStand',
  Party = 'Party',
  Sport = 'Sport'
}

export enum MeetWhen {
  NextWeek = 'NextWeek',
  ThisWeek = 'ThisWeek',
  ThisWeekend = 'ThisWeekend',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Within30Days = 'Within30Days'
}

export enum MemberVisibility {
  Hidden = 'HIDDEN',
  Visible = 'VISIBLE'
}

export type Message = {
  __typename?: 'Message';
  _id: Scalars['String']['output'];
  attachments?: Maybe<Array<Maybe<MessageAttachment>>>;
  chatId: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deletedByIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fromId: Scalars['String']['output'];
  reactions?: Maybe<Array<Reaction>>;
  snapFlirt?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  translated?: Maybe<TranslatedText>;
  videoCallId?: Maybe<Scalars['String']['output']>;
  videoCallRequest?: Maybe<Scalars['Boolean']['output']>;
  viewedByIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  blurhash?: Maybe<Scalars['String']['output']>;
  contentType: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  expired: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type MessageAttachmentArgs = {
  contentLength: Scalars['Int']['input'];
  contentType: Scalars['String']['input'];
  expireType: MessageAttachmentExpireType;
  fileName: Scalars['String']['input'];
};

export enum MessageAttachmentExpireType {
  OneDay = 'OneDay',
  OneHour = 'OneHour',
  OneMonth = 'OneMonth'
}

export type MessagesSettings = {
  __typename?: 'MessagesSettings';
  allowNewFrom?: Maybe<Array<NewMessageSetting>>;
};

export type MessagesSettingsInput = {
  allowNewFrom?: InputMaybe<Array<NewMessageSetting>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']['output']>;
  acceptFriendRequest?: Maybe<Scalars['String']['output']>;
  acceptGroupInvite?: Maybe<Scalars['String']['output']>;
  acceptGroupRequest?: Maybe<Scalars['String']['output']>;
  addBlock?: Maybe<Scalars['String']['output']>;
  addDestination?: Maybe<Scalars['String']['output']>;
  addFavorite?: Maybe<Scalars['String']['output']>;
  addFollow?: Maybe<Scalars['String']['output']>;
  addGroupAd?: Maybe<Scalars['String']['output']>;
  addLiveComment: Scalars['String']['output'];
  addMeet?: Maybe<Scalars['String']['output']>;
  addMessageReaction?: Maybe<Scalars['String']['output']>;
  addPhoneNumber?: Maybe<Scalars['String']['output']>;
  addPrivatePhotoAccess?: Maybe<Scalars['String']['output']>;
  addReelComment?: Maybe<NewCommentResult>;
  addSavedReel: Scalars['String']['output'];
  addViewingChat?: Maybe<Scalars['String']['output']>;
  archiveAllChats?: Maybe<Scalars['String']['output']>;
  archiveChat?: Maybe<Scalars['String']['output']>;
  banUser?: Maybe<Scalars['String']['output']>;
  claimAchievement: Scalars['String']['output'];
  claimGift?: Maybe<Scalars['Int']['output']>;
  clearAllNoVotes?: Maybe<Scalars['String']['output']>;
  connectFacebook?: Maybe<Scalars['String']['output']>;
  connectGoogle?: Maybe<Scalars['String']['output']>;
  connectInstagram?: Maybe<Scalars['String']['output']>;
  connectSpotify?: Maybe<Scalars['String']['output']>;
  connectTiktok?: Maybe<Scalars['String']['output']>;
  deactivate?: Maybe<Scalars['String']['output']>;
  deleteChat?: Maybe<Scalars['String']['output']>;
  deleteComment?: Maybe<Scalars['String']['output']>;
  deleteGroup?: Maybe<Scalars['String']['output']>;
  deleteMatchVideo?: Maybe<Scalars['String']['output']>;
  deleteMeet?: Maybe<Scalars['String']['output']>;
  deleteMessage?: Maybe<Scalars['String']['output']>;
  deletePhoto?: Maybe<Scalars['String']['output']>;
  deletePost?: Maybe<Scalars['String']['output']>;
  deletePrivatePhoto?: Maybe<Scalars['String']['output']>;
  deletePrivateVideo?: Maybe<Scalars['String']['output']>;
  deleteReel: Scalars['String']['output'];
  deleteReelComment?: Maybe<Scalars['String']['output']>;
  deleteVideo?: Maybe<Scalars['String']['output']>;
  deleteVoicePrompt?: Maybe<Scalars['String']['output']>;
  denyFriendRequest?: Maybe<Scalars['String']['output']>;
  disconnectFacebook?: Maybe<Scalars['String']['output']>;
  disconnectGoogle?: Maybe<Scalars['String']['output']>;
  disconnectInstagram?: Maybe<Scalars['String']['output']>;
  disconnectSpotify?: Maybe<Scalars['String']['output']>;
  disconnectTiktok?: Maybe<Scalars['String']['output']>;
  editDestination?: Maybe<Scalars['String']['output']>;
  endChat?: Maybe<Scalars['String']['output']>;
  hideGroup?: Maybe<Scalars['String']['output']>;
  hideInstagramMedia?: Maybe<Scalars['String']['output']>;
  interestedMeet?: Maybe<Scalars['String']['output']>;
  inviteMember?: Maybe<Scalars['String']['output']>;
  joinGroup?: Maybe<Scalars['String']['output']>;
  kickUser?: Maybe<Scalars['String']['output']>;
  leaveGroup?: Maybe<Scalars['String']['output']>;
  match?: Maybe<MatchResult>;
  newComment?: Maybe<NewCommentResult>;
  newGroup: NewGroupResult;
  newPost?: Maybe<NewPostResult>;
  openChat?: Maybe<Scalars['String']['output']>;
  permanentlyDelete?: Maybe<Scalars['String']['output']>;
  reactToComment?: Maybe<Scalars['String']['output']>;
  reactToPost?: Maybe<Scalars['String']['output']>;
  reactToReel: Scalars['String']['output'];
  readAllNotifications?: Maybe<Scalars['String']['output']>;
  readNotification?: Maybe<Scalars['String']['output']>;
  readyToChat?: Maybe<Scalars['String']['output']>;
  refreshInstagramMedia?: Maybe<Scalars['String']['output']>;
  refreshSpotify?: Maybe<Scalars['String']['output']>;
  refreshTiktokMedia?: Maybe<Scalars['String']['output']>;
  rejectGroupInvite?: Maybe<Scalars['String']['output']>;
  rejectGroupRequest?: Maybe<Scalars['String']['output']>;
  removeBlock?: Maybe<Scalars['String']['output']>;
  removeDestination?: Maybe<Scalars['String']['output']>;
  removeFavorite?: Maybe<Scalars['String']['output']>;
  removeFollow?: Maybe<Scalars['String']['output']>;
  removeFriend?: Maybe<Scalars['String']['output']>;
  removeFriendRequest?: Maybe<Scalars['String']['output']>;
  removeInvitation?: Maybe<Scalars['String']['output']>;
  removeMessageReaction?: Maybe<Scalars['String']['output']>;
  removePrivatePhotoAccess?: Maybe<Scalars['String']['output']>;
  removeSavedReel: Scalars['String']['output'];
  removeViewingChat?: Maybe<Scalars['String']['output']>;
  reportGhosting?: Maybe<Scalars['String']['output']>;
  reportLiveStream: Scalars['String']['output'];
  requestMembership?: Maybe<Scalars['String']['output']>;
  sendFeedback: Scalars['String']['output'];
  sendFriendRequest?: Maybe<Scalars['String']['output']>;
  sendGift?: Maybe<Scalars['String']['output']>;
  sendLiveGift: Scalars['String']['output'];
  sendMessageToChat?: Maybe<SendMessageResult>;
  sendMessageToUser?: Maybe<SendMessageResult>;
  sendReport?: Maybe<Scalars['String']['output']>;
  sendSnapFlirt?: Maybe<Scalars['String']['output']>;
  setGenderToMan: Scalars['String']['output'];
  setLiveBan: Scalars['String']['output'];
  setMood?: Maybe<Scalars['String']['output']>;
  setProfileImage?: Maybe<Scalars['String']['output']>;
  setPushNotification?: Maybe<Scalars['String']['output']>;
  setReelPushNotifications: Scalars['String']['output'];
  setTranslation?: Maybe<Scalars['String']['output']>;
  showGroup?: Maybe<Scalars['String']['output']>;
  submitSurvey?: Maybe<Scalars['String']['output']>;
  translatePost?: Maybe<Scalars['String']['output']>;
  typingInChat?: Maybe<Scalars['String']['output']>;
  unbanUser?: Maybe<Scalars['String']['output']>;
  updateEmailSubscriptionSettings: Scalars['String']['output'];
  updateFilter?: Maybe<Scalars['String']['output']>;
  updateGroup?: Maybe<Scalars['String']['output']>;
  updateProfile?: Maybe<Scalars['String']['output']>;
  updatePushTokens?: Maybe<Scalars['String']['output']>;
  updateSettings?: Maybe<Scalars['String']['output']>;
  uploadGroupImage: FileUploadResult;
  uploadMatchVideo?: Maybe<VideoUploadResult>;
  uploadPhoto?: Maybe<PhotoUploadResult>;
  uploadPhotoVerification?: Maybe<PhotoUploadResult>;
  uploadPrivatePhoto?: Maybe<PhotoUploadResult>;
  uploadPrivateVideo?: Maybe<VideoUploadResult>;
  uploadReel: ReelUploadResult;
  uploadVideo?: Maybe<VideoUploadResult>;
  uploadVideoThumbnail?: Maybe<PhotoUploadResult>;
  uploadVoicePrompt?: Maybe<VoicePromptUploadResult>;
  verifyPhoneNumber?: Maybe<Scalars['String']['output']>;
  viewAllNotifications?: Maybe<Scalars['String']['output']>;
  viewChat?: Maybe<Scalars['String']['output']>;
  viewMessage?: Maybe<Scalars['String']['output']>;
  viewNotification?: Maybe<Scalars['String']['output']>;
  viewReel: Scalars['String']['output'];
  visitProfile?: Maybe<Scalars['String']['output']>;
};


export type MutationAcceptFriendRequestArgs = {
  friendId: Scalars['String']['input'];
};


export type MutationAcceptGroupInviteArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationAcceptGroupRequestArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddBlockArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAddDestinationArgs = {
  input: DestinationInput;
};


export type MutationAddFavoriteArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAddFollowArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAddGroupAdArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationAddLiveCommentArgs = {
  comment: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationAddMeetArgs = {
  input: AddMeetInput;
};


export type MutationAddMessageReactionArgs = {
  messageId: Scalars['String']['input'];
  reaction: Scalars['String']['input'];
};


export type MutationAddPhoneNumberArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationAddPrivatePhotoAccessArgs = {
  userId: Scalars['String']['input'];
};


export type MutationAddReelCommentArgs = {
  args: NewCommentArgs;
};


export type MutationAddSavedReelArgs = {
  reelId: Scalars['String']['input'];
};


export type MutationAddViewingChatArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationArchiveChatArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationBanUserArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationClaimAchievementArgs = {
  achievementId: AchievementId;
};


export type MutationClaimGiftArgs = {
  giftId: Scalars['String']['input'];
};


export type MutationConnectFacebookArgs = {
  token: Scalars['String']['input'];
};


export type MutationConnectGoogleArgs = {
  token: Scalars['String']['input'];
};


export type MutationConnectInstagramArgs = {
  code: Scalars['String']['input'];
};


export type MutationConnectSpotifyArgs = {
  code: Scalars['String']['input'];
};


export type MutationConnectTiktokArgs = {
  code: Scalars['String']['input'];
};


export type MutationDeactivateArgs = {
  value: Scalars['Boolean']['input'];
};


export type MutationDeleteChatArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String']['input'];
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationDeleteMeetArgs = {
  meetId: Scalars['String']['input'];
};


export type MutationDeleteMessageArgs = {
  messageId: Scalars['String']['input'];
};


export type MutationDeletePhotoArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationDeletePostArgs = {
  postId: Scalars['String']['input'];
};


export type MutationDeletePrivatePhotoArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationDeletePrivateVideoArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationDeleteReelArgs = {
  reelId: Scalars['String']['input'];
};


export type MutationDeleteReelCommentArgs = {
  commentId: Scalars['String']['input'];
};


export type MutationDeleteVideoArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationDeleteVoicePromptArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationDenyFriendRequestArgs = {
  friendId: Scalars['String']['input'];
};


export type MutationEditDestinationArgs = {
  destinationId: Scalars['String']['input'];
  input: DestinationInput;
};


export type MutationEndChatArgs = {
  chatId: Scalars['String']['input'];
  message: Scalars['String']['input'];
};


export type MutationHideGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationHideInstagramMediaArgs = {
  instagramMediaId: Scalars['String']['input'];
  visible: Scalars['Boolean']['input'];
};


export type MutationInterestedMeetArgs = {
  meetId: Scalars['String']['input'];
};


export type MutationInviteMemberArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationJoinGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationKickUserArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationLeaveGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationMatchArgs = {
  hotLike?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationNewCommentArgs = {
  args: NewCommentArgs;
};


export type MutationNewGroupArgs = {
  args: NewGroupArgs;
};


export type MutationNewPostArgs = {
  args: NewPostArgs;
};


export type MutationOpenChatArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationPermanentlyDeleteArgs = {
  args?: InputMaybe<DeleteArgs>;
};


export type MutationReactToCommentArgs = {
  commentId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationReactToPostArgs = {
  postId: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationReactToReelArgs = {
  like: Scalars['Boolean']['input'];
  reelId: Scalars['String']['input'];
};


export type MutationReadNotificationArgs = {
  notificationId: Scalars['String']['input'];
};


export type MutationRejectGroupInviteArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationRejectGroupRequestArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveBlockArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRemoveDestinationArgs = {
  destinationId: Scalars['String']['input'];
};


export type MutationRemoveFavoriteArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRemoveFollowArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRemoveFriendArgs = {
  friendId: Scalars['String']['input'];
};


export type MutationRemoveFriendRequestArgs = {
  friendId: Scalars['String']['input'];
};


export type MutationRemoveInvitationArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationRemoveMessageReactionArgs = {
  messageId: Scalars['String']['input'];
};


export type MutationRemovePrivatePhotoAccessArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRemoveSavedReelArgs = {
  reelId: Scalars['String']['input'];
};


export type MutationRemoveViewingChatArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationReportGhostingArgs = {
  chatId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationReportLiveStreamArgs = {
  userId: Scalars['String']['input'];
};


export type MutationRequestMembershipArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationSendFeedbackArgs = {
  appPlatform?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  type: FeedbackType;
};


export type MutationSendFriendRequestArgs = {
  friendId: Scalars['String']['input'];
};


export type MutationSendGiftArgs = {
  credits: Scalars['Int']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  toUserId: Scalars['String']['input'];
};


export type MutationSendLiveGiftArgs = {
  gift: LiveGift;
  userId: Scalars['String']['input'];
};


export type MutationSendMessageToChatArgs = {
  args: SendMessageToChatArgs;
};


export type MutationSendMessageToUserArgs = {
  args: SendMessageToUserArgs;
};


export type MutationSendReportArgs = {
  args: ReportArgs;
};


export type MutationSendSnapFlirtArgs = {
  message: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationSetGenderToManArgs = {
  userId: Scalars['String']['input'];
};


export type MutationSetLiveBanArgs = {
  userId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationSetMoodArgs = {
  emojiString: Scalars['String']['input'];
};


export type MutationSetProfileImageArgs = {
  uploadId: Scalars['String']['input'];
};


export type MutationSetPushNotificationArgs = {
  chatId: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationSetReelPushNotificationsArgs = {
  reelId: Scalars['String']['input'];
  value: Scalars['Boolean']['input'];
};


export type MutationSetTranslationArgs = {
  chatId: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationShowGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationSubmitSurveyArgs = {
  args: SubmitSurveyArgs;
  timeMs?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationTranslatePostArgs = {
  postId: Scalars['String']['input'];
};


export type MutationTypingInChatArgs = {
  chatId: Scalars['String']['input'];
  status: Scalars['Boolean']['input'];
};


export type MutationUnbanUserArgs = {
  groupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationUpdateEmailSubscriptionSettingsArgs = {
  input: EmailSubscriptionSettingsInput;
};


export type MutationUpdateFilterArgs = {
  input: PeopleFilterInput;
};


export type MutationUpdateGroupArgs = {
  args: UpdateGroupArgs;
  groupId: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  profile: ProfileInput;
};


export type MutationUpdatePushTokensArgs = {
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  expoPushToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSettingsArgs = {
  settings: SettingsInput;
};


export type MutationUploadGroupImageArgs = {
  args: FileUploadArgs;
  groupId: Scalars['String']['input'];
};


export type MutationUploadMatchVideoArgs = {
  args: VideoUploadArgs;
};


export type MutationUploadPhotoArgs = {
  args: PhotoUploadArgs;
};


export type MutationUploadPhotoVerificationArgs = {
  args: PhotoUploadArgs;
};


export type MutationUploadPrivatePhotoArgs = {
  args: PhotoUploadArgs;
};


export type MutationUploadPrivateVideoArgs = {
  args: VideoUploadArgs;
};


export type MutationUploadReelArgs = {
  args: ReelUploadArgs;
};


export type MutationUploadVideoArgs = {
  args: VideoUploadArgs;
};


export type MutationUploadVideoThumbnailArgs = {
  args: VideoThumbnailUploadArgs;
};


export type MutationUploadVoicePromptArgs = {
  args: VoicePromptUploadArgs;
};


export type MutationVerifyPhoneNumberArgs = {
  code: Scalars['String']['input'];
};


export type MutationViewChatArgs = {
  chatId: Scalars['String']['input'];
};


export type MutationViewMessageArgs = {
  messageId: Scalars['String']['input'];
};


export type MutationViewNotificationArgs = {
  notificationId: Scalars['String']['input'];
};


export type MutationViewReelArgs = {
  reelId: Scalars['String']['input'];
};


export type MutationVisitProfileArgs = {
  userId: Scalars['String']['input'];
};

export type NearbyPeopleResult = {
  __typename?: 'NearbyPeopleResult';
  maxDistanceInMeters: Scalars['Int']['output'];
  nodes: Array<User>;
  paging: PagingResult;
};

export type NewCommentArgs = {
  attachments?: InputMaybe<Array<AttachmentArgs>>;
  parentId: Scalars['String']['input'];
  rootId: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  type: CommentType;
};

export type NewCommentResult = {
  __typename?: 'NewCommentResult';
  attachments?: Maybe<Array<Maybe<UploadPostInfo>>>;
  newCommentId: Scalars['String']['output'];
};

export type NewGroupArgs = {
  genderLimited: Array<Gender>;
  maxAgeLimited: Scalars['Int']['input'];
  minAgeLimited: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  privacy: GroupPrivacy;
  visibility: GroupVisibility;
};

export type NewGroupResult = {
  __typename?: 'NewGroupResult';
  groupId: Scalars['String']['output'];
};

export type NewMessage = {
  __typename?: 'NewMessage';
  _id: Scalars['String']['output'];
  chatId: Scalars['String']['output'];
  clientId: Scalars['String']['output'];
  fromId: Scalars['String']['output'];
};

export enum NewMessageSetting {
  Filter = 'FILTER',
  Followers = 'FOLLOWERS',
  Friends = 'FRIENDS',
  Matches = 'MATCHES',
  Public = 'PUBLIC'
}

export type NewMessages = {
  __typename?: 'NewMessages';
  nodes: Array<NewMessage>;
};

export type NewMessagesForChat = {
  __typename?: 'NewMessagesForChat';
  availableTranslationAmount: Scalars['Int']['output'];
  lastViewed: Array<LastViewedMessagesForChat>;
  nodes: Array<Message>;
  typingUserIds: Array<Scalars['String']['output']>;
};

export type NewPostArgs = {
  attachments?: InputMaybe<Array<AttachmentArgs>>;
  audience: PostAudience;
  groupId?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type NewPostResult = {
  __typename?: 'NewPostResult';
  attachments?: Maybe<Array<Maybe<UploadPostInfo>>>;
  newPostId: Scalars['String']['output'];
};

export type NewUsersResult = {
  __typename?: 'NewUsersResult';
  users: Array<User>;
};

export type News = {
  __typename?: 'News';
  _id: Scalars['String']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NotifMessageReactionData = {
  __typename?: 'NotifMessageReactionData';
  messageId: Scalars['String']['output'];
  reaction: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  _id: Scalars['String']['output'];
  actionUrl?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
  messageReaction?: Maybe<NotifMessageReactionData>;
  read: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  type: NotificationType;
  user?: Maybe<User>;
  viewed: Scalars['Boolean']['output'];
};

export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  email: Scalars['Boolean']['output'];
  push: Scalars['Boolean']['output'];
  sms: Scalars['Boolean']['output'];
};

export type NotificationSettingInput = {
  email?: InputMaybe<Scalars['Boolean']['input']>;
  push?: InputMaybe<Scalars['Boolean']['input']>;
  sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  advertisements: NotificationSetting;
  comments: NotificationSetting;
  dailySpinReminder: NotificationSetting;
  friendRequests: NotificationSetting;
  invites: NotificationSetting;
  matches: NotificationSetting;
  newMessages: NotificationSetting;
  newPosts: NotificationSetting;
  replies: NotificationSetting;
  signupNotifications: NotificationSetting;
  updatesFollowing: NotificationSetting;
  updatesFriends: NotificationSetting;
  updatesGroups: NotificationSetting;
  updatesMatches: NotificationSetting;
  updatesOnline: NotificationSetting;
};

export type NotificationSettingsInput = {
  advertisements?: InputMaybe<NotificationSettingInput>;
  comments?: InputMaybe<NotificationSettingInput>;
  dailySpinReminder?: InputMaybe<NotificationSettingInput>;
  friendRequests?: InputMaybe<NotificationSettingInput>;
  invites?: InputMaybe<NotificationSettingInput>;
  matches?: InputMaybe<NotificationSettingInput>;
  newMessages?: InputMaybe<NotificationSettingInput>;
  newPosts?: InputMaybe<NotificationSettingInput>;
  replies?: InputMaybe<NotificationSettingInput>;
  signupNotifications?: InputMaybe<NotificationSettingInput>;
  updatesFollowing?: InputMaybe<NotificationSettingInput>;
  updatesFriends?: InputMaybe<NotificationSettingInput>;
  updatesGroups?: InputMaybe<NotificationSettingInput>;
  updatesMatches?: InputMaybe<NotificationSettingInput>;
  updatesOnline?: InputMaybe<NotificationSettingInput>;
};

export enum NotificationType {
  Gift = 'GIFT',
  Match = 'MATCH',
  Message = 'MESSAGE',
  MessageReaction = 'MESSAGE_REACTION',
  PrivatePhotosAccess = 'PRIVATE_PHOTOS_ACCESS',
  Standard = 'STANDARD'
}

export type NotificationsResult = {
  __typename?: 'NotificationsResult';
  nodes: Array<Notification>;
  totalUnviewed: Scalars['Int']['output'];
};

export type OffsetQueryArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PageQueryArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type PagedChatList = {
  __typename?: 'PagedChatList';
  nodes: Array<ChatListItem>;
  paging: PagingResult;
};

export type PagedComments = {
  __typename?: 'PagedComments';
  nodes: Array<Comment>;
  paging: PagingResult;
};

export type PagedDestinations = {
  __typename?: 'PagedDestinations';
  nodes: Array<Destination>;
  paging: PagingResult;
};

export type PagedGroups = {
  __typename?: 'PagedGroups';
  nodes: Array<Group>;
  paging: PagingResult;
};

export type PagedLiveStreamComments = {
  __typename?: 'PagedLiveStreamComments';
  nodes: Array<LiveComment>;
  paging: PagingResult;
};

export type PagedMatches = {
  __typename?: 'PagedMatches';
  nodes: Array<User>;
  paging: PagingResult;
};

export type PagedMeets = {
  __typename?: 'PagedMeets';
  nodes: Array<Meet>;
  paging: PagingResult;
};

export type PagedMessages = {
  __typename?: 'PagedMessages';
  nodes: Array<Message>;
  paging: PagingResult;
};

export type PagedPosts = {
  __typename?: 'PagedPosts';
  nodes: Array<Post>;
  paging?: Maybe<PagingResult>;
};

export type PagedReactions = {
  __typename?: 'PagedReactions';
  nodes: Array<Reaction>;
  paging: PagingResult;
};

export type PagedReels = {
  __typename?: 'PagedReels';
  nodes: Array<Reel>;
  paging: PagingResult;
};

export type PagedSurveys = {
  __typename?: 'PagedSurveys';
  nodes: Array<SurveyListItem>;
  paging: PagingResult;
};

export type PagedUsers = {
  __typename?: 'PagedUsers';
  nodes: Array<User>;
  paging: PagingResult;
};

export type PagedYesMatches = {
  __typename?: 'PagedYesMatches';
  nodes: Array<User>;
  paging: PagingResult;
};

export type PagedYesMatchesBlurred = {
  __typename?: 'PagedYesMatchesBlurred';
  nodes: Array<BlurredMatch>;
  paging: PagingResult;
};

export type PagingArgs = {
  cursorArgs?: InputMaybe<CursorQueryArgs>;
  offsetArgs?: InputMaybe<OffsetQueryArgs>;
  pageArgs?: InputMaybe<PageQueryArgs>;
  type: Scalars['String']['input'];
};

export type PagingResult = {
  __typename?: 'PagingResult';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPrevPage?: Maybe<Scalars['Boolean']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  pagingCounter?: Maybe<Scalars['Int']['output']>;
  prevPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PeopleFilter = {
  __typename?: 'PeopleFilter';
  alcohol?: Maybe<Array<AlcoholHabit>>;
  beard?: Maybe<Array<Beard>>;
  distance?: Maybe<Scalars['Int']['output']>;
  etnicity?: Maybe<Array<Etnicity>>;
  eyeColor?: Maybe<Array<EyeColor>>;
  genders?: Maybe<Array<Gender>>;
  hairColor?: Maybe<Array<HairColor>>;
  hereTo?: Maybe<Array<HereTo>>;
  kids?: Maybe<Array<KidsStatus>>;
  languages?: Maybe<Array<Language>>;
  maxAge?: Maybe<Scalars['Int']['output']>;
  maxLength?: Maybe<Scalars['Int']['output']>;
  maxWeight?: Maybe<Scalars['Int']['output']>;
  minAge?: Maybe<Scalars['Int']['output']>;
  minLength?: Maybe<Scalars['Int']['output']>;
  minWeight?: Maybe<Scalars['Int']['output']>;
  relationshipStatus?: Maybe<Array<RelationshipStatus>>;
  religion?: Maybe<Array<Religion>>;
  sexuality?: Maybe<Array<Sexuality>>;
  showVerifiedOnly?: Maybe<Scalars['Boolean']['output']>;
  smoking?: Maybe<Array<SmokingHabit>>;
  tattoo?: Maybe<Array<Tattoo>>;
  zodiac?: Maybe<Array<Zodiac>>;
};

export type PeopleFilterInput = {
  alcohol?: InputMaybe<Array<AlcoholHabit>>;
  beard?: InputMaybe<Array<Beard>>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  etnicity?: InputMaybe<Array<Etnicity>>;
  eyeColor?: InputMaybe<Array<EyeColor>>;
  genders?: InputMaybe<Array<Gender>>;
  hairColor?: InputMaybe<Array<HairColor>>;
  hereTo?: InputMaybe<Array<HereTo>>;
  kids?: InputMaybe<Array<KidsStatus>>;
  languages?: InputMaybe<Array<Language>>;
  maxAge?: InputMaybe<Scalars['Int']['input']>;
  maxLength?: InputMaybe<Scalars['Int']['input']>;
  maxWeight?: InputMaybe<Scalars['Int']['input']>;
  minAge?: InputMaybe<Scalars['Int']['input']>;
  minLength?: InputMaybe<Scalars['Int']['input']>;
  minWeight?: InputMaybe<Scalars['Int']['input']>;
  relationshipStatus?: InputMaybe<Array<RelationshipStatus>>;
  religion?: InputMaybe<Array<Religion>>;
  sexuality?: InputMaybe<Array<Sexuality>>;
  showVerifiedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  smoking?: InputMaybe<Array<SmokingHabit>>;
  tattoo?: InputMaybe<Array<Tattoo>>;
  zodiac?: InputMaybe<Array<Zodiac>>;
};

export enum PersonalityTrait {
  Adventurous = 'Adventurous',
  Ambitious = 'Ambitious',
  Caring = 'Caring',
  Charismatic = 'Charismatic',
  Charming = 'Charming',
  Clever = 'Clever',
  Considerate = 'Considerate',
  Courageous = 'Courageous',
  Discreet = 'Discreet',
  Dramatic = 'Dramatic',
  Educated = 'Educated',
  Elegant = 'Elegant',
  Empathetic = 'Empathetic',
  Energetic = 'Energetic',
  Enthusiastic = 'Enthusiastic',
  Esthetic = 'Esthetic',
  Fair = 'Fair',
  Faithful = 'Faithful',
  Flexible = 'Flexible',
  Forgiving = 'Forgiving',
  Friendly = 'Friendly',
  Funny = 'Funny',
  Generous = 'Generous',
  Helpful = 'Helpful',
  Heroic = 'Heroic',
  Honest = 'Honest',
  Humble = 'Humble',
  Independent = 'Independent',
  Intelligent = 'Intelligent',
  Kind = 'Kind',
  Leader = 'Leader',
  Lovable = 'Lovable',
  Loving = 'Loving',
  Masculine = 'Masculine',
  Mature = 'Mature',
  Modest = 'Modest',
  Open = 'Open',
  Optimistic = 'Optimistic',
  Organized = 'Organized',
  Outgoing = 'Outgoing',
  Passionate = 'Passionate',
  Patient = 'Patient',
  Peaceful = 'Peaceful',
  Perfectionist = 'Perfectionist',
  Playful = 'Playful',
  Popular = 'Popular',
  Precise = 'Precise',
  Protective = 'Protective',
  Rational = 'Rational',
  Realistic = 'Realistic',
  Relaxed = 'Relaxed',
  Reliable = 'Reliable',
  Responsible = 'Responsible',
  Romantic = 'Romantic',
  Selfless = 'Selfless',
  Sensitive = 'Sensitive',
  Sentimental = 'Sentimental',
  Serious = 'Serious',
  Sharing = 'Sharing',
  Simple = 'Simple',
  Sociable = 'Sociable',
  Sophisticated = 'Sophisticated',
  Spontaneous = 'Spontaneous',
  Sweet = 'Sweet',
  Sympathetic = 'Sympathetic',
  Tasteful = 'Tasteful',
  Thoughtful = 'Thoughtful',
  Tolerant = 'Tolerant',
  Trusting = 'Trusting',
  Trustworthy = 'Trustworthy',
  Understanding = 'Understanding',
  Warm = 'Warm',
  Warmhearted = 'Warmhearted',
  Wise = 'Wise',
  Witty = 'Witty',
  Youthful = 'Youthful'
}

export type Photo = {
  __typename?: 'Photo';
  _id: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  blurhash?: Maybe<Scalars['String']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  thumbnailUrl: Scalars['String']['output'];
  uploadById?: Maybe<Scalars['String']['output']>;
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type PhotoUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type PhotoUploadResult = {
  __typename?: 'PhotoUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export type PhotoVerification = {
  __typename?: 'PhotoVerification';
  photo?: Maybe<Photo>;
  status?: Maybe<PhotoVerificationStatus>;
};

export enum PhotoVerificationStatus {
  Approved = 'Approved',
  None = 'None',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type PlaceAutocomplete = {
  __typename?: 'PlaceAutocomplete';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type Post = {
  __typename?: 'Post';
  _id: Scalars['String']['output'];
  attachments?: Maybe<Array<Attachment>>;
  audience: PostAudience;
  byUserId: Scalars['String']['output'];
  comments?: Maybe<PagedComments>;
  commentsAllowed: Scalars['Boolean']['output'];
  commentsCount: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  groupPhoto?: Maybe<Photo>;
  language?: Maybe<Scalars['String']['output']>;
  locked: Scalars['Boolean']['output'];
  meReaction?: Maybe<ReactionValue>;
  pinned?: Maybe<Scalars['Boolean']['output']>;
  reactionCounts: Array<Scalars['Int']['output']>;
  reviewStatus: PostReviewStatus;
  text?: Maybe<Scalars['String']['output']>;
  totalReactions: Scalars['Int']['output'];
  user?: Maybe<User>;
};


export type PostCommentsArgs = {
  input: PagingArgs;
};

export enum PostAudience {
  Followers = 'FOLLOWERS',
  Friends = 'FRIENDS',
  Group = 'GROUP',
  Matches = 'MATCHES',
  Public = 'PUBLIC'
}

export enum PostReviewStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type PrivatePhotosAccessResult = {
  __typename?: 'PrivatePhotosAccessResult';
  users: Array<User>;
};

export type Profile = {
  __typename?: 'Profile';
  _id: Scalars['String']['output'];
  alcohol?: Maybe<AlcoholHabit>;
  beard?: Maybe<Beard>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  birthDateChanged?: Maybe<Scalars['DateTime']['output']>;
  describeYourself?: Maybe<Scalars['String']['output']>;
  etnicity?: Maybe<Etnicity>;
  eyeColor?: Maybe<EyeColor>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Gender>;
  hairColor?: Maybe<HairColor>;
  hereTo?: Maybe<HereTo>;
  interests?: Maybe<Array<Interest>>;
  kids?: Maybe<KidsStatus>;
  languages?: Maybe<Array<Language>>;
  lastName?: Maybe<Scalars['String']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  living?: Maybe<Living>;
  location?: Maybe<ProfileLocation>;
  lookingFor?: Maybe<Array<Gender>>;
  mood?: Maybe<Scalars['String']['output']>;
  personality?: Maybe<Array<PersonalityTrait>>;
  relationshipStatus?: Maybe<RelationshipStatus>;
  religion?: Maybe<Religion>;
  sexuality?: Maybe<Sexuality>;
  smoking?: Maybe<SmokingHabit>;
  tattoo?: Maybe<Tattoo>;
  weight?: Maybe<Scalars['Int']['output']>;
  work?: Maybe<ProfileWork>;
  zodiac?: Maybe<Zodiac>;
};

export type ProfileInput = {
  alcohol?: InputMaybe<AlcoholHabit>;
  beard?: InputMaybe<Beard>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  describeYourself?: InputMaybe<Scalars['String']['input']>;
  etnicity?: InputMaybe<Etnicity>;
  eyeColor?: InputMaybe<EyeColor>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  hairColor?: InputMaybe<HairColor>;
  hereTo?: InputMaybe<HereTo>;
  interests?: InputMaybe<Array<Interest>>;
  kids?: InputMaybe<KidsStatus>;
  languages?: InputMaybe<Array<Language>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<Scalars['Int']['input']>;
  living?: InputMaybe<Living>;
  location?: InputMaybe<ProfileLocationInput>;
  lookingFor?: InputMaybe<Array<Gender>>;
  mood?: InputMaybe<Scalars['String']['input']>;
  personality?: InputMaybe<Array<PersonalityTrait>>;
  relationshipStatus?: InputMaybe<RelationshipStatus>;
  religion?: InputMaybe<Religion>;
  sexuality?: InputMaybe<Sexuality>;
  smoking?: InputMaybe<SmokingHabit>;
  tattoo?: InputMaybe<Tattoo>;
  weight?: InputMaybe<Scalars['Int']['input']>;
  work?: InputMaybe<ProfileWorkInput>;
  zodiac?: InputMaybe<Zodiac>;
};

export type ProfileLocation = {
  __typename?: 'ProfileLocation';
  _id: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  geo?: Maybe<Geo>;
};

export type ProfileLocationInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  county?: InputMaybe<Scalars['String']['input']>;
  geo?: InputMaybe<GeoInput>;
};

export type ProfileVisit = {
  __typename?: 'ProfileVisit';
  user: User;
  when: Scalars['DateTime']['output'];
};

export type ProfileWork = {
  __typename?: 'ProfileWork';
  _id: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  education?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProfileWorkInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  education?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PromoReward = {
  __typename?: 'PromoReward';
  rewardCredits: Scalars['Int']['output'];
  rewardPremiumDays: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']['output']>;
  achievements: Array<Achievement>;
  activeContacts: Array<User>;
  activeFriends: Array<User>;
  activePeopleVip?: Maybe<ListUserResult>;
  chat?: Maybe<Chat>;
  chatForUser?: Maybe<Chat>;
  chats?: Maybe<ChatList>;
  chattyMembersVip?: Maybe<ListUserResult>;
  checkAllowedReelUpload: AllowedReelUploadResult;
  currentAppVersion: Scalars['String']['output'];
  currentLiveViewers: PagedUsers;
  destinations?: Maybe<PagedDestinations>;
  emailSubscriptionSettings: EmailSubscriptionSettings;
  fetchBadges?: Maybe<BadgesResult>;
  fetchUpdates?: Maybe<SyncResult>;
  followers?: Maybe<FollowersResult>;
  freePremiumAvailable: Scalars['Int']['output'];
  freeSpinJackpot: Scalars['Int']['output'];
  getAdNetwork: AdNetworkResult;
  getAiCoaches?: Maybe<ListUserResult>;
  getComment?: Maybe<Comment>;
  getGroup?: Maybe<Group>;
  getHappyHour: HappyHour;
  getLiveBannedUsers: PagedUsers;
  getLiveStreamComments: PagedLiveStreamComments;
  getLiveStreamData: LiveStreamData;
  getLiveStreamingUsers: Array<User>;
  getMeets?: Maybe<PagedMeets>;
  getPost?: Maybe<Post>;
  getReactions: PagedReactions;
  getSnapFlirt: User;
  getTranslatedText?: Maybe<TranslationResult>;
  giftsBadge?: Maybe<Scalars['Int']['output']>;
  groupAds: Array<GroupAd>;
  groupInvites: PagedGroups;
  groups: PagedGroups;
  interestedMeetUsers?: Maybe<ListUserResult>;
  isMatch?: Maybe<IsMatch>;
  joinedGroups: PagedGroups;
  likesYouCount: Scalars['Int']['output'];
  managedGroups: PagedGroups;
  matches?: Maybe<PagedMatches>;
  matching?: Maybe<MatchingResult>;
  matchingMembersVip?: Maybe<ListUserResult>;
  me?: Maybe<Me>;
  meBlocks?: Maybe<Array<Maybe<Blocked>>>;
  meFavorites?: Maybe<Array<Maybe<Favorite>>>;
  meFilter?: Maybe<PeopleFilter>;
  meFollowing?: Maybe<Array<Maybe<Following>>>;
  meFriends?: Maybe<FriendsData>;
  meIntegrations: Integrations;
  meMatchVideo?: Maybe<Video>;
  mePhotoVerification?: Maybe<PhotoVerification>;
  mePhotos?: Maybe<Array<Photo>>;
  mePrivatePhotos?: Maybe<Array<Photo>>;
  mePrivateVideos?: Maybe<Array<Video>>;
  meProfile?: Maybe<Profile>;
  mePush?: Maybe<MePush>;
  meSettings?: Maybe<MeSettings>;
  meVideos?: Maybe<Array<Video>>;
  meVoicePrompts?: Maybe<Array<VoicePrompt>>;
  membersWithMatchVideo?: Maybe<ListUserResult>;
  membersWithPrivatePhotos?: Maybe<ListUserResult>;
  membersWithPrivateVideos?: Maybe<ListUserResult>;
  membersWithTravelPlans?: Maybe<ListUserResult>;
  membersWithVerifiedPhoto?: Maybe<ListUserResult>;
  membersWithVoiceRecordings?: Maybe<ListUserResult>;
  messageAttachments?: Maybe<Array<Maybe<MessageAttachment>>>;
  nearbyPeople?: Maybe<NearbyPeopleResult>;
  negativeVotePeople?: Maybe<ListUserResult>;
  newMessages?: Maybe<NewMessages>;
  newMessagesForChat?: Maybe<NewMessagesForChat>;
  newPeopleVip?: Maybe<ListUserResult>;
  newUsers?: Maybe<SearchUsersResult>;
  news: Array<Maybe<News>>;
  notifications?: Maybe<NotificationsResult>;
  pagedReels: PagedReels;
  pagedSurveys: PagedSurveys;
  peopleAtDestination?: Maybe<NearbyPeopleResult>;
  peopleReadyToChat: Array<User>;
  peopleSeeking?: Maybe<ListUserResult>;
  peopleVisitingDestination?: Maybe<NearbyPeopleResult>;
  peopleWithInterest?: Maybe<ListUserResult>;
  peopleWithZodiac?: Maybe<ListUserResult>;
  photo?: Maybe<Photo>;
  placeAutocomplete?: Maybe<Array<Maybe<PlaceAutocomplete>>>;
  popularPeople: Array<User>;
  popularPeopleVip?: Maybe<ListUserResult>;
  postsFeed: PagedPosts;
  privatePhotosAccessUsers?: Maybe<PrivatePhotosAccessResult>;
  profileVisitors: Array<ProfileVisit>;
  promotedReels: Array<Reel>;
  receivedGifts?: Maybe<Array<Gift>>;
  recentActive: Array<User>;
  recentLogins: Array<User>;
  reel: Reel;
  reelComments: PagedComments;
  reelPromotion: ReelPromotionResult;
  reels: Array<Reel>;
  reelsByUser: Array<Reel>;
  reelsForYou: ReelsForYouResult;
  reelsInGroup: Array<Reel>;
  reverseGeocode?: Maybe<GeocodeResult>;
  searchUsers?: Maybe<SearchUsersResult>;
  sentGifts?: Maybe<Array<Gift>>;
  spotlight?: Maybe<SearchUsersResult>;
  spotlights: SpotlightResult;
  survey?: Maybe<Survey>;
  topPicks?: Maybe<ListUserResult>;
  topRated?: Maybe<ListUserResult>;
  unhandledMatches?: Maybe<PagedMatches>;
  unhandledMatchesCount: Scalars['Int']['output'];
  user?: Maybe<User>;
  userCanReply: Scalars['Boolean']['output'];
  userInsights?: Maybe<UserInsights>;
  users: Array<User>;
  video?: Maybe<Video>;
  yesMatches?: Maybe<PagedYesMatches>;
  yesMatchesBlurred?: Maybe<PagedYesMatchesBlurred>;
  yesVotesLeft: Scalars['Int']['output'];
};


export type QueryActivePeopleVipArgs = {
  paging: PagingArgs;
};


export type QueryChatArgs = {
  chatId: Scalars['String']['input'];
};


export type QueryChatForUserArgs = {
  userId: Scalars['String']['input'];
};


export type QueryChattyMembersVipArgs = {
  paging: PagingArgs;
};


export type QueryCurrentAppVersionArgs = {
  appPlatform?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrentLiveViewersArgs = {
  paging: PagingArgs;
  userId: Scalars['String']['input'];
};


export type QueryDestinationsArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  paging: PagingArgs;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEmailSubscriptionSettingsArgs = {
  emailUuid: Scalars['String']['input'];
};


export type QueryFetchUpdatesArgs = {
  notificationsFromDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryFollowersArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAiCoachesArgs = {
  paging: PagingArgs;
};


export type QueryGetCommentArgs = {
  commentId: Scalars['String']['input'];
};


export type QueryGetGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type QueryGetLiveBannedUsersArgs = {
  paging: PagingArgs;
};


export type QueryGetLiveStreamCommentsArgs = {
  paging: PagingArgs;
};


export type QueryGetLiveStreamDataArgs = {
  lastPollDate: Scalars['DateTime']['input'];
  userId: Scalars['String']['input'];
  videoActive?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetLiveStreamingUsersArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryGetMeetsArgs = {
  input: PagingArgs;
};


export type QueryGetPostArgs = {
  postId: Scalars['String']['input'];
};


export type QueryGetReactionsArgs = {
  paging: PagingArgs;
  parentId: Scalars['String']['input'];
  reaction?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type QueryGetTranslatedTextArgs = {
  messageId: Scalars['String']['input'];
};


export type QueryGroupInvitesArgs = {
  paging: PagingArgs;
};


export type QueryGroupsArgs = {
  paging: PagingArgs;
  regex?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInterestedMeetUsersArgs = {
  meetId: Scalars['String']['input'];
  paging: PagingArgs;
};


export type QueryIsMatchArgs = {
  userId: Scalars['String']['input'];
};


export type QueryJoinedGroupsArgs = {
  paging: PagingArgs;
  regex?: InputMaybe<Scalars['String']['input']>;
};


export type QueryManagedGroupsArgs = {
  paging: PagingArgs;
};


export type QueryMatchesArgs = {
  input: PagingArgs;
};


export type QueryMatchingArgs = {
  input: MatchingArgs;
};


export type QueryMatchingMembersVipArgs = {
  paging: PagingArgs;
};


export type QueryMeArgs = {
  currentAppVersion?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMembersWithMatchVideoArgs = {
  paging: PagingArgs;
};


export type QueryMembersWithPrivatePhotosArgs = {
  paging: PagingArgs;
};


export type QueryMembersWithPrivateVideosArgs = {
  paging: PagingArgs;
};


export type QueryMembersWithTravelPlansArgs = {
  paging: PagingArgs;
};


export type QueryMembersWithVerifiedPhotoArgs = {
  paging: PagingArgs;
};


export type QueryMembersWithVoiceRecordingsArgs = {
  paging: PagingArgs;
};


export type QueryMessageAttachmentsArgs = {
  messageId: Scalars['String']['input'];
};


export type QueryNearbyPeopleArgs = {
  paging: PagingArgs;
};


export type QueryNegativeVotePeopleArgs = {
  paging: PagingArgs;
};


export type QueryNewMessagesForChatArgs = {
  chatId: Scalars['String']['input'];
  lastMessageDate: Scalars['DateTime']['input'];
};


export type QueryNewPeopleVipArgs = {
  paging: PagingArgs;
};


export type QueryNewsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type QueryNotificationsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryPagedReelsArgs = {
  feedType?: InputMaybe<ReelFeedType>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['Int']['input']>;
  paging: PagingArgs;
  safeSearch?: InputMaybe<Scalars['Boolean']['input']>;
  skipReelId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPagedSurveysArgs = {
  paging: PagingArgs;
};


export type QueryPeopleAtDestinationArgs = {
  destinationId: Scalars['String']['input'];
  paging: PagingArgs;
};


export type QueryPeopleReadyToChatArgs = {
  amount: Scalars['Int']['input'];
};


export type QueryPeopleSeekingArgs = {
  hereTo?: InputMaybe<HereTo>;
  paging: PagingArgs;
};


export type QueryPeopleVisitingDestinationArgs = {
  destinationId: Scalars['String']['input'];
  paging: PagingArgs;
};


export type QueryPeopleWithInterestArgs = {
  interest: Interest;
  paging: PagingArgs;
};


export type QueryPeopleWithZodiacArgs = {
  paging: PagingArgs;
  zodiac: Zodiac;
};


export type QueryPhotoArgs = {
  uploadId: Scalars['String']['input'];
};


export type QueryPlaceAutocompleteArgs = {
  text: Scalars['String']['input'];
};


export type QueryPopularPeopleVipArgs = {
  paging: PagingArgs;
};


export type QueryPostsFeedArgs = {
  audience: Array<PostAudience>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  paging: PagingArgs;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPromotedReelsArgs = {
  safeSearch?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryReelArgs = {
  reelId: Scalars['String']['input'];
};


export type QueryReelCommentsArgs = {
  paging: PagingArgs;
  reelId: Scalars['String']['input'];
};


export type QueryReelPromotionArgs = {
  reelId: Scalars['String']['input'];
};


export type QueryReelsArgs = {
  limit: Scalars['Int']['input'];
};


export type QueryReelsByUserArgs = {
  limit: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};


export type QueryReelsForYouArgs = {
  args: ReelsForYouArgs;
};


export type QueryReelsInGroupArgs = {
  groupId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
};


export type QueryReverseGeocodeArgs = {
  lat: Scalars['String']['input'];
  lng: Scalars['String']['input'];
};


export type QuerySearchUsersArgs = {
  groupInvite?: InputMaybe<Scalars['Boolean']['input']>;
  regex?: InputMaybe<Scalars['String']['input']>;
  useFilter?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySpotlightArgs = {
  amount: Scalars['Int']['input'];
};


export type QuerySpotlightsArgs = {
  amount: Scalars['Int']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySurveyArgs = {
  surveyId: Scalars['String']['input'];
};


export type QueryTopPicksArgs = {
  paging: PagingArgs;
};


export type QueryTopRatedArgs = {
  paging: PagingArgs;
};


export type QueryUnhandledMatchesArgs = {
  input: PagingArgs;
};


export type QueryUserArgs = {
  username: Scalars['String']['input'];
};


export type QueryUserCanReplyArgs = {
  chatId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryUserInsightsArgs = {
  username: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  regex?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVideoArgs = {
  uploadId: Scalars['String']['input'];
};


export type QueryYesMatchesArgs = {
  input: PagingArgs;
};


export type QueryYesMatchesBlurredArgs = {
  input: PagingArgs;
};

export type Reaction = {
  __typename?: 'Reaction';
  _id: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  reaction: Scalars['String']['output'];
  type: ReactionType;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};

export enum ReactionType {
  Comment = 'COMMENT',
  Message = 'MESSAGE',
  Post = 'POST',
  Reel = 'REEL'
}

export enum ReactionValue {
  Angry = 'ANGRY',
  Care = 'CARE',
  Flirt = 'FLIRT',
  Haha = 'HAHA',
  Like = 'LIKE',
  Love = 'LOVE',
  NoReaction = 'NO_REACTION',
  Sad = 'SAD',
  Wow = 'WOW'
}

export type Reel = {
  __typename?: 'Reel';
  _id: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  bookmarkCount: Scalars['Int']['output'];
  bookmarked: Scalars['Boolean']['output'];
  commentsCount: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  explicit: Scalars['Boolean']['output'];
  meLiked: Scalars['Boolean']['output'];
  promoteDiscount: Scalars['Boolean']['output'];
  pushNotifications: Scalars['Boolean']['output'];
  thumbnailBlurhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  totalLikes: Scalars['Int']['output'];
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  user: User;
  views: Scalars['Int']['output'];
};

export enum ReelFeedType {
  Following = 'FOLLOWING',
  Group = 'GROUP',
  MostCommented = 'MOST_COMMENTED',
  MostLiked = 'MOST_LIKED',
  MostViewed = 'MOST_VIEWED',
  Promoted = 'PROMOTED',
  Public = 'PUBLIC',
  Saved = 'SAVED',
  User = 'USER'
}

export type ReelPromotionResult = {
  __typename?: 'ReelPromotionResult';
  created?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  promoted: Scalars['Boolean']['output'];
};

export type ReelUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  groupId?: InputMaybe<Scalars['String']['input']>;
};

export type ReelUploadResult = {
  __typename?: 'ReelUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export type ReelsForYouArgs = {
  safeSearch?: InputMaybe<Scalars['Boolean']['input']>;
  skipReelIds: Array<Scalars['String']['input']>;
};

export type ReelsForYouResult = {
  __typename?: 'ReelsForYouResult';
  reels: Array<Reel>;
};

export enum RelationshipStatus {
  Complicated = 'COMPLICATED',
  InOpenRelationship = 'IN_OPEN_RELATIONSHIP',
  InRelationship = 'IN_RELATIONSHIP',
  Married = 'MARRIED',
  Other = 'OTHER',
  Single = 'SINGLE',
  Undisclosed = 'UNDISCLOSED'
}

export enum Religion {
  Agnostic = 'Agnostic',
  Atheist = 'Atheist',
  Buddhist = 'Buddhist',
  Catholic = 'Catholic',
  Christian = 'Christian',
  Hindu = 'Hindu',
  Jain = 'Jain',
  Jewish = 'Jewish',
  Muslim = 'Muslim',
  Other = 'Other',
  Parsi = 'Parsi',
  Sikh = 'Sikh',
  Spiritual = 'Spiritual',
  Undisclosed = 'UNDISCLOSED'
}

export type Report = {
  __typename?: 'Report';
  _id: Scalars['String']['output'];
  byUserId?: Maybe<Scalars['String']['output']>;
  contentId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  problem?: Maybe<ReportProblem>;
  resolved?: Maybe<Scalars['Boolean']['output']>;
  resolvedByEmail?: Maybe<Scalars['String']['output']>;
  resolvedDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ReportType>;
};

export type ReportArgs = {
  contentId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  problem: ReportProblem;
  type: ReportType;
};

export enum ReportProblem {
  Bullying = 'BULLYING',
  Harassment = 'HARASSMENT',
  HateSpeech = 'HATE_SPEECH',
  IntelectualProperty = 'INTELECTUAL_PROPERTY',
  Nudity = 'NUDITY',
  PrivacyViolation = 'PRIVACY_VIOLATION',
  Scam = 'SCAM',
  SelfInjury = 'SELF_INJURY',
  SexualServices = 'SEXUAL_SERVICES',
  Spam = 'SPAM',
  Terrorism = 'TERRORISM',
  Violence = 'VIOLENCE'
}

export enum ReportType {
  Comment = 'COMMENT',
  Group = 'GROUP',
  Live = 'LIVE',
  Message = 'MESSAGE',
  Post = 'POST',
  Reel = 'REEL',
  User = 'USER'
}

export type SearchUsersResult = {
  __typename?: 'SearchUsersResult';
  users: Array<User>;
};

export type SendMessageResult = {
  __typename?: 'SendMessageResult';
  attachments?: Maybe<Array<Maybe<UploadPostInfo>>>;
  message: Message;
  newMessageId: Scalars['String']['output'];
};

export type SendMessageToChatArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentArgs>>;
  chatId: Scalars['String']['input'];
  clientId: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type SendMessageToUserArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentArgs>>;
  clientId: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type Settings = {
  __typename?: 'Settings';
  _id?: Maybe<Scalars['String']['output']>;
  allowBlindDateInvites: Scalars['Boolean']['output'];
  allowFriendRequests: Scalars['Boolean']['output'];
  allowGroupInvites: Scalars['Boolean']['output'];
  filterReels: Scalars['Boolean']['output'];
  hideLocation: Scalars['Boolean']['output'];
  incognito: Scalars['Boolean']['output'];
  language?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<MessagesSettings>;
  notifications?: Maybe<NotificationSettings>;
  showOnlineStatus: Scalars['Boolean']['output'];
};

export type SettingsInput = {
  allowBlindDateInvites?: InputMaybe<Scalars['Boolean']['input']>;
  allowFriendRequests?: InputMaybe<Scalars['Boolean']['input']>;
  allowGroupInvites?: InputMaybe<Scalars['Boolean']['input']>;
  filterReels?: InputMaybe<Scalars['Boolean']['input']>;
  hideLocation?: InputMaybe<Scalars['Boolean']['input']>;
  incognito?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  messages?: InputMaybe<MessagesSettingsInput>;
  notifications?: InputMaybe<NotificationSettingsInput>;
  showOnlineStatus?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Sexuality {
  AskMe = 'ASK_ME',
  Bisexual = 'BISEXUAL',
  Hetero = 'HETERO',
  Homo = 'HOMO',
  Other = 'OTHER',
  Undisclosed = 'UNDISCLOSED'
}

export enum SmokingHabit {
  HateSmoking = 'HATE_SMOKING',
  No = 'NO',
  Undisclosed = 'UNDISCLOSED',
  YesEveryDay = 'YES_EVERY_DAY',
  YesParties = 'YES_PARTIES',
  YesSometimes = 'YES_SOMETIMES'
}

export type Spotify = {
  __typename?: 'Spotify';
  _id: Scalars['String']['output'];
  display_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  media?: Maybe<SpotifyMedia>;
  userId: Scalars['String']['output'];
};

export type SpotifyArtist = {
  __typename?: 'SpotifyArtist';
  external_url?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  popularity?: Maybe<Scalars['Int']['output']>;
};

export type SpotifyMedia = {
  __typename?: 'SpotifyMedia';
  recent_track?: Maybe<SpotifyTrack>;
  top_artists?: Maybe<Array<SpotifyArtist>>;
  top_tracks?: Maybe<Array<SpotifyTrack>>;
};

export type SpotifyTrack = {
  __typename?: 'SpotifyTrack';
  artist?: Maybe<Scalars['String']['output']>;
  external_url?: Maybe<Scalars['String']['output']>;
  genres?: Maybe<Array<Scalars['String']['output']>>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  played_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Spotlight = {
  __typename?: 'Spotlight';
  _id: Scalars['String']['output'];
  country: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  photo: Photo;
  reel?: Maybe<Reel>;
  user: User;
};

export type SpotlightResult = {
  __typename?: 'SpotlightResult';
  nodes: Array<Spotlight>;
};

export type SubmitSurveyArgs = {
  answers: Array<SurveyAnswer>;
  surveyId: Scalars['String']['input'];
};

export type Survey = {
  __typename?: 'Survey';
  _id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  questions: Array<SurveyQuestion>;
  rewardAmount: Scalars['Int']['output'];
  rewardType: SurveyRewardType;
  title: Scalars['String']['output'];
};

export type SurveyAnswer = {
  choices: Array<Scalars['String']['input']>;
};

export type SurveyListItem = {
  __typename?: 'SurveyListItem';
  _id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  numberOfQuestions: Scalars['Int']['output'];
  rewardAmount: Scalars['Int']['output'];
  rewardType: SurveyRewardType;
  submitted: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  choices: Array<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  timer: Scalars['Int']['output'];
  type: SurveyQuestionType;
};

export enum SurveyQuestionType {
  MultipleChoice = 'MultipleChoice',
  SingleChoice = 'SingleChoice',
  Text = 'Text'
}

export enum SurveyRewardType {
  Credits = 'Credits',
  None = 'None'
}

export type SyncResult = {
  __typename?: 'SyncResult';
  newMessages: NewMessages;
  notifications: NotificationsResult;
};

export enum Tattoo {
  Many = 'MANY',
  No = 'NO',
  One = 'ONE',
  Undisclosed = 'UNDISCLOSED'
}

export type Tiktok = {
  __typename?: 'Tiktok';
  _id: Scalars['String']['output'];
  media?: Maybe<Array<TiktokMedia>>;
  updatedDate: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type TiktokMedia = {
  __typename?: 'TiktokMedia';
  cover_image_url: Scalars['String']['output'];
  embed_link: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type TranslatedText = {
  __typename?: 'TranslatedText';
  toLang: Scalars['String']['output'];
  translated: Scalars['String']['output'];
};

export type TranslationResult = {
  __typename?: 'TranslationResult';
  availableTranslationAmount: Scalars['Int']['output'];
  translatedText: TranslatedText;
};

export type UpdateGroupArgs = {
  about?: InputMaybe<Scalars['String']['input']>;
  genderLimited?: InputMaybe<Array<Gender>>;
  maxAgeLimited?: InputMaybe<Scalars['Int']['input']>;
  memberCommentsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  memberPostsAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  memberVisibility?: InputMaybe<MemberVisibility>;
  minAgeLimited?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<GroupPrivacy>;
  rules?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<GroupVisibility>;
};

export type UploadField = {
  __typename?: 'UploadField';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type UploadPostInfo = {
  __typename?: 'UploadPostInfo';
  fields?: Maybe<Array<Maybe<UploadField>>>;
  fileName: Scalars['String']['output'];
  postUrl: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['String']['output'];
  admin?: Maybe<Scalars['Boolean']['output']>;
  aiCoach?: Maybe<Scalars['Boolean']['output']>;
  blocks?: Maybe<Array<UserBlock>>;
  created?: Maybe<Scalars['DateTime']['output']>;
  deactivated?: Maybe<Scalars['Boolean']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  ghostingChats?: Maybe<Scalars['Int']['output']>;
  hasReceivedFriendRequest?: Maybe<Scalars['Boolean']['output']>;
  hasSentFriendRequest?: Maybe<Scalars['Boolean']['output']>;
  instagram?: Maybe<Instagram>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  isFriend?: Maybe<Scalars['Boolean']['output']>;
  isMatch?: Maybe<Scalars['Boolean']['output']>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
  isPopular?: Maybe<Scalars['Boolean']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastActive?: Maybe<Scalars['DateTime']['output']>;
  latestReel?: Maybe<Reel>;
  latestReelUrl?: Maybe<Scalars['String']['output']>;
  likesMe?: Maybe<LikesMe>;
  loginDate?: Maybe<Scalars['DateTime']['output']>;
  matchVideo?: Maybe<Video>;
  matchesFilter?: Maybe<Scalars['Boolean']['output']>;
  mood?: Maybe<Scalars['String']['output']>;
  moodDate?: Maybe<Scalars['DateTime']['output']>;
  onlineToday?: Maybe<Scalars['Boolean']['output']>;
  photos?: Maybe<Array<Photo>>;
  popularity?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Boolean']['output']>;
  profile?: Maybe<Profile>;
  profilePhoto?: Maybe<Photo>;
  profilePhotoApproved?: Maybe<Scalars['Boolean']['output']>;
  profilePhotoUploadId?: Maybe<Scalars['String']['output']>;
  readyToChatDate?: Maybe<Scalars['DateTime']['output']>;
  sendingLive?: Maybe<Scalars['Boolean']['output']>;
  showOnlineStatus?: Maybe<Scalars['Boolean']['output']>;
  spotify?: Maybe<Spotify>;
  tiktok?: Maybe<Tiktok>;
  totalReels?: Maybe<Scalars['Int']['output']>;
  travelPlans?: Maybe<Array<Destination>>;
  username?: Maybe<Scalars['String']['output']>;
  verifications?: Maybe<Array<VerificationType>>;
  verifiedPhoto?: Maybe<Scalars['Boolean']['output']>;
  videos?: Maybe<Array<Video>>;
  voicePrompts?: Maybe<Array<VoicePrompt>>;
};


export type UserProfilePhotoArgs = {
  cdn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserBlock = {
  __typename?: 'UserBlock';
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserCardData = {
  __typename?: 'UserCardData';
  _id: Scalars['String']['output'];
  blurhash?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Geo>;
  firstName?: Maybe<Scalars['String']['output']>;
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  mood?: Maybe<Scalars['String']['output']>;
  sendingLive?: Maybe<Scalars['Boolean']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UserInsights = {
  __typename?: 'UserInsights';
  chatsStartedByUser?: Maybe<Scalars['Int']['output']>;
  chatsUserHasNotRepliedTo?: Maybe<Scalars['Int']['output']>;
  chatsUserIsBeingGhosted?: Maybe<Scalars['Int']['output']>;
  chatsUserIsGhosting?: Maybe<Scalars['Int']['output']>;
  followers?: Maybe<Scalars['Int']['output']>;
  friends?: Maybe<Scalars['Int']['output']>;
  peopleBlocked?: Maybe<Scalars['Int']['output']>;
  peopleBlockedUser?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Boolean']['output']>;
  profileVisits?: Maybe<Scalars['Int']['output']>;
  totalChats?: Maybe<Scalars['Int']['output']>;
  totalMatches?: Maybe<Scalars['Int']['output']>;
  totalVotesByUser?: Maybe<Scalars['Int']['output']>;
  totalVotesForUser?: Maybe<Scalars['Int']['output']>;
  totalVotesYesByUser?: Maybe<Scalars['Int']['output']>;
  vip?: Maybe<Scalars['Boolean']['output']>;
};

export enum VerificationType {
  Facebook = 'Facebook',
  Google = 'Google',
  Instagram = 'Instagram',
  Phone = 'Phone',
  Photo = 'Photo',
  Spotify = 'Spotify',
  Tiktok = 'Tiktok'
}

export type Video = {
  __typename?: 'Video';
  _id: Scalars['String']['output'];
  approved: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  thumbnailBlurhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  uploadById?: Maybe<Scalars['String']['output']>;
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type VideoThumbnailUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  videoUploadId: Scalars['String']['input'];
};

export type VideoUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type VideoUploadResult = {
  __typename?: 'VideoUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export type VoicePrompt = {
  __typename?: 'VoicePrompt';
  text: Scalars['String']['output'];
  uploadComplete: Scalars['Boolean']['output'];
  uploadId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type VoicePromptUploadArgs = {
  contentLength: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  promptText: Scalars['String']['input'];
};

export type VoicePromptUploadResult = {
  __typename?: 'VoicePromptUploadResult';
  uploadId: Scalars['String']['output'];
  uploadPostInfo: UploadPostInfo;
};

export enum Zodiac {
  Aquarius = 'Aquarius',
  Aries = 'Aries',
  Cancer = 'Cancer',
  Capricornus = 'Capricornus',
  Gemini = 'Gemini',
  Leo = 'Leo',
  Libra = 'Libra',
  Pisces = 'Pisces',
  Sagittarius = 'Sagittarius',
  Scorpius = 'Scorpius',
  Taurus = 'Taurus',
  Undisclosed = 'UNDISCLOSED',
  Virgo = 'Virgo'
}

export enum ZodiacSign {
  Aquarius = 'Aquarius',
  Aries = 'Aries',
  Cancer = 'Cancer',
  Capricornus = 'Capricornus',
  Gemini = 'Gemini',
  Leo = 'Leo',
  Libra = 'Libra',
  Pisces = 'Pisces',
  Sagittarius = 'Sagittarius',
  Scorpius = 'Scorpius',
  Taurus = 'Taurus',
  Virgo = 'Virgo'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Account: ResolverTypeWrapper<Account>;
  Achievement: ResolverTypeWrapper<Achievement>;
  AchievementId: AchievementId;
  AdNetwork: AdNetwork;
  AdNetworkResult: ResolverTypeWrapper<AdNetworkResult>;
  AddMeetInput: AddMeetInput;
  AlcoholHabit: AlcoholHabit;
  AllowedReelUploadResult: ResolverTypeWrapper<AllowedReelUploadResult>;
  Attachment: ResolverTypeWrapper<Attachment>;
  AttachmentArgs: AttachmentArgs;
  BadgesResult: ResolverTypeWrapper<BadgesResult>;
  Beard: Beard;
  Blocked: ResolverTypeWrapper<Blocked>;
  BlurredMatch: ResolverTypeWrapper<BlurredMatch>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Chat: ResolverTypeWrapper<Chat>;
  ChatList: ResolverTypeWrapper<ChatList>;
  ChatListItem: ResolverTypeWrapper<ChatListItem>;
  Comment: ResolverTypeWrapper<Comment>;
  CommentType: CommentType;
  CursorQueryArgs: CursorQueryArgs;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeleteArgs: DeleteArgs;
  DeleteReason: DeleteReason;
  Destination: ResolverTypeWrapper<Destination>;
  DestinationInput: DestinationInput;
  DestinationLocation: ResolverTypeWrapper<DestinationLocation>;
  DestinationLocationInput: DestinationLocationInput;
  EmailSubscriptionSettings: ResolverTypeWrapper<EmailSubscriptionSettings>;
  EmailSubscriptionSettingsInput: EmailSubscriptionSettingsInput;
  Etnicity: Etnicity;
  EyeColor: EyeColor;
  Facebook: ResolverTypeWrapper<Facebook>;
  Favorite: ResolverTypeWrapper<Favorite>;
  FeatureType: FeatureType;
  FeedbackType: FeedbackType;
  FileUploadArgs: FileUploadArgs;
  FileUploadResult: ResolverTypeWrapper<FileUploadResult>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Follower: ResolverTypeWrapper<Follower>;
  FollowersResult: ResolverTypeWrapper<FollowersResult>;
  Following: ResolverTypeWrapper<Following>;
  Friend: ResolverTypeWrapper<Friend>;
  FriendRequest: ResolverTypeWrapper<FriendRequest>;
  FriendsData: ResolverTypeWrapper<FriendsData>;
  Gender: Gender;
  Geo: ResolverTypeWrapper<Geo>;
  GeoInput: GeoInput;
  GeocodeResult: ResolverTypeWrapper<GeocodeResult>;
  GhostingStatus: ResolverTypeWrapper<GhostingStatus>;
  Gift: ResolverTypeWrapper<Gift>;
  Google: ResolverTypeWrapper<Google>;
  Group: ResolverTypeWrapper<Group>;
  GroupAd: ResolverTypeWrapper<GroupAd>;
  GroupPrivacy: GroupPrivacy;
  GroupStatus: GroupStatus;
  GroupVisibility: GroupVisibility;
  HairColor: HairColor;
  HappyHour: ResolverTypeWrapper<HappyHour>;
  HereTo: HereTo;
  Instagram: ResolverTypeWrapper<Instagram>;
  InstagramMedia: ResolverTypeWrapper<InstagramMedia>;
  InstagramMediaType: InstagramMediaType;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Integrations: ResolverTypeWrapper<Integrations>;
  Interest: Interest;
  IsMatch: ResolverTypeWrapper<IsMatch>;
  KidsStatus: KidsStatus;
  LIVING: Living;
  Language: Language;
  LastViewedMessagesForChat: ResolverTypeWrapper<LastViewedMessagesForChat>;
  LikeStreakInfo: ResolverTypeWrapper<LikeStreakInfo>;
  LikesMe: ResolverTypeWrapper<LikesMe>;
  ListUserResult: ResolverTypeWrapper<ListUserResult>;
  LiveComment: ResolverTypeWrapper<LiveComment>;
  LiveGift: LiveGift;
  LiveStatusUpdate: LiveStatusUpdate;
  LiveStreamData: ResolverTypeWrapper<LiveStreamData>;
  Location: ResolverTypeWrapper<Location>;
  LocationInput: LocationInput;
  MatchResult: ResolverTypeWrapper<MatchResult>;
  MatchingArgs: MatchingArgs;
  MatchingResult: ResolverTypeWrapper<MatchingResult>;
  Me: ResolverTypeWrapper<Me>;
  MePush: ResolverTypeWrapper<MePush>;
  MeSettings: ResolverTypeWrapper<MeSettings>;
  Meet: ResolverTypeWrapper<Meet>;
  MeetType: MeetType;
  MeetWhen: MeetWhen;
  MemberVisibility: MemberVisibility;
  Message: ResolverTypeWrapper<Message>;
  MessageAttachment: ResolverTypeWrapper<MessageAttachment>;
  MessageAttachmentArgs: MessageAttachmentArgs;
  MessageAttachmentExpireType: MessageAttachmentExpireType;
  MessagesSettings: ResolverTypeWrapper<MessagesSettings>;
  MessagesSettingsInput: MessagesSettingsInput;
  Mutation: ResolverTypeWrapper<{}>;
  NearbyPeopleResult: ResolverTypeWrapper<NearbyPeopleResult>;
  NewCommentArgs: NewCommentArgs;
  NewCommentResult: ResolverTypeWrapper<NewCommentResult>;
  NewGroupArgs: NewGroupArgs;
  NewGroupResult: ResolverTypeWrapper<NewGroupResult>;
  NewMessage: ResolverTypeWrapper<NewMessage>;
  NewMessageSetting: NewMessageSetting;
  NewMessages: ResolverTypeWrapper<NewMessages>;
  NewMessagesForChat: ResolverTypeWrapper<NewMessagesForChat>;
  NewPostArgs: NewPostArgs;
  NewPostResult: ResolverTypeWrapper<NewPostResult>;
  NewUsersResult: ResolverTypeWrapper<NewUsersResult>;
  News: ResolverTypeWrapper<News>;
  NotifMessageReactionData: ResolverTypeWrapper<NotifMessageReactionData>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationSetting: ResolverTypeWrapper<NotificationSetting>;
  NotificationSettingInput: NotificationSettingInput;
  NotificationSettings: ResolverTypeWrapper<NotificationSettings>;
  NotificationSettingsInput: NotificationSettingsInput;
  NotificationType: NotificationType;
  NotificationsResult: ResolverTypeWrapper<NotificationsResult>;
  OffsetQueryArgs: OffsetQueryArgs;
  PageQueryArgs: PageQueryArgs;
  PagedChatList: ResolverTypeWrapper<PagedChatList>;
  PagedComments: ResolverTypeWrapper<PagedComments>;
  PagedDestinations: ResolverTypeWrapper<PagedDestinations>;
  PagedGroups: ResolverTypeWrapper<PagedGroups>;
  PagedLiveStreamComments: ResolverTypeWrapper<PagedLiveStreamComments>;
  PagedMatches: ResolverTypeWrapper<PagedMatches>;
  PagedMeets: ResolverTypeWrapper<PagedMeets>;
  PagedMessages: ResolverTypeWrapper<PagedMessages>;
  PagedPosts: ResolverTypeWrapper<PagedPosts>;
  PagedReactions: ResolverTypeWrapper<PagedReactions>;
  PagedReels: ResolverTypeWrapper<PagedReels>;
  PagedSurveys: ResolverTypeWrapper<PagedSurveys>;
  PagedUsers: ResolverTypeWrapper<PagedUsers>;
  PagedYesMatches: ResolverTypeWrapper<PagedYesMatches>;
  PagedYesMatchesBlurred: ResolverTypeWrapper<PagedYesMatchesBlurred>;
  PagingArgs: PagingArgs;
  PagingResult: ResolverTypeWrapper<PagingResult>;
  PeopleFilter: ResolverTypeWrapper<PeopleFilter>;
  PeopleFilterInput: PeopleFilterInput;
  PersonalityTrait: PersonalityTrait;
  Photo: ResolverTypeWrapper<Photo>;
  PhotoUploadArgs: PhotoUploadArgs;
  PhotoUploadResult: ResolverTypeWrapper<PhotoUploadResult>;
  PhotoVerification: ResolverTypeWrapper<PhotoVerification>;
  PhotoVerificationStatus: PhotoVerificationStatus;
  PlaceAutocomplete: ResolverTypeWrapper<PlaceAutocomplete>;
  Post: ResolverTypeWrapper<Post>;
  PostAudience: PostAudience;
  PostReviewStatus: PostReviewStatus;
  PrivatePhotosAccessResult: ResolverTypeWrapper<PrivatePhotosAccessResult>;
  Profile: ResolverTypeWrapper<Profile>;
  ProfileInput: ProfileInput;
  ProfileLocation: ResolverTypeWrapper<ProfileLocation>;
  ProfileLocationInput: ProfileLocationInput;
  ProfileVisit: ResolverTypeWrapper<ProfileVisit>;
  ProfileWork: ResolverTypeWrapper<ProfileWork>;
  ProfileWorkInput: ProfileWorkInput;
  PromoReward: ResolverTypeWrapper<PromoReward>;
  Query: ResolverTypeWrapper<{}>;
  Reaction: ResolverTypeWrapper<Reaction>;
  ReactionType: ReactionType;
  ReactionValue: ReactionValue;
  Reel: ResolverTypeWrapper<Reel>;
  ReelFeedType: ReelFeedType;
  ReelPromotionResult: ResolverTypeWrapper<ReelPromotionResult>;
  ReelUploadArgs: ReelUploadArgs;
  ReelUploadResult: ResolverTypeWrapper<ReelUploadResult>;
  ReelsForYouArgs: ReelsForYouArgs;
  ReelsForYouResult: ResolverTypeWrapper<ReelsForYouResult>;
  RelationshipStatus: RelationshipStatus;
  Religion: Religion;
  Report: ResolverTypeWrapper<Report>;
  ReportArgs: ReportArgs;
  ReportProblem: ReportProblem;
  ReportType: ReportType;
  SearchUsersResult: ResolverTypeWrapper<SearchUsersResult>;
  SendMessageResult: ResolverTypeWrapper<SendMessageResult>;
  SendMessageToChatArgs: SendMessageToChatArgs;
  SendMessageToUserArgs: SendMessageToUserArgs;
  Settings: ResolverTypeWrapper<Settings>;
  SettingsInput: SettingsInput;
  Sexuality: Sexuality;
  SmokingHabit: SmokingHabit;
  Spotify: ResolverTypeWrapper<Spotify>;
  SpotifyArtist: ResolverTypeWrapper<SpotifyArtist>;
  SpotifyMedia: ResolverTypeWrapper<SpotifyMedia>;
  SpotifyTrack: ResolverTypeWrapper<SpotifyTrack>;
  Spotlight: ResolverTypeWrapper<Spotlight>;
  SpotlightResult: ResolverTypeWrapper<SpotlightResult>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SubmitSurveyArgs: SubmitSurveyArgs;
  Survey: ResolverTypeWrapper<Survey>;
  SurveyAnswer: SurveyAnswer;
  SurveyListItem: ResolverTypeWrapper<SurveyListItem>;
  SurveyQuestion: ResolverTypeWrapper<SurveyQuestion>;
  SurveyQuestionType: SurveyQuestionType;
  SurveyRewardType: SurveyRewardType;
  SyncResult: ResolverTypeWrapper<SyncResult>;
  TATTOO: Tattoo;
  Tiktok: ResolverTypeWrapper<Tiktok>;
  TiktokMedia: ResolverTypeWrapper<TiktokMedia>;
  TranslatedText: ResolverTypeWrapper<TranslatedText>;
  TranslationResult: ResolverTypeWrapper<TranslationResult>;
  UpdateGroupArgs: UpdateGroupArgs;
  UploadField: ResolverTypeWrapper<UploadField>;
  UploadPostInfo: ResolverTypeWrapper<UploadPostInfo>;
  User: ResolverTypeWrapper<User>;
  UserBlock: ResolverTypeWrapper<UserBlock>;
  UserCardData: ResolverTypeWrapper<UserCardData>;
  UserInsights: ResolverTypeWrapper<UserInsights>;
  VerificationType: VerificationType;
  Video: ResolverTypeWrapper<Video>;
  VideoThumbnailUploadArgs: VideoThumbnailUploadArgs;
  VideoUploadArgs: VideoUploadArgs;
  VideoUploadResult: ResolverTypeWrapper<VideoUploadResult>;
  VoicePrompt: ResolverTypeWrapper<VoicePrompt>;
  VoicePromptUploadArgs: VoicePromptUploadArgs;
  VoicePromptUploadResult: ResolverTypeWrapper<VoicePromptUploadResult>;
  Zodiac: Zodiac;
  ZodiacSign: ZodiacSign;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Account: Account;
  Achievement: Achievement;
  AdNetworkResult: AdNetworkResult;
  AddMeetInput: AddMeetInput;
  AllowedReelUploadResult: AllowedReelUploadResult;
  Attachment: Attachment;
  AttachmentArgs: AttachmentArgs;
  BadgesResult: BadgesResult;
  Blocked: Blocked;
  BlurredMatch: BlurredMatch;
  Boolean: Scalars['Boolean']['output'];
  Chat: Chat;
  ChatList: ChatList;
  ChatListItem: ChatListItem;
  Comment: Comment;
  CursorQueryArgs: CursorQueryArgs;
  DateTime: Scalars['DateTime']['output'];
  DeleteArgs: DeleteArgs;
  Destination: Destination;
  DestinationInput: DestinationInput;
  DestinationLocation: DestinationLocation;
  DestinationLocationInput: DestinationLocationInput;
  EmailSubscriptionSettings: EmailSubscriptionSettings;
  EmailSubscriptionSettingsInput: EmailSubscriptionSettingsInput;
  Facebook: Facebook;
  Favorite: Favorite;
  FileUploadArgs: FileUploadArgs;
  FileUploadResult: FileUploadResult;
  Float: Scalars['Float']['output'];
  Follower: Follower;
  FollowersResult: FollowersResult;
  Following: Following;
  Friend: Friend;
  FriendRequest: FriendRequest;
  FriendsData: FriendsData;
  Geo: Geo;
  GeoInput: GeoInput;
  GeocodeResult: GeocodeResult;
  GhostingStatus: GhostingStatus;
  Gift: Gift;
  Google: Google;
  Group: Group;
  GroupAd: GroupAd;
  HappyHour: HappyHour;
  Instagram: Instagram;
  InstagramMedia: InstagramMedia;
  Int: Scalars['Int']['output'];
  Integrations: Integrations;
  IsMatch: IsMatch;
  LastViewedMessagesForChat: LastViewedMessagesForChat;
  LikeStreakInfo: LikeStreakInfo;
  LikesMe: LikesMe;
  ListUserResult: ListUserResult;
  LiveComment: LiveComment;
  LiveStreamData: LiveStreamData;
  Location: Location;
  LocationInput: LocationInput;
  MatchResult: MatchResult;
  MatchingArgs: MatchingArgs;
  MatchingResult: MatchingResult;
  Me: Me;
  MePush: MePush;
  MeSettings: MeSettings;
  Meet: Meet;
  Message: Message;
  MessageAttachment: MessageAttachment;
  MessageAttachmentArgs: MessageAttachmentArgs;
  MessagesSettings: MessagesSettings;
  MessagesSettingsInput: MessagesSettingsInput;
  Mutation: {};
  NearbyPeopleResult: NearbyPeopleResult;
  NewCommentArgs: NewCommentArgs;
  NewCommentResult: NewCommentResult;
  NewGroupArgs: NewGroupArgs;
  NewGroupResult: NewGroupResult;
  NewMessage: NewMessage;
  NewMessages: NewMessages;
  NewMessagesForChat: NewMessagesForChat;
  NewPostArgs: NewPostArgs;
  NewPostResult: NewPostResult;
  NewUsersResult: NewUsersResult;
  News: News;
  NotifMessageReactionData: NotifMessageReactionData;
  Notification: Notification;
  NotificationSetting: NotificationSetting;
  NotificationSettingInput: NotificationSettingInput;
  NotificationSettings: NotificationSettings;
  NotificationSettingsInput: NotificationSettingsInput;
  NotificationsResult: NotificationsResult;
  OffsetQueryArgs: OffsetQueryArgs;
  PageQueryArgs: PageQueryArgs;
  PagedChatList: PagedChatList;
  PagedComments: PagedComments;
  PagedDestinations: PagedDestinations;
  PagedGroups: PagedGroups;
  PagedLiveStreamComments: PagedLiveStreamComments;
  PagedMatches: PagedMatches;
  PagedMeets: PagedMeets;
  PagedMessages: PagedMessages;
  PagedPosts: PagedPosts;
  PagedReactions: PagedReactions;
  PagedReels: PagedReels;
  PagedSurveys: PagedSurveys;
  PagedUsers: PagedUsers;
  PagedYesMatches: PagedYesMatches;
  PagedYesMatchesBlurred: PagedYesMatchesBlurred;
  PagingArgs: PagingArgs;
  PagingResult: PagingResult;
  PeopleFilter: PeopleFilter;
  PeopleFilterInput: PeopleFilterInput;
  Photo: Photo;
  PhotoUploadArgs: PhotoUploadArgs;
  PhotoUploadResult: PhotoUploadResult;
  PhotoVerification: PhotoVerification;
  PlaceAutocomplete: PlaceAutocomplete;
  Post: Post;
  PrivatePhotosAccessResult: PrivatePhotosAccessResult;
  Profile: Profile;
  ProfileInput: ProfileInput;
  ProfileLocation: ProfileLocation;
  ProfileLocationInput: ProfileLocationInput;
  ProfileVisit: ProfileVisit;
  ProfileWork: ProfileWork;
  ProfileWorkInput: ProfileWorkInput;
  PromoReward: PromoReward;
  Query: {};
  Reaction: Reaction;
  Reel: Reel;
  ReelPromotionResult: ReelPromotionResult;
  ReelUploadArgs: ReelUploadArgs;
  ReelUploadResult: ReelUploadResult;
  ReelsForYouArgs: ReelsForYouArgs;
  ReelsForYouResult: ReelsForYouResult;
  Report: Report;
  ReportArgs: ReportArgs;
  SearchUsersResult: SearchUsersResult;
  SendMessageResult: SendMessageResult;
  SendMessageToChatArgs: SendMessageToChatArgs;
  SendMessageToUserArgs: SendMessageToUserArgs;
  Settings: Settings;
  SettingsInput: SettingsInput;
  Spotify: Spotify;
  SpotifyArtist: SpotifyArtist;
  SpotifyMedia: SpotifyMedia;
  SpotifyTrack: SpotifyTrack;
  Spotlight: Spotlight;
  SpotlightResult: SpotlightResult;
  String: Scalars['String']['output'];
  SubmitSurveyArgs: SubmitSurveyArgs;
  Survey: Survey;
  SurveyAnswer: SurveyAnswer;
  SurveyListItem: SurveyListItem;
  SurveyQuestion: SurveyQuestion;
  SyncResult: SyncResult;
  Tiktok: Tiktok;
  TiktokMedia: TiktokMedia;
  TranslatedText: TranslatedText;
  TranslationResult: TranslationResult;
  UpdateGroupArgs: UpdateGroupArgs;
  UploadField: UploadField;
  UploadPostInfo: UploadPostInfo;
  User: User;
  UserBlock: UserBlock;
  UserCardData: UserCardData;
  UserInsights: UserInsights;
  Video: Video;
  VideoThumbnailUploadArgs: VideoThumbnailUploadArgs;
  VideoUploadArgs: VideoUploadArgs;
  VideoUploadResult: VideoUploadResult;
  VoicePrompt: VoicePrompt;
  VoicePromptUploadArgs: VoicePromptUploadArgs;
  VoicePromptUploadResult: VoicePromptUploadResult;
};

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = {
  adFree?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  availableTranslationAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  blockPurchase?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  credits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  premium?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  premiumDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  vip?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AchievementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Achievement'] = ResolversParentTypes['Achievement']> = {
  achievementId?: Resolver<ResolversTypes['AchievementId'], ParentType, ContextType>;
  completed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  rewardClaimedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rewardCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdNetworkResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdNetworkResult'] = ResolversParentTypes['AdNetworkResult']> = {
  adNetwork?: Resolver<ResolversTypes['AdNetwork'], ParentType, ContextType>;
  useAds?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllowedReelUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['AllowedReelUploadResult'] = ResolversParentTypes['AllowedReelUploadResult']> = {
  allowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  needPremium?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  blurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BadgesResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['BadgesResult'] = ResolversParentTypes['BadgesResult']> = {
  unreadMessages?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unreadNotifications?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockedResolvers<ContextType = any, ParentType extends ResolversParentTypes['Blocked'] = ResolversParentTypes['Blocked']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlurredMatchResolvers<ContextType = any, ParentType extends ResolversParentTypes['BlurredMatch'] = ResolversParentTypes['BlurredMatch']> = {
  thumbnailBlurhash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatResolvers<ContextType = any, ParentType extends ResolversParentTypes['Chat'] = ResolversParentTypes['Chat']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  acceptedByIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  allowNew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowNewFrom?: Resolver<Maybe<Array<Maybe<ResolversTypes['NewMessageSetting']>>>, ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  deletedByIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ghostingIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  ghostingStatus?: Resolver<Maybe<ResolversTypes['GhostingStatus']>, ParentType, ContextType>;
  isSnapFlirt?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType>;
  lastMessageId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  messages?: Resolver<Maybe<ResolversTypes['PagedMessages']>, ParentType, ContextType, RequireFields<ChatMessagesArgs, 'input'>>;
  typingUserIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  unlocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  usePushNotification?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  useTranslation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatListResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatList'] = ResolversParentTypes['ChatList']> = {
  items?: Resolver<Maybe<ResolversTypes['PagedChatList']>, ParentType, ContextType, RequireFields<ChatListItemsArgs, 'input'>>;
  totalChats?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChatListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChatListItem'] = ResolversParentTypes['ChatListItem']> = {
  accepted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  chatId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ended?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endedByUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ghostingIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  ghostingStatus?: Resolver<Maybe<ResolversTypes['GhostingStatus']>, ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['Message']>, ParentType, ContextType>;
  members?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  updatedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Attachment']>>>, ParentType, ContextType>;
  byUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  meReaction?: Resolver<Maybe<ResolversTypes['ReactionValue']>, ParentType, ContextType>;
  parentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactionCounts?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  replies?: Resolver<Maybe<ResolversTypes['PagedComments']>, ParentType, ContextType, RequireFields<CommentRepliesArgs, 'input'>>;
  repliesAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  replyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rootId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalReactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DestinationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Destination'] = ResolversParentTypes['Destination']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  audience?: Resolver<Maybe<ResolversTypes['PostAudience']>, ParentType, ContextType>;
  byUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  fromDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['DestinationLocation'], ParentType, ContextType>;
  push?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  toDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DestinationLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DestinationLocation'] = ResolversParentTypes['DestinationLocation']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailSubscriptionSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailSubscriptionSettings'] = ResolversParentTypes['EmailSubscriptionSettings']> = {
  advertisements?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  newsLetter?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  systemNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  weeklyMatches?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  weeklyNewLikes?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  weeklyNewMessages?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FacebookResolvers<ContextType = any, ParentType extends ResolversParentTypes['Facebook'] = ResolversParentTypes['Facebook']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  facebookId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FavoriteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Favorite'] = ResolversParentTypes['Favorite']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileUploadResult'] = ResolversParentTypes['FileUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Follower'] = ResolversParentTypes['Follower']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowersResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['FollowersResult'] = ResolversParentTypes['FollowersResult']> = {
  followers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Follower']>>>, ParentType, ContextType>;
  totalFollowers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FollowingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Following'] = ResolversParentTypes['Following']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FriendResolvers<ContextType = any, ParentType extends ResolversParentTypes['Friend'] = ResolversParentTypes['Friend']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FriendRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['FriendRequest'] = ResolversParentTypes['FriendRequest']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FriendsDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['FriendsData'] = ResolversParentTypes['FriendsData']> = {
  friendRequestsReceived?: Resolver<Maybe<Array<Maybe<ResolversTypes['FriendRequest']>>>, ParentType, ContextType>;
  friendRequestsSent?: Resolver<Maybe<Array<Maybe<ResolversTypes['FriendRequest']>>>, ParentType, ContextType>;
  friends?: Resolver<Maybe<Array<Maybe<ResolversTypes['Friend']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Geo'] = ResolversParentTypes['Geo']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeocodeResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeocodeResult'] = ResolversParentTypes['GeocodeResult']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GhostingStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['GhostingStatus'] = ResolversParentTypes['GhostingStatus']> = {
  receiver?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  you?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Gift'] = ResolversParentTypes['Gift']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  byUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  byUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  claimed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  claimedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  credits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  toUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Google'] = ResolversParentTypes['Google']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  about?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  admins?: Resolver<Maybe<ResolversTypes['PagedUsers']>, ParentType, ContextType, RequireFields<GroupAdminsArgs, 'paging'>>;
  bannedUsers?: Resolver<Maybe<ResolversTypes['PagedUsers']>, ParentType, ContextType, RequireFields<GroupBannedUsersArgs, 'paging'>>;
  canJoin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  genderLimited?: Resolver<Array<ResolversTypes['Gender']>, ParentType, ContextType>;
  hasSentRequest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  inviteLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  inviteLimitDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdminInvited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isBanned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInvited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMember?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxAgeLimited?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  memberCommentsAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  memberCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  memberInvites?: Resolver<Maybe<ResolversTypes['PagedUsers']>, ParentType, ContextType, RequireFields<GroupMemberInvitesArgs, 'paging'>>;
  memberInvitesIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  memberPostsAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  memberRequests?: Resolver<Maybe<ResolversTypes['PagedUsers']>, ParentType, ContextType, RequireFields<GroupMemberRequestsArgs, 'paging'>>;
  memberRequestsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  memberVisibility?: Resolver<ResolversTypes['MemberVisibility'], ParentType, ContextType>;
  members?: Resolver<Maybe<ResolversTypes['PagedUsers']>, ParentType, ContextType, RequireFields<GroupMembersArgs, 'paging'>>;
  minAgeLimited?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  photoApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  privacy?: Resolver<ResolversTypes['GroupPrivacy'], ParentType, ContextType>;
  rules?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['GroupStatus']>, ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['GroupVisibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupAdResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupAd'] = ResolversParentTypes['GroupAd']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  byUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  memberCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  privacy?: Resolver<ResolversTypes['GroupPrivacy'], ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['GroupVisibility'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HappyHourResolvers<ContextType = any, ParentType extends ResolversParentTypes['HappyHour'] = ResolversParentTypes['HappyHour']> = {
  endDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  nextDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  pollDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  startDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstagramResolvers<ContextType = any, ParentType extends ResolversParentTypes['Instagram'] = ResolversParentTypes['Instagram']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instagramUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media?: Resolver<Maybe<Array<ResolversTypes['InstagramMedia']>>, ParentType, ContextType>;
  updatedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InstagramMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['InstagramMedia'] = ResolversParentTypes['InstagramMedia']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['InstagramMediaType'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Integrations'] = ResolversParentTypes['Integrations']> = {
  facebook?: Resolver<Maybe<ResolversTypes['Facebook']>, ParentType, ContextType>;
  google?: Resolver<Maybe<ResolversTypes['Google']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['Instagram']>, ParentType, ContextType>;
  spotify?: Resolver<Maybe<ResolversTypes['Spotify']>, ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['Tiktok']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IsMatchResolvers<ContextType = any, ParentType extends ResolversParentTypes['IsMatch'] = ResolversParentTypes['IsMatch']> = {
  hasVoted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  match?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  meVote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  numberOfVotes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  userHasVoted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userVote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LastViewedMessagesForChatResolvers<ContextType = any, ParentType extends ResolversParentTypes['LastViewedMessagesForChat'] = ResolversParentTypes['LastViewedMessagesForChat']> = {
  lastViewedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LikeStreakInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['LikeStreakInfo'] = ResolversParentTypes['LikeStreakInfo']> = {
  likeStreakCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likeStreakDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  likeStreakDaysMax?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LikesMeResolvers<ContextType = any, ParentType extends ResolversParentTypes['LikesMe'] = ResolversParentTypes['LikesMe']> = {
  date?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  hot?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  vote?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListUserResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ListUserResult'] = ResolversParentTypes['ListUserResult']> = {
  maxDistanceInMeters?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveComment'] = ResolversParentTypes['LiveComment']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  gift?: Resolver<Maybe<ResolversTypes['LiveGift']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['LiveStatusUpdate']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LiveStreamDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['LiveStreamData'] = ResolversParentTypes['LiveStreamData']> = {
  comments?: Resolver<Array<ResolversTypes['LiveComment']>, ParentType, ContextType>;
  currentViewers?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isBanned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastActiveVideoDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  lastPollDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  videoActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MatchResult'] = ResolversParentTypes['MatchResult']> = {
  boostOfferExpireDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hotLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hotLikesDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isMatch?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  streak?: Resolver<ResolversTypes['LikeStreakInfo'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  yesVotesLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MatchingResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['MatchingResult'] = ResolversParentTypes['MatchingResult']> = {
  boostOfferExpireDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hotLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hotLikesDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  streak?: Resolver<ResolversTypes['LikeStreakInfo'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  yesVotesLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Me'] = ResolversParentTypes['Me']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType>;
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  boostExpiresDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  boostOfferExpireDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  boosting?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  chatIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType, Partial<MeChatIdsArgs>>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deactivated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  facebook?: Resolver<Maybe<ResolversTypes['Facebook']>, ParentType, ContextType>;
  freeSpinDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  google?: Resolver<Maybe<ResolversTypes['Google']>, ParentType, ContextType>;
  hideGroupIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  hotLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hotLikesDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['Instagram']>, ParentType, ContextType>;
  invitedMemberCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActive?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  likeStreak?: Resolver<Maybe<ResolversTypes['LikeStreakInfo']>, ParentType, ContextType>;
  locationUpdateDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  matchScore?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moderator?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moodDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  numberOfVotes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  popularity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  privatePhotosAccessIds?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  profilePhotoApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profilePhotoUploadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileVisits?: Resolver<Maybe<Array<ResolversTypes['ProfileVisit']>>, ParentType, ContextType>;
  pushAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  readyToChatDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reelStreakDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reelStreakDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardedAdsAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  showOnlineStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  snapFlirtStreakDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  snapFlirtStreakDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  snapFlirts?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  snapFlirtsDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  spotify?: Resolver<Maybe<ResolversTypes['Spotify']>, ParentType, ContextType>;
  spotlightOfferExpireDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['Tiktok']>, ParentType, ContextType>;
  totalReels?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verifiedPhone?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  votesDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MePushResolvers<ContextType = any, ParentType extends ResolversParentTypes['MePush'] = ResolversParentTypes['MePush']> = {
  deviceToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expoPushToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeSettings'] = ResolversParentTypes['MeSettings']> = {
  freeSpinDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Settings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Meet'] = ResolversParentTypes['Meet']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interestedCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isInterested?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MeetType'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  when?: Resolver<ResolversTypes['MeetWhen'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageAttachment']>>>, ParentType, ContextType>;
  chatId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedByIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  fromId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reactions?: Resolver<Maybe<Array<ResolversTypes['Reaction']>>, ParentType, ContextType>;
  snapFlirt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translated?: Resolver<Maybe<ResolversTypes['TranslatedText']>, ParentType, ContextType>;
  videoCallId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoCallRequest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  viewedByIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageAttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageAttachment'] = ResolversParentTypes['MessageAttachment']> = {
  blurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  deleted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagesSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessagesSettings'] = ResolversParentTypes['MessagesSettings']> = {
  allowNewFrom?: Resolver<Maybe<Array<ResolversTypes['NewMessageSetting']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  acceptFriendRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAcceptFriendRequestArgs, 'friendId'>>;
  acceptGroupInvite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAcceptGroupInviteArgs, 'groupId'>>;
  acceptGroupRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAcceptGroupRequestArgs, 'groupId' | 'userId'>>;
  addBlock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddBlockArgs, 'userId'>>;
  addDestination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddDestinationArgs, 'input'>>;
  addFavorite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddFavoriteArgs, 'userId'>>;
  addFollow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddFollowArgs, 'userId'>>;
  addGroupAd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddGroupAdArgs, 'groupId'>>;
  addLiveComment?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationAddLiveCommentArgs, 'comment' | 'userId'>>;
  addMeet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddMeetArgs, 'input'>>;
  addMessageReaction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddMessageReactionArgs, 'messageId' | 'reaction'>>;
  addPhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddPhoneNumberArgs, 'phoneNumber'>>;
  addPrivatePhotoAccess?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddPrivatePhotoAccessArgs, 'userId'>>;
  addReelComment?: Resolver<Maybe<ResolversTypes['NewCommentResult']>, ParentType, ContextType, RequireFields<MutationAddReelCommentArgs, 'args'>>;
  addSavedReel?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationAddSavedReelArgs, 'reelId'>>;
  addViewingChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationAddViewingChatArgs, 'chatId'>>;
  archiveAllChats?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  archiveChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationArchiveChatArgs, 'chatId'>>;
  banUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationBanUserArgs, 'groupId' | 'userId'>>;
  claimAchievement?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationClaimAchievementArgs, 'achievementId'>>;
  claimGift?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationClaimGiftArgs, 'giftId'>>;
  clearAllNoVotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  connectFacebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationConnectFacebookArgs, 'token'>>;
  connectGoogle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationConnectGoogleArgs, 'token'>>;
  connectInstagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationConnectInstagramArgs, 'code'>>;
  connectSpotify?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationConnectSpotifyArgs, 'code'>>;
  connectTiktok?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationConnectTiktokArgs, 'code'>>;
  deactivate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeactivateArgs, 'value'>>;
  deleteChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteChatArgs, 'chatId'>>;
  deleteComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, 'commentId'>>;
  deleteGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteGroupArgs, 'groupId'>>;
  deleteMatchVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deleteMeet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteMeetArgs, 'meetId'>>;
  deleteMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteMessageArgs, 'messageId'>>;
  deletePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeletePhotoArgs, 'uploadId'>>;
  deletePost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeletePostArgs, 'postId'>>;
  deletePrivatePhoto?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeletePrivatePhotoArgs, 'uploadId'>>;
  deletePrivateVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeletePrivateVideoArgs, 'uploadId'>>;
  deleteReel?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationDeleteReelArgs, 'reelId'>>;
  deleteReelComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteReelCommentArgs, 'commentId'>>;
  deleteVideo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteVideoArgs, 'uploadId'>>;
  deleteVoicePrompt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDeleteVoicePromptArgs, 'uploadId'>>;
  denyFriendRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationDenyFriendRequestArgs, 'friendId'>>;
  disconnectFacebook?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disconnectGoogle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disconnectInstagram?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disconnectSpotify?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disconnectTiktok?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editDestination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationEditDestinationArgs, 'destinationId' | 'input'>>;
  endChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationEndChatArgs, 'chatId' | 'message'>>;
  hideGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationHideGroupArgs, 'groupId'>>;
  hideInstagramMedia?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationHideInstagramMediaArgs, 'instagramMediaId' | 'visible'>>;
  interestedMeet?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationInterestedMeetArgs, 'meetId'>>;
  inviteMember?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationInviteMemberArgs, 'groupId' | 'userId'>>;
  joinGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationJoinGroupArgs, 'groupId'>>;
  kickUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationKickUserArgs, 'groupId' | 'userId'>>;
  leaveGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationLeaveGroupArgs, 'groupId'>>;
  match?: Resolver<Maybe<ResolversTypes['MatchResult']>, ParentType, ContextType, RequireFields<MutationMatchArgs, 'userId' | 'value'>>;
  newComment?: Resolver<Maybe<ResolversTypes['NewCommentResult']>, ParentType, ContextType, RequireFields<MutationNewCommentArgs, 'args'>>;
  newGroup?: Resolver<ResolversTypes['NewGroupResult'], ParentType, ContextType, RequireFields<MutationNewGroupArgs, 'args'>>;
  newPost?: Resolver<Maybe<ResolversTypes['NewPostResult']>, ParentType, ContextType, RequireFields<MutationNewPostArgs, 'args'>>;
  openChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationOpenChatArgs, 'chatId'>>;
  permanentlyDelete?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<MutationPermanentlyDeleteArgs>>;
  reactToComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationReactToCommentArgs, 'commentId' | 'value'>>;
  reactToPost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationReactToPostArgs, 'postId' | 'value'>>;
  reactToReel?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationReactToReelArgs, 'like' | 'reelId'>>;
  readAllNotifications?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readNotification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationReadNotificationArgs, 'notificationId'>>;
  readyToChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshInstagramMedia?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshSpotify?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshTiktokMedia?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rejectGroupInvite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRejectGroupInviteArgs, 'groupId'>>;
  rejectGroupRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRejectGroupRequestArgs, 'groupId' | 'userId'>>;
  removeBlock?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveBlockArgs, 'userId'>>;
  removeDestination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveDestinationArgs, 'destinationId'>>;
  removeFavorite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveFavoriteArgs, 'userId'>>;
  removeFollow?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveFollowArgs, 'userId'>>;
  removeFriend?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveFriendArgs, 'friendId'>>;
  removeFriendRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveFriendRequestArgs, 'friendId'>>;
  removeInvitation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveInvitationArgs, 'groupId' | 'userId'>>;
  removeMessageReaction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveMessageReactionArgs, 'messageId'>>;
  removePrivatePhotoAccess?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemovePrivatePhotoAccessArgs, 'userId'>>;
  removeSavedReel?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRemoveSavedReelArgs, 'reelId'>>;
  removeViewingChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRemoveViewingChatArgs, 'chatId'>>;
  reportGhosting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationReportGhostingArgs, 'chatId' | 'userId'>>;
  reportLiveStream?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationReportLiveStreamArgs, 'userId'>>;
  requestMembership?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationRequestMembershipArgs, 'groupId'>>;
  sendFeedback?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSendFeedbackArgs, 'message' | 'type'>>;
  sendFriendRequest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSendFriendRequestArgs, 'friendId'>>;
  sendGift?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSendGiftArgs, 'credits' | 'toUserId'>>;
  sendLiveGift?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSendLiveGiftArgs, 'gift' | 'userId'>>;
  sendMessageToChat?: Resolver<Maybe<ResolversTypes['SendMessageResult']>, ParentType, ContextType, RequireFields<MutationSendMessageToChatArgs, 'args'>>;
  sendMessageToUser?: Resolver<Maybe<ResolversTypes['SendMessageResult']>, ParentType, ContextType, RequireFields<MutationSendMessageToUserArgs, 'args'>>;
  sendReport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSendReportArgs, 'args'>>;
  sendSnapFlirt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSendSnapFlirtArgs, 'message' | 'userId'>>;
  setGenderToMan?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSetGenderToManArgs, 'userId'>>;
  setLiveBan?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSetLiveBanArgs, 'userId' | 'value'>>;
  setMood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSetMoodArgs, 'emojiString'>>;
  setProfileImage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSetProfileImageArgs, 'uploadId'>>;
  setPushNotification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSetPushNotificationArgs, 'chatId' | 'status'>>;
  setReelPushNotifications?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationSetReelPushNotificationsArgs, 'reelId' | 'value'>>;
  setTranslation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSetTranslationArgs, 'chatId' | 'status'>>;
  showGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationShowGroupArgs, 'groupId'>>;
  submitSurvey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSubmitSurveyArgs, 'args'>>;
  translatePost?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationTranslatePostArgs, 'postId'>>;
  typingInChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationTypingInChatArgs, 'chatId' | 'status'>>;
  unbanUser?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUnbanUserArgs, 'groupId' | 'userId'>>;
  updateEmailSubscriptionSettings?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationUpdateEmailSubscriptionSettingsArgs, 'input'>>;
  updateFilter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUpdateFilterArgs, 'input'>>;
  updateGroup?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUpdateGroupArgs, 'args' | 'groupId'>>;
  updateProfile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, 'profile'>>;
  updatePushTokens?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<MutationUpdatePushTokensArgs>>;
  updateSettings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationUpdateSettingsArgs, 'settings'>>;
  uploadGroupImage?: Resolver<ResolversTypes['FileUploadResult'], ParentType, ContextType, RequireFields<MutationUploadGroupImageArgs, 'args' | 'groupId'>>;
  uploadMatchVideo?: Resolver<Maybe<ResolversTypes['VideoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadMatchVideoArgs, 'args'>>;
  uploadPhoto?: Resolver<Maybe<ResolversTypes['PhotoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadPhotoArgs, 'args'>>;
  uploadPhotoVerification?: Resolver<Maybe<ResolversTypes['PhotoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadPhotoVerificationArgs, 'args'>>;
  uploadPrivatePhoto?: Resolver<Maybe<ResolversTypes['PhotoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadPrivatePhotoArgs, 'args'>>;
  uploadPrivateVideo?: Resolver<Maybe<ResolversTypes['VideoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadPrivateVideoArgs, 'args'>>;
  uploadReel?: Resolver<ResolversTypes['ReelUploadResult'], ParentType, ContextType, RequireFields<MutationUploadReelArgs, 'args'>>;
  uploadVideo?: Resolver<Maybe<ResolversTypes['VideoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadVideoArgs, 'args'>>;
  uploadVideoThumbnail?: Resolver<Maybe<ResolversTypes['PhotoUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadVideoThumbnailArgs, 'args'>>;
  uploadVoicePrompt?: Resolver<Maybe<ResolversTypes['VoicePromptUploadResult']>, ParentType, ContextType, RequireFields<MutationUploadVoicePromptArgs, 'args'>>;
  verifyPhoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationVerifyPhoneNumberArgs, 'code'>>;
  viewAllNotifications?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  viewChat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationViewChatArgs, 'chatId'>>;
  viewMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationViewMessageArgs, 'messageId'>>;
  viewNotification?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationViewNotificationArgs, 'notificationId'>>;
  viewReel?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationViewReelArgs, 'reelId'>>;
  visitProfile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationVisitProfileArgs, 'userId'>>;
};

export type NearbyPeopleResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NearbyPeopleResult'] = ResolversParentTypes['NearbyPeopleResult']> = {
  maxDistanceInMeters?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewCommentResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewCommentResult'] = ResolversParentTypes['NewCommentResult']> = {
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['UploadPostInfo']>>>, ParentType, ContextType>;
  newCommentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewGroupResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewGroupResult'] = ResolversParentTypes['NewGroupResult']> = {
  groupId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewMessage'] = ResolversParentTypes['NewMessage']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chatId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  clientId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewMessages'] = ResolversParentTypes['NewMessages']> = {
  nodes?: Resolver<Array<ResolversTypes['NewMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewMessagesForChatResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewMessagesForChat'] = ResolversParentTypes['NewMessagesForChat']> = {
  availableTranslationAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastViewed?: Resolver<Array<ResolversTypes['LastViewedMessagesForChat']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType>;
  typingUserIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewPostResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewPostResult'] = ResolversParentTypes['NewPostResult']> = {
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['UploadPostInfo']>>>, ParentType, ContextType>;
  newPostId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewUsersResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NewUsersResult'] = ResolversParentTypes['NewUsersResult']> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NewsResolvers<ContextType = any, ParentType extends ResolversParentTypes['News'] = ResolversParentTypes['News']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotifMessageReactionDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotifMessageReactionData'] = ResolversParentTypes['NotifMessageReactionData']> = {
  messageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reaction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  actionUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageReaction?: Resolver<Maybe<ResolversTypes['NotifMessageReactionData']>, ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  viewed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationSettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationSetting'] = ResolversParentTypes['NotificationSetting']> = {
  email?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  push?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sms?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationSettings'] = ResolversParentTypes['NotificationSettings']> = {
  advertisements?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  comments?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  dailySpinReminder?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  friendRequests?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  invites?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  matches?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  newMessages?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  newPosts?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  replies?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  signupNotifications?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  updatesFollowing?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  updatesFriends?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  updatesGroups?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  updatesMatches?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  updatesOnline?: Resolver<ResolversTypes['NotificationSetting'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationsResult'] = ResolversParentTypes['NotificationsResult']> = {
  nodes?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType>;
  totalUnviewed?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedChatListResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedChatList'] = ResolversParentTypes['PagedChatList']> = {
  nodes?: Resolver<Array<ResolversTypes['ChatListItem']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedCommentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedComments'] = ResolversParentTypes['PagedComments']> = {
  nodes?: Resolver<Array<ResolversTypes['Comment']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedDestinationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedDestinations'] = ResolversParentTypes['PagedDestinations']> = {
  nodes?: Resolver<Array<ResolversTypes['Destination']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedGroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedGroups'] = ResolversParentTypes['PagedGroups']> = {
  nodes?: Resolver<Array<ResolversTypes['Group']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedLiveStreamCommentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedLiveStreamComments'] = ResolversParentTypes['PagedLiveStreamComments']> = {
  nodes?: Resolver<Array<ResolversTypes['LiveComment']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedMatchesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedMatches'] = ResolversParentTypes['PagedMatches']> = {
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedMeetsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedMeets'] = ResolversParentTypes['PagedMeets']> = {
  nodes?: Resolver<Array<ResolversTypes['Meet']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedMessagesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedMessages'] = ResolversParentTypes['PagedMessages']> = {
  nodes?: Resolver<Array<ResolversTypes['Message']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedPostsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedPosts'] = ResolversParentTypes['PagedPosts']> = {
  nodes?: Resolver<Array<ResolversTypes['Post']>, ParentType, ContextType>;
  paging?: Resolver<Maybe<ResolversTypes['PagingResult']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedReactionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedReactions'] = ResolversParentTypes['PagedReactions']> = {
  nodes?: Resolver<Array<ResolversTypes['Reaction']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedReelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedReels'] = ResolversParentTypes['PagedReels']> = {
  nodes?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedSurveysResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedSurveys'] = ResolversParentTypes['PagedSurveys']> = {
  nodes?: Resolver<Array<ResolversTypes['SurveyListItem']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedUsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedUsers'] = ResolversParentTypes['PagedUsers']> = {
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedYesMatchesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedYesMatches'] = ResolversParentTypes['PagedYesMatches']> = {
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagedYesMatchesBlurredResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagedYesMatchesBlurred'] = ResolversParentTypes['PagedYesMatchesBlurred']> = {
  nodes?: Resolver<Array<ResolversTypes['BlurredMatch']>, ParentType, ContextType>;
  paging?: Resolver<ResolversTypes['PagingResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PagingResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PagingResult'] = ResolversParentTypes['PagingResult']> = {
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPrevPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pagingCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  prevPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PeopleFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['PeopleFilter'] = ResolversParentTypes['PeopleFilter']> = {
  alcohol?: Resolver<Maybe<Array<ResolversTypes['AlcoholHabit']>>, ParentType, ContextType>;
  beard?: Resolver<Maybe<Array<ResolversTypes['Beard']>>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  etnicity?: Resolver<Maybe<Array<ResolversTypes['Etnicity']>>, ParentType, ContextType>;
  eyeColor?: Resolver<Maybe<Array<ResolversTypes['EyeColor']>>, ParentType, ContextType>;
  genders?: Resolver<Maybe<Array<ResolversTypes['Gender']>>, ParentType, ContextType>;
  hairColor?: Resolver<Maybe<Array<ResolversTypes['HairColor']>>, ParentType, ContextType>;
  hereTo?: Resolver<Maybe<Array<ResolversTypes['HereTo']>>, ParentType, ContextType>;
  kids?: Resolver<Maybe<Array<ResolversTypes['KidsStatus']>>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<ResolversTypes['Language']>>, ParentType, ContextType>;
  maxAge?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minAge?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minLength?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  relationshipStatus?: Resolver<Maybe<Array<ResolversTypes['RelationshipStatus']>>, ParentType, ContextType>;
  religion?: Resolver<Maybe<Array<ResolversTypes['Religion']>>, ParentType, ContextType>;
  sexuality?: Resolver<Maybe<Array<ResolversTypes['Sexuality']>>, ParentType, ContextType>;
  showVerifiedOnly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  smoking?: Resolver<Maybe<Array<ResolversTypes['SmokingHabit']>>, ParentType, ContextType>;
  tattoo?: Resolver<Maybe<Array<ResolversTypes['TATTOO']>>, ParentType, ContextType>;
  zodiac?: Resolver<Maybe<Array<ResolversTypes['Zodiac']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Photo'] = ResolversParentTypes['Photo']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  blurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhotoUploadResult'] = ResolversParentTypes['PhotoUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PhotoVerificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['PhotoVerification'] = ResolversParentTypes['PhotoVerification']> = {
  photo?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['PhotoVerificationStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaceAutocompleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlaceAutocomplete'] = ResolversParentTypes['PlaceAutocomplete']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PostResolvers<ContextType = any, ParentType extends ResolversParentTypes['Post'] = ResolversParentTypes['Post']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attachments?: Resolver<Maybe<Array<ResolversTypes['Attachment']>>, ParentType, ContextType>;
  audience?: Resolver<ResolversTypes['PostAudience'], ParentType, ContextType>;
  byUserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Maybe<ResolversTypes['PagedComments']>, ParentType, ContextType, RequireFields<PostCommentsArgs, 'input'>>;
  commentsAllowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  commentsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupPhoto?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  meReaction?: Resolver<Maybe<ResolversTypes['ReactionValue']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reactionCounts?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  reviewStatus?: Resolver<ResolversTypes['PostReviewStatus'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalReactions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivatePhotosAccessResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrivatePhotosAccessResult'] = ResolversParentTypes['PrivatePhotosAccessResult']> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alcohol?: Resolver<Maybe<ResolversTypes['AlcoholHabit']>, ParentType, ContextType>;
  beard?: Resolver<Maybe<ResolversTypes['Beard']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  birthDateChanged?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  describeYourself?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etnicity?: Resolver<Maybe<ResolversTypes['Etnicity']>, ParentType, ContextType>;
  eyeColor?: Resolver<Maybe<ResolversTypes['EyeColor']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  hairColor?: Resolver<Maybe<ResolversTypes['HairColor']>, ParentType, ContextType>;
  hereTo?: Resolver<Maybe<ResolversTypes['HereTo']>, ParentType, ContextType>;
  interests?: Resolver<Maybe<Array<ResolversTypes['Interest']>>, ParentType, ContextType>;
  kids?: Resolver<Maybe<ResolversTypes['KidsStatus']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<ResolversTypes['Language']>>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  living?: Resolver<Maybe<ResolversTypes['LIVING']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['ProfileLocation']>, ParentType, ContextType>;
  lookingFor?: Resolver<Maybe<Array<ResolversTypes['Gender']>>, ParentType, ContextType>;
  mood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  personality?: Resolver<Maybe<Array<ResolversTypes['PersonalityTrait']>>, ParentType, ContextType>;
  relationshipStatus?: Resolver<Maybe<ResolversTypes['RelationshipStatus']>, ParentType, ContextType>;
  religion?: Resolver<Maybe<ResolversTypes['Religion']>, ParentType, ContextType>;
  sexuality?: Resolver<Maybe<ResolversTypes['Sexuality']>, ParentType, ContextType>;
  smoking?: Resolver<Maybe<ResolversTypes['SmokingHabit']>, ParentType, ContextType>;
  tattoo?: Resolver<Maybe<ResolversTypes['TATTOO']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  work?: Resolver<Maybe<ResolversTypes['ProfileWork']>, ParentType, ContextType>;
  zodiac?: Resolver<Maybe<ResolversTypes['Zodiac']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileLocation'] = ResolversParentTypes['ProfileLocation']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  geo?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileVisitResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileVisit'] = ResolversParentTypes['ProfileVisit']> = {
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  when?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileWorkResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProfileWork'] = ResolversParentTypes['ProfileWork']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  education?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PromoRewardResolvers<ContextType = any, ParentType extends ResolversParentTypes['PromoReward'] = ResolversParentTypes['PromoReward']> = {
  rewardCredits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardPremiumDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  achievements?: Resolver<Array<ResolversTypes['Achievement']>, ParentType, ContextType>;
  activeContacts?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  activeFriends?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  activePeopleVip?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryActivePeopleVipArgs, 'paging'>>;
  chat?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType, RequireFields<QueryChatArgs, 'chatId'>>;
  chatForUser?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType, RequireFields<QueryChatForUserArgs, 'userId'>>;
  chats?: Resolver<Maybe<ResolversTypes['ChatList']>, ParentType, ContextType>;
  chattyMembersVip?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryChattyMembersVipArgs, 'paging'>>;
  checkAllowedReelUpload?: Resolver<ResolversTypes['AllowedReelUploadResult'], ParentType, ContextType>;
  currentAppVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType, Partial<QueryCurrentAppVersionArgs>>;
  currentLiveViewers?: Resolver<ResolversTypes['PagedUsers'], ParentType, ContextType, RequireFields<QueryCurrentLiveViewersArgs, 'paging' | 'userId'>>;
  destinations?: Resolver<Maybe<ResolversTypes['PagedDestinations']>, ParentType, ContextType, RequireFields<QueryDestinationsArgs, 'paging'>>;
  emailSubscriptionSettings?: Resolver<ResolversTypes['EmailSubscriptionSettings'], ParentType, ContextType, RequireFields<QueryEmailSubscriptionSettingsArgs, 'emailUuid'>>;
  fetchBadges?: Resolver<Maybe<ResolversTypes['BadgesResult']>, ParentType, ContextType>;
  fetchUpdates?: Resolver<Maybe<ResolversTypes['SyncResult']>, ParentType, ContextType, Partial<QueryFetchUpdatesArgs>>;
  followers?: Resolver<Maybe<ResolversTypes['FollowersResult']>, ParentType, ContextType, Partial<QueryFollowersArgs>>;
  freePremiumAvailable?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  freeSpinJackpot?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  getAdNetwork?: Resolver<ResolversTypes['AdNetworkResult'], ParentType, ContextType>;
  getAiCoaches?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryGetAiCoachesArgs, 'paging'>>;
  getComment?: Resolver<Maybe<ResolversTypes['Comment']>, ParentType, ContextType, RequireFields<QueryGetCommentArgs, 'commentId'>>;
  getGroup?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<QueryGetGroupArgs, 'groupId'>>;
  getHappyHour?: Resolver<ResolversTypes['HappyHour'], ParentType, ContextType>;
  getLiveBannedUsers?: Resolver<ResolversTypes['PagedUsers'], ParentType, ContextType, RequireFields<QueryGetLiveBannedUsersArgs, 'paging'>>;
  getLiveStreamComments?: Resolver<ResolversTypes['PagedLiveStreamComments'], ParentType, ContextType, RequireFields<QueryGetLiveStreamCommentsArgs, 'paging'>>;
  getLiveStreamData?: Resolver<ResolversTypes['LiveStreamData'], ParentType, ContextType, RequireFields<QueryGetLiveStreamDataArgs, 'lastPollDate' | 'userId'>>;
  getLiveStreamingUsers?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryGetLiveStreamingUsersArgs, 'limit'>>;
  getMeets?: Resolver<Maybe<ResolversTypes['PagedMeets']>, ParentType, ContextType, RequireFields<QueryGetMeetsArgs, 'input'>>;
  getPost?: Resolver<Maybe<ResolversTypes['Post']>, ParentType, ContextType, RequireFields<QueryGetPostArgs, 'postId'>>;
  getReactions?: Resolver<ResolversTypes['PagedReactions'], ParentType, ContextType, RequireFields<QueryGetReactionsArgs, 'paging' | 'parentId' | 'type'>>;
  getSnapFlirt?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getTranslatedText?: Resolver<Maybe<ResolversTypes['TranslationResult']>, ParentType, ContextType, RequireFields<QueryGetTranslatedTextArgs, 'messageId'>>;
  giftsBadge?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  groupAds?: Resolver<Array<ResolversTypes['GroupAd']>, ParentType, ContextType>;
  groupInvites?: Resolver<ResolversTypes['PagedGroups'], ParentType, ContextType, RequireFields<QueryGroupInvitesArgs, 'paging'>>;
  groups?: Resolver<ResolversTypes['PagedGroups'], ParentType, ContextType, RequireFields<QueryGroupsArgs, 'paging'>>;
  interestedMeetUsers?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryInterestedMeetUsersArgs, 'meetId' | 'paging'>>;
  isMatch?: Resolver<Maybe<ResolversTypes['IsMatch']>, ParentType, ContextType, RequireFields<QueryIsMatchArgs, 'userId'>>;
  joinedGroups?: Resolver<ResolversTypes['PagedGroups'], ParentType, ContextType, RequireFields<QueryJoinedGroupsArgs, 'paging'>>;
  likesYouCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  managedGroups?: Resolver<ResolversTypes['PagedGroups'], ParentType, ContextType, RequireFields<QueryManagedGroupsArgs, 'paging'>>;
  matches?: Resolver<Maybe<ResolversTypes['PagedMatches']>, ParentType, ContextType, RequireFields<QueryMatchesArgs, 'input'>>;
  matching?: Resolver<Maybe<ResolversTypes['MatchingResult']>, ParentType, ContextType, RequireFields<QueryMatchingArgs, 'input'>>;
  matchingMembersVip?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMatchingMembersVipArgs, 'paging'>>;
  me?: Resolver<Maybe<ResolversTypes['Me']>, ParentType, ContextType, Partial<QueryMeArgs>>;
  meBlocks?: Resolver<Maybe<Array<Maybe<ResolversTypes['Blocked']>>>, ParentType, ContextType>;
  meFavorites?: Resolver<Maybe<Array<Maybe<ResolversTypes['Favorite']>>>, ParentType, ContextType>;
  meFilter?: Resolver<Maybe<ResolversTypes['PeopleFilter']>, ParentType, ContextType>;
  meFollowing?: Resolver<Maybe<Array<Maybe<ResolversTypes['Following']>>>, ParentType, ContextType>;
  meFriends?: Resolver<Maybe<ResolversTypes['FriendsData']>, ParentType, ContextType>;
  meIntegrations?: Resolver<ResolversTypes['Integrations'], ParentType, ContextType>;
  meMatchVideo?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType>;
  mePhotoVerification?: Resolver<Maybe<ResolversTypes['PhotoVerification']>, ParentType, ContextType>;
  mePhotos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType>;
  mePrivatePhotos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType>;
  mePrivateVideos?: Resolver<Maybe<Array<ResolversTypes['Video']>>, ParentType, ContextType>;
  meProfile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  mePush?: Resolver<Maybe<ResolversTypes['MePush']>, ParentType, ContextType>;
  meSettings?: Resolver<Maybe<ResolversTypes['MeSettings']>, ParentType, ContextType>;
  meVideos?: Resolver<Maybe<Array<ResolversTypes['Video']>>, ParentType, ContextType>;
  meVoicePrompts?: Resolver<Maybe<Array<ResolversTypes['VoicePrompt']>>, ParentType, ContextType>;
  membersWithMatchVideo?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMembersWithMatchVideoArgs, 'paging'>>;
  membersWithPrivatePhotos?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMembersWithPrivatePhotosArgs, 'paging'>>;
  membersWithPrivateVideos?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMembersWithPrivateVideosArgs, 'paging'>>;
  membersWithTravelPlans?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMembersWithTravelPlansArgs, 'paging'>>;
  membersWithVerifiedPhoto?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMembersWithVerifiedPhotoArgs, 'paging'>>;
  membersWithVoiceRecordings?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryMembersWithVoiceRecordingsArgs, 'paging'>>;
  messageAttachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['MessageAttachment']>>>, ParentType, ContextType, RequireFields<QueryMessageAttachmentsArgs, 'messageId'>>;
  nearbyPeople?: Resolver<Maybe<ResolversTypes['NearbyPeopleResult']>, ParentType, ContextType, RequireFields<QueryNearbyPeopleArgs, 'paging'>>;
  negativeVotePeople?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryNegativeVotePeopleArgs, 'paging'>>;
  newMessages?: Resolver<Maybe<ResolversTypes['NewMessages']>, ParentType, ContextType>;
  newMessagesForChat?: Resolver<Maybe<ResolversTypes['NewMessagesForChat']>, ParentType, ContextType, RequireFields<QueryNewMessagesForChatArgs, 'chatId' | 'lastMessageDate'>>;
  newPeopleVip?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryNewPeopleVipArgs, 'paging'>>;
  newUsers?: Resolver<Maybe<ResolversTypes['SearchUsersResult']>, ParentType, ContextType>;
  news?: Resolver<Array<Maybe<ResolversTypes['News']>>, ParentType, ContextType, RequireFields<QueryNewsArgs, 'limit'>>;
  notifications?: Resolver<Maybe<ResolversTypes['NotificationsResult']>, ParentType, ContextType, Partial<QueryNotificationsArgs>>;
  pagedReels?: Resolver<ResolversTypes['PagedReels'], ParentType, ContextType, RequireFields<QueryPagedReelsArgs, 'paging'>>;
  pagedSurveys?: Resolver<ResolversTypes['PagedSurveys'], ParentType, ContextType, RequireFields<QueryPagedSurveysArgs, 'paging'>>;
  peopleAtDestination?: Resolver<Maybe<ResolversTypes['NearbyPeopleResult']>, ParentType, ContextType, RequireFields<QueryPeopleAtDestinationArgs, 'destinationId' | 'paging'>>;
  peopleReadyToChat?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryPeopleReadyToChatArgs, 'amount'>>;
  peopleSeeking?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryPeopleSeekingArgs, 'paging'>>;
  peopleVisitingDestination?: Resolver<Maybe<ResolversTypes['NearbyPeopleResult']>, ParentType, ContextType, RequireFields<QueryPeopleVisitingDestinationArgs, 'destinationId' | 'paging'>>;
  peopleWithInterest?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryPeopleWithInterestArgs, 'interest' | 'paging'>>;
  peopleWithZodiac?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryPeopleWithZodiacArgs, 'paging' | 'zodiac'>>;
  photo?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType, RequireFields<QueryPhotoArgs, 'uploadId'>>;
  placeAutocomplete?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlaceAutocomplete']>>>, ParentType, ContextType, RequireFields<QueryPlaceAutocompleteArgs, 'text'>>;
  popularPeople?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  popularPeopleVip?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryPopularPeopleVipArgs, 'paging'>>;
  postsFeed?: Resolver<ResolversTypes['PagedPosts'], ParentType, ContextType, RequireFields<QueryPostsFeedArgs, 'audience' | 'paging'>>;
  privatePhotosAccessUsers?: Resolver<Maybe<ResolversTypes['PrivatePhotosAccessResult']>, ParentType, ContextType>;
  profileVisitors?: Resolver<Array<ResolversTypes['ProfileVisit']>, ParentType, ContextType>;
  promotedReels?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType, Partial<QueryPromotedReelsArgs>>;
  receivedGifts?: Resolver<Maybe<Array<ResolversTypes['Gift']>>, ParentType, ContextType>;
  recentActive?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  recentLogins?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  reel?: Resolver<ResolversTypes['Reel'], ParentType, ContextType, RequireFields<QueryReelArgs, 'reelId'>>;
  reelComments?: Resolver<ResolversTypes['PagedComments'], ParentType, ContextType, RequireFields<QueryReelCommentsArgs, 'paging' | 'reelId'>>;
  reelPromotion?: Resolver<ResolversTypes['ReelPromotionResult'], ParentType, ContextType, RequireFields<QueryReelPromotionArgs, 'reelId'>>;
  reels?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType, RequireFields<QueryReelsArgs, 'limit'>>;
  reelsByUser?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType, RequireFields<QueryReelsByUserArgs, 'limit' | 'userId'>>;
  reelsForYou?: Resolver<ResolversTypes['ReelsForYouResult'], ParentType, ContextType, RequireFields<QueryReelsForYouArgs, 'args'>>;
  reelsInGroup?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType, RequireFields<QueryReelsInGroupArgs, 'groupId' | 'limit'>>;
  reverseGeocode?: Resolver<Maybe<ResolversTypes['GeocodeResult']>, ParentType, ContextType, RequireFields<QueryReverseGeocodeArgs, 'lat' | 'lng'>>;
  searchUsers?: Resolver<Maybe<ResolversTypes['SearchUsersResult']>, ParentType, ContextType, Partial<QuerySearchUsersArgs>>;
  sentGifts?: Resolver<Maybe<Array<ResolversTypes['Gift']>>, ParentType, ContextType>;
  spotlight?: Resolver<Maybe<ResolversTypes['SearchUsersResult']>, ParentType, ContextType, RequireFields<QuerySpotlightArgs, 'amount'>>;
  spotlights?: Resolver<ResolversTypes['SpotlightResult'], ParentType, ContextType, RequireFields<QuerySpotlightsArgs, 'amount'>>;
  survey?: Resolver<Maybe<ResolversTypes['Survey']>, ParentType, ContextType, RequireFields<QuerySurveyArgs, 'surveyId'>>;
  topPicks?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryTopPicksArgs, 'paging'>>;
  topRated?: Resolver<Maybe<ResolversTypes['ListUserResult']>, ParentType, ContextType, RequireFields<QueryTopRatedArgs, 'paging'>>;
  unhandledMatches?: Resolver<Maybe<ResolversTypes['PagedMatches']>, ParentType, ContextType, RequireFields<QueryUnhandledMatchesArgs, 'input'>>;
  unhandledMatchesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'username'>>;
  userCanReply?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryUserCanReplyArgs, 'chatId' | 'userId'>>;
  userInsights?: Resolver<Maybe<ResolversTypes['UserInsights']>, ParentType, ContextType, RequireFields<QueryUserInsightsArgs, 'username'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryUsersArgs>>;
  video?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType, RequireFields<QueryVideoArgs, 'uploadId'>>;
  yesMatches?: Resolver<Maybe<ResolversTypes['PagedYesMatches']>, ParentType, ContextType, RequireFields<QueryYesMatchesArgs, 'input'>>;
  yesMatchesBlurred?: Resolver<Maybe<ResolversTypes['PagedYesMatchesBlurred']>, ParentType, ContextType, RequireFields<QueryYesMatchesBlurredArgs, 'input'>>;
  yesVotesLeft?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type ReactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reaction'] = ResolversParentTypes['Reaction']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  reaction?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ReactionType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reel'] = ResolversParentTypes['Reel']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bookmarkCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  commentsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  explicit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  meLiked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  promoteDiscount?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushNotifications?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailBlurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalLikes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReelPromotionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReelPromotionResult'] = ResolversParentTypes['ReelPromotionResult']> = {
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  promoted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReelUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReelUploadResult'] = ResolversParentTypes['ReelUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReelsForYouResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReelsForYouResult'] = ResolversParentTypes['ReelsForYouResult']> = {
  reels?: Resolver<Array<ResolversTypes['Reel']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  byUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  problem?: Resolver<Maybe<ResolversTypes['ReportProblem']>, ParentType, ContextType>;
  resolved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  resolvedByEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resolvedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ReportType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchUsersResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchUsersResult'] = ResolversParentTypes['SearchUsersResult']> = {
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendMessageResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendMessageResult'] = ResolversParentTypes['SendMessageResult']> = {
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['UploadPostInfo']>>>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['Message'], ParentType, ContextType>;
  newMessageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Settings'] = ResolversParentTypes['Settings']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allowBlindDateInvites?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  allowFriendRequests?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  allowGroupInvites?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  filterReels?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hideLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  incognito?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messages?: Resolver<Maybe<ResolversTypes['MessagesSettings']>, ParentType, ContextType>;
  notifications?: Resolver<Maybe<ResolversTypes['NotificationSettings']>, ParentType, ContextType>;
  showOnlineStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Spotify'] = ResolversParentTypes['Spotify']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['SpotifyMedia']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyArtistResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyArtist'] = ResolversParentTypes['SpotifyArtist']> = {
  external_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  popularity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyMedia'] = ResolversParentTypes['SpotifyMedia']> = {
  recent_track?: Resolver<Maybe<ResolversTypes['SpotifyTrack']>, ParentType, ContextType>;
  top_artists?: Resolver<Maybe<Array<ResolversTypes['SpotifyArtist']>>, ParentType, ContextType>;
  top_tracks?: Resolver<Maybe<Array<ResolversTypes['SpotifyTrack']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotifyTrackResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotifyTrack'] = ResolversParentTypes['SpotifyTrack']> = {
  artist?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  external_url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  genres?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  played_at?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotlightResolvers<ContextType = any, ParentType extends ResolversParentTypes['Spotlight'] = ResolversParentTypes['Spotlight']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  photo?: Resolver<ResolversTypes['Photo'], ParentType, ContextType>;
  reel?: Resolver<Maybe<ResolversTypes['Reel']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpotlightResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SpotlightResult'] = ResolversParentTypes['SpotlightResult']> = {
  nodes?: Resolver<Array<ResolversTypes['Spotlight']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SurveyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Survey'] = ResolversParentTypes['Survey']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questions?: Resolver<Array<ResolversTypes['SurveyQuestion']>, ParentType, ContextType>;
  rewardAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardType?: Resolver<ResolversTypes['SurveyRewardType'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SurveyListItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['SurveyListItem'] = ResolversParentTypes['SurveyListItem']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfQuestions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardType?: Resolver<ResolversTypes['SurveyRewardType'], ParentType, ContextType>;
  submitted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SurveyQuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SurveyQuestion'] = ResolversParentTypes['SurveyQuestion']> = {
  choices?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timer?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SurveyQuestionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SyncResult'] = ResolversParentTypes['SyncResult']> = {
  newMessages?: Resolver<ResolversTypes['NewMessages'], ParentType, ContextType>;
  notifications?: Resolver<ResolversTypes['NotificationsResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TiktokResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tiktok'] = ResolversParentTypes['Tiktok']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  media?: Resolver<Maybe<Array<ResolversTypes['TiktokMedia']>>, ParentType, ContextType>;
  updatedDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TiktokMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['TiktokMedia'] = ResolversParentTypes['TiktokMedia']> = {
  cover_image_url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  embed_link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslatedTextResolvers<ContextType = any, ParentType extends ResolversParentTypes['TranslatedText'] = ResolversParentTypes['TranslatedText']> = {
  toLang?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  translated?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TranslationResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['TranslationResult'] = ResolversParentTypes['TranslationResult']> = {
  availableTranslationAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  translatedText?: Resolver<ResolversTypes['TranslatedText'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadField'] = ResolversParentTypes['UploadField']> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UploadPostInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['UploadPostInfo'] = ResolversParentTypes['UploadPostInfo']> = {
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['UploadField']>>>, ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  admin?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  aiCoach?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  blocks?: Resolver<Maybe<Array<ResolversTypes['UserBlock']>>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deactivated?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  deleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ghostingChats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  hasReceivedFriendRequest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasSentFriendRequest?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  instagram?: Resolver<Maybe<ResolversTypes['Instagram']>, ParentType, ContextType>;
  isBlocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFavorite?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFollowing?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isFriend?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isMatch?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isNew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isPopular?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastActive?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  latestReel?: Resolver<Maybe<ResolversTypes['Reel']>, ParentType, ContextType>;
  latestReelUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  likesMe?: Resolver<Maybe<ResolversTypes['LikesMe']>, ParentType, ContextType>;
  loginDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  matchVideo?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType>;
  matchesFilter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moodDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  onlineToday?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  photos?: Resolver<Maybe<Array<ResolversTypes['Photo']>>, ParentType, ContextType>;
  popularity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  premium?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  profilePhoto?: Resolver<Maybe<ResolversTypes['Photo']>, ParentType, ContextType, Partial<UserProfilePhotoArgs>>;
  profilePhotoApproved?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profilePhotoUploadId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  readyToChatDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  sendingLive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  showOnlineStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  spotify?: Resolver<Maybe<ResolversTypes['Spotify']>, ParentType, ContextType>;
  tiktok?: Resolver<Maybe<ResolversTypes['Tiktok']>, ParentType, ContextType>;
  totalReels?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  travelPlans?: Resolver<Maybe<Array<ResolversTypes['Destination']>>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verifications?: Resolver<Maybe<Array<ResolversTypes['VerificationType']>>, ParentType, ContextType>;
  verifiedPhoto?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<ResolversTypes['Video']>>, ParentType, ContextType>;
  voicePrompts?: Resolver<Maybe<Array<ResolversTypes['VoicePrompt']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserBlockResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserBlock'] = ResolversParentTypes['UserBlock']> = {
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCardDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCardData'] = ResolversParentTypes['UserCardData']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  blurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  coordinates?: Resolver<Maybe<ResolversTypes['Geo']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isOnline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendingLive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserInsightsResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserInsights'] = ResolversParentTypes['UserInsights']> = {
  chatsStartedByUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatsUserHasNotRepliedTo?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatsUserIsBeingGhosted?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  chatsUserIsGhosting?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  followers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  friends?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  peopleBlocked?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  peopleBlockedUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  premium?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  profileVisits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalChats?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalMatches?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalVotesByUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalVotesForUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalVotesYesByUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  vip?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoResolvers<ContextType = any, ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video']> = {
  _id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  approved?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  thumbnailBlurhash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VideoUploadResult'] = ResolversParentTypes['VideoUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoicePromptResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoicePrompt'] = ResolversParentTypes['VoicePrompt']> = {
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadComplete?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoicePromptUploadResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoicePromptUploadResult'] = ResolversParentTypes['VoicePromptUploadResult']> = {
  uploadId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uploadPostInfo?: Resolver<ResolversTypes['UploadPostInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Account?: AccountResolvers<ContextType>;
  Achievement?: AchievementResolvers<ContextType>;
  AdNetworkResult?: AdNetworkResultResolvers<ContextType>;
  AllowedReelUploadResult?: AllowedReelUploadResultResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  BadgesResult?: BadgesResultResolvers<ContextType>;
  Blocked?: BlockedResolvers<ContextType>;
  BlurredMatch?: BlurredMatchResolvers<ContextType>;
  Chat?: ChatResolvers<ContextType>;
  ChatList?: ChatListResolvers<ContextType>;
  ChatListItem?: ChatListItemResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Destination?: DestinationResolvers<ContextType>;
  DestinationLocation?: DestinationLocationResolvers<ContextType>;
  EmailSubscriptionSettings?: EmailSubscriptionSettingsResolvers<ContextType>;
  Facebook?: FacebookResolvers<ContextType>;
  Favorite?: FavoriteResolvers<ContextType>;
  FileUploadResult?: FileUploadResultResolvers<ContextType>;
  Follower?: FollowerResolvers<ContextType>;
  FollowersResult?: FollowersResultResolvers<ContextType>;
  Following?: FollowingResolvers<ContextType>;
  Friend?: FriendResolvers<ContextType>;
  FriendRequest?: FriendRequestResolvers<ContextType>;
  FriendsData?: FriendsDataResolvers<ContextType>;
  Geo?: GeoResolvers<ContextType>;
  GeocodeResult?: GeocodeResultResolvers<ContextType>;
  GhostingStatus?: GhostingStatusResolvers<ContextType>;
  Gift?: GiftResolvers<ContextType>;
  Google?: GoogleResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupAd?: GroupAdResolvers<ContextType>;
  HappyHour?: HappyHourResolvers<ContextType>;
  Instagram?: InstagramResolvers<ContextType>;
  InstagramMedia?: InstagramMediaResolvers<ContextType>;
  Integrations?: IntegrationsResolvers<ContextType>;
  IsMatch?: IsMatchResolvers<ContextType>;
  LastViewedMessagesForChat?: LastViewedMessagesForChatResolvers<ContextType>;
  LikeStreakInfo?: LikeStreakInfoResolvers<ContextType>;
  LikesMe?: LikesMeResolvers<ContextType>;
  ListUserResult?: ListUserResultResolvers<ContextType>;
  LiveComment?: LiveCommentResolvers<ContextType>;
  LiveStreamData?: LiveStreamDataResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  MatchResult?: MatchResultResolvers<ContextType>;
  MatchingResult?: MatchingResultResolvers<ContextType>;
  Me?: MeResolvers<ContextType>;
  MePush?: MePushResolvers<ContextType>;
  MeSettings?: MeSettingsResolvers<ContextType>;
  Meet?: MeetResolvers<ContextType>;
  Message?: MessageResolvers<ContextType>;
  MessageAttachment?: MessageAttachmentResolvers<ContextType>;
  MessagesSettings?: MessagesSettingsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NearbyPeopleResult?: NearbyPeopleResultResolvers<ContextType>;
  NewCommentResult?: NewCommentResultResolvers<ContextType>;
  NewGroupResult?: NewGroupResultResolvers<ContextType>;
  NewMessage?: NewMessageResolvers<ContextType>;
  NewMessages?: NewMessagesResolvers<ContextType>;
  NewMessagesForChat?: NewMessagesForChatResolvers<ContextType>;
  NewPostResult?: NewPostResultResolvers<ContextType>;
  NewUsersResult?: NewUsersResultResolvers<ContextType>;
  News?: NewsResolvers<ContextType>;
  NotifMessageReactionData?: NotifMessageReactionDataResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationSetting?: NotificationSettingResolvers<ContextType>;
  NotificationSettings?: NotificationSettingsResolvers<ContextType>;
  NotificationsResult?: NotificationsResultResolvers<ContextType>;
  PagedChatList?: PagedChatListResolvers<ContextType>;
  PagedComments?: PagedCommentsResolvers<ContextType>;
  PagedDestinations?: PagedDestinationsResolvers<ContextType>;
  PagedGroups?: PagedGroupsResolvers<ContextType>;
  PagedLiveStreamComments?: PagedLiveStreamCommentsResolvers<ContextType>;
  PagedMatches?: PagedMatchesResolvers<ContextType>;
  PagedMeets?: PagedMeetsResolvers<ContextType>;
  PagedMessages?: PagedMessagesResolvers<ContextType>;
  PagedPosts?: PagedPostsResolvers<ContextType>;
  PagedReactions?: PagedReactionsResolvers<ContextType>;
  PagedReels?: PagedReelsResolvers<ContextType>;
  PagedSurveys?: PagedSurveysResolvers<ContextType>;
  PagedUsers?: PagedUsersResolvers<ContextType>;
  PagedYesMatches?: PagedYesMatchesResolvers<ContextType>;
  PagedYesMatchesBlurred?: PagedYesMatchesBlurredResolvers<ContextType>;
  PagingResult?: PagingResultResolvers<ContextType>;
  PeopleFilter?: PeopleFilterResolvers<ContextType>;
  Photo?: PhotoResolvers<ContextType>;
  PhotoUploadResult?: PhotoUploadResultResolvers<ContextType>;
  PhotoVerification?: PhotoVerificationResolvers<ContextType>;
  PlaceAutocomplete?: PlaceAutocompleteResolvers<ContextType>;
  Post?: PostResolvers<ContextType>;
  PrivatePhotosAccessResult?: PrivatePhotosAccessResultResolvers<ContextType>;
  Profile?: ProfileResolvers<ContextType>;
  ProfileLocation?: ProfileLocationResolvers<ContextType>;
  ProfileVisit?: ProfileVisitResolvers<ContextType>;
  ProfileWork?: ProfileWorkResolvers<ContextType>;
  PromoReward?: PromoRewardResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Reaction?: ReactionResolvers<ContextType>;
  Reel?: ReelResolvers<ContextType>;
  ReelPromotionResult?: ReelPromotionResultResolvers<ContextType>;
  ReelUploadResult?: ReelUploadResultResolvers<ContextType>;
  ReelsForYouResult?: ReelsForYouResultResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  SearchUsersResult?: SearchUsersResultResolvers<ContextType>;
  SendMessageResult?: SendMessageResultResolvers<ContextType>;
  Settings?: SettingsResolvers<ContextType>;
  Spotify?: SpotifyResolvers<ContextType>;
  SpotifyArtist?: SpotifyArtistResolvers<ContextType>;
  SpotifyMedia?: SpotifyMediaResolvers<ContextType>;
  SpotifyTrack?: SpotifyTrackResolvers<ContextType>;
  Spotlight?: SpotlightResolvers<ContextType>;
  SpotlightResult?: SpotlightResultResolvers<ContextType>;
  Survey?: SurveyResolvers<ContextType>;
  SurveyListItem?: SurveyListItemResolvers<ContextType>;
  SurveyQuestion?: SurveyQuestionResolvers<ContextType>;
  SyncResult?: SyncResultResolvers<ContextType>;
  Tiktok?: TiktokResolvers<ContextType>;
  TiktokMedia?: TiktokMediaResolvers<ContextType>;
  TranslatedText?: TranslatedTextResolvers<ContextType>;
  TranslationResult?: TranslationResultResolvers<ContextType>;
  UploadField?: UploadFieldResolvers<ContextType>;
  UploadPostInfo?: UploadPostInfoResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserBlock?: UserBlockResolvers<ContextType>;
  UserCardData?: UserCardDataResolvers<ContextType>;
  UserInsights?: UserInsightsResolvers<ContextType>;
  Video?: VideoResolvers<ContextType>;
  VideoUploadResult?: VideoUploadResultResolvers<ContextType>;
  VoicePrompt?: VoicePromptResolvers<ContextType>;
  VoicePromptUploadResult?: VoicePromptUploadResultResolvers<ContextType>;
};

