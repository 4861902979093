import React from "react";
import styled from "styled-components";

interface IconProps {
  size: number;
  color?: string;
  backgroundColor?: string;
}

const IconDiv = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor ?? props.theme.buttonBg};
  color: ${(props) => props.color ?? props.theme.buttonFg};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  //margin-right: 5px;

  &:hover {
    background-color: ${(props) =>
      props.backgroundColor ?? props.theme.buttonBg};
    transition: 0.2s;
    opacity: 0.8;
    cursor: pointer;
  }
`;

const CircIconButton: React.FC<{
  id?: string;
  icon: React.ReactNode;
  size: number;
  color?: string;
  onClick?: (event: any) => void;
  anchorEl?: any;
  backgroundColor?: string;
}> = ({
  icon,
  size,
  color,
  onClick,
  anchorEl,
  backgroundColor,
  id,
  ...props
}) => {
  return (
    <>
      <IconDiv
        id={id}
        onClick={(event) => (onClick ? onClick(event) : null)}
        color={color}
        backgroundColor={backgroundColor}
        size={size}
        ref={anchorEl}
        {...props}
      >
        {icon}
      </IconDiv>
    </>
  );
};

export default CircIconButton;
