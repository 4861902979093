import styled from "styled-components";
import { FlexCol } from "../Styled/Container";
import CircularProgress from "@mui/material/CircularProgress";

export const CardContainer = styled.div<{ width: number; height: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  max-height: ${(props) => props.height}px;
  background-color: ${(props) => props.theme.pageBackground};
  box-shadow: 0 0 1px rgb(0 0 0 / 10%), 0 1px 1px rgb(0 0 0 / 10%);
  flex-basis: 100%;
  flex-grow: 1;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const EmptyReelCardContainer = styled(CardContainer)`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledSpinner = styled(CircularProgress)`
  color: ${(props) => props.theme.secondaryTextColor};
`;

export const AddReelImage = styled.img`
  width: 100%;
  height: 65%;
  object-fit: cover;
  &:hover {
    cursor: pointer;
  }
  align-self: flex-start;
  justify-self: flex-start;
`;

export const AddIconContainer = styled.div`
  display: flex;
  margin-top: -20px;
  justify-content: center;
  align-items: center;
  padding: 1px;
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  background-color: ${(props) => props.theme.pageBackground};
  border-radius: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const AddTextContainer = styled(FlexCol)`
  margin-top: -10px;
  justify-content: center;
  align-items: center;
  row-gap: 3px;
  &:hover {
    cursor: pointer;
  }
`;

export const ReelImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const AvatarContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;

  &:hover {
    scale: 1.1;
  }
`;
