import { useState } from "react";

export const useGeoLocation = () => {
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [error, setError] = useState<string | null>(null);

  // const onChange = ({coords}) => {
  //   setPosition({
  //     latitude: coords.latitude,
  //     longitude: coords.longitude,
  //   });
  // };
  // const onError = (error) => {
  //   setError(error.message);
  // };

  const fetchLocation = (callback?: (data: GeolocationPosition) => void) => {
    const geo: Geolocation = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }
    geo.getCurrentPosition(
      (data: GeolocationPosition) => {
        setPosition(data);
        setError(null);
        if (callback) {
          callback(data);
        }
      },
      (error: GeolocationPositionError) => {
        setPosition(null);
        setError(error.message);
      }
    );
  };

  const reset = () => {
    setPosition(null);
    setError(null);
  };

  // useEffect(() => {

  //   watcher = geo.watchPosition(onChange, onError);
  //   return () => geo.clearWatch(watcher);
  // }, []);

  return { position, error, fetchLocation, reset };
};
