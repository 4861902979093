import { useMutation } from "@apollo/client";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  GlobeAltIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { logger } from "util/log";
import {
  StdButton,
  default as StyledButton,
} from "../../components/Button/Button";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import HrDivider from "../../components/HrDivider/HrDivider";
import { FlexCol, FlexRow } from "../../components/Styled/Container";
import Text, { SpanItalic, StyledText } from "../../components/Styled/Text";
import { GOOGLE_LOGIN } from "../../graphql/mutations-gql";
import { useAuthStore } from "../../hooks/useAuth";
import useWindowSize from "../../hooks/useWindowSize";
import { track } from "../../util/amplitude";
import GoogleButton from "./GoogleButton";
import GoogleSignupModal from "./GoogleSignupModal";
import LoginFooter from "./LoginFooter";
import LoginForm from "./LoginForm";
import {
  BoxContainer,
  InfoBubble,
  InfoContainer,
  InfoHeader,
  InfoHeaderWrapper,
  MingleContainer,
  PageWrapper,
  PaperBox,
  Spacer,
  StyledA,
  WhyImage,
  WhyImageContainer,
  WhyImageTextContainer,
  WhyWrapper,
  WrapContainer,
} from "./styled";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 200px;
  //background-color: ${(props) => props.theme.bodyBackground};
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  flex-shrink: 1;
  flex-grow: 0;
`;

const EasySignup = styled(StyledText)`
  align-self: center;
`;

const LoginLeftColumn = styled.div`
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  row-gap: 20px;
`;

const LoginRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  flex-wrap: wrap;
`;

const LoginFormContainer = styled.div`
  min-width: 400px;
  max-width: 400px !important;
  background-color: ${(props) => props.theme.pageBackground};
  border-radius: 12px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  box-sizing: border-box;
  padding: 20px;
`;

const StyledPage = styled.div<{ bgImage: string }>`
  width: 100%;
  //background-color: ${(props) => props.theme.bodyBackground};
  display: flex;
  flex-flow: column;

  background: ${(props) => `url(/images/${props.bgImage})`} no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @supports (-webkit-touch-callout: none) {
    background: ${(props) => `url(/images/${props.bgImage})`} center top
      no-repeat scroll;
    //background-size: cover;
    background-attachment: scroll;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @media (prefers-reduced-motion: no-preference) {
    .login-logo {
      animation: pulse 3s infinite;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.9);
    }
  }
`;

const AppStoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //padding-bottom: 10px !important;
  width: 100%;

  flex-direction: row;

  a {
    align-self: center;
    text-decoration: none;
  }

  .appstore-text {
    align-self: center;
  }

  .space20 {
    width: 20px;
    height: 20px;
  }
`;

const SocialLoginButtonWrapper = styled.div`
  max-width: 270px;
  width: 270px;
  min-width: 270px;
  &:hover {
    cursor: pointer;
  }
`;

const StoreImage = styled.img`
  width: 160px;
`;

const LogoTitleContainer = styled(FlexCol)`
  max-width: 280px;
`;
const DividerContainer = styled(FlexCol)`
  max-width: 400px;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

const BrandImage = styled.img`
  margin-right: 10px;
`;

export const getLanguage = (langCode: string) => {
  switch (langCode) {
    case "th":
      return "Thai";
    case "hi":
      return "Hindi";
    case "de":
      return "German";
    case "fr":
      return "French";
    case "es":
      return "Spanish";
    case "fi":
      return "Finnish";
    case "ru":
      return "Russian";
    case "pt":
      return "Portuguese";
    case "ja":
      return "Japanese";
    case "it":
      return "Italian";
    case "el":
      return "Greek";
    case "ko":
      return "Korean";
    case "ar":
      return "Arabic";
    case "tr":
      return "Turkish";
    case "id":
      return "Indonesian";
    case "ph":
      return "Filipino";
    case "km":
      return "Khmer";
    case "bg":
      return "Bulgarian";
    case "ca":
      return "Catalan";
    case "hr":
      return "Croatian";
    case "cs":
      return "Czech";
    case "da":
      return "Danish";
    case "nl":
      return "Dutch";
    case "et":
      return "Estonian";
    case "hu":
      return "Hungarian";
    case "lv":
      return "Latvian";
    case "lt":
      return "Lithuanian";
    case "ms":
      return "Malay";
    case "no":
      return "Norwegian";
    case "pl":
      return "Polish";
    case "ro":
      return "Romanian";
    case "uk":
      return "Ukrainian";
    case "vi":
      return "Vietnamese";
    case "sk":
      return "Slovak";
    case "sv":
      return "Swedish";
    case "en":
      return "English";
    case "fil":
      return "Filipino";
    case "zh":
      return "Chinese";
    default:
      return null;
  }
};

const InfoItem: React.FC<{
  title: string;
  text: string;
  icon: JSX.Element;
}> = ({ title, text, icon }) => {
  const theme = useTheme() as Theme;

  return (
    <InfoContainer>
      <InfoBubble>{icon}</InfoBubble>
      <Text size={24} weight={600}>
        {title}
      </Text>
      <Text size={18} color={theme.colors.gray}>
        {text}
      </Text>
    </InfoContainer>
  );
};

const LoginPage: React.FC<{}> = () => {
  const theme = useTheme() as Theme;
  const setAuthenticated = useAuthStore.use.setAuthenticated();
  const [googleLoginMutation, { error: googleError }] =
    useMutation(GOOGLE_LOGIN);
  // const [facebookLoginMutation, { error: facebookError }] =
  //   useMutation(FACEBOOK_LOGIN);
  // const [showFacebookSignup, setShowFacebookSignup] = useState(false);
  // const [facebookToken, setFacebookToken] = useState("");
  const [socialLoginError, setSocialLoginError] = useState<string | null>(null);
  const [showGoogleSignup, setShowGoogleSignup] = useState(false);
  const [googleToken, setGoogleToken] = useState("");
  const { search } = useLocation();
  const invite = new URLSearchParams(search).get("invite");
  const signupRef = new URLSearchParams(search).get("ref");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const { lang } = useParams();

  //logger.log("invite", invite);
  //logger.log("signupRef", signupRef);

  useEffect(() => {
    theme.name === "dark"
      ? document.documentElement.setAttribute("loginPageDark", "")
      : document.documentElement.setAttribute("loginPageLight", "");
  }, [theme]);

  useEffect(() => {
    if (lang) {
      logger.log("Changing lang to: " + lang);
      i18n.changeLanguage(lang);
      localStorage.setItem("lang", lang);
    }
  }, [i18n, lang]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const loginWithGoogleToken = (access_token: string) => {
    // Try a login with google. If no user exists we show the google signup dialog that lets the user select
    // some necessary fields before signup can be completed. Signup should automatically login the user.
    googleLoginMutation({
      variables: {
        context: { service: "api-auth" },
        args: {
          token: access_token,
          appPlatform: "web",
        },
      },
      onCompleted: (data) => {
        logger.log("googleLogin onCompleted", data);
        if (data.googleLogin.authenticated) {
          track("Web Login Success", { loginType: "Google" });
          setAuthenticated(true);
          localStorage.setItem("auth_token", data.googleLogin.token);
        } else {
          // Show google signup dialog.
          setGoogleToken(access_token);
          setShowGoogleSignup(true);
        }
      },
      onError: (error) => {
        logger.log("googleLogin onError", error);
      },
    });
  };

  // const loginWithFacebookToken = (accessToken: string) => {
  //   facebookLoginMutation({
  //     variables: {
  //       args: {
  //         token: accessToken,
  //         appPlatform: "web",
  //       },
  //     },
  //     onCompleted: (data) => {
  //       logger.log("facebookLogin onCompleted", data);
  //       if (data.facebookLogin.authenticated) {
  //         track("Web Login Success", { loginType: "Facebook" });
  //         setAuthenticated(true);
  //       } else {
  //         // Show facebook signup dialog.
  //         setFacebookToken(accessToken);
  //         setShowFacebookSignup(true);
  //       }
  //     },
  //     onError: (error) => {
  //       logger.log("facebookLogin onError", error);
  //     },
  //   });
  // };

  const googleLogin = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: (tokenResponse) => {
      logger.log(tokenResponse);

      // access_token
      // token_type
      if (tokenResponse) {
        loginWithGoogleToken(tokenResponse.access_token);
      } else {
        setSocialLoginError("No valid response received from Google.");
      }
    },
  });

  // const facebookLogin = async () => {
  //   FacebookLoginClient.clear();

  //   await FacebookLoginClient.loadSdk("en_US");
  //   FacebookLoginClient.init({ appId: "573999271435465", version: "v9.0" });

  //   FacebookLoginClient.login(
  //     (res: LoginResponse) => {
  //       logger.log(res);

  //       if (res && res.status === "connected" && res.authResponse) {
  //         loginWithFacebookToken(res.authResponse.accessToken);
  //       }
  //     },
  //     {
  //       scope: "email",
  //     }
  //   );
  // };

  // Lets get the bgImage based on which day of the week it is
  const today = new Date();
  const dayOfWeek = today.getDay(); // day is a number between 0 and 6
  let whyIndex = dayOfWeek - 1;
  if (whyIndex < 0) {
    whyIndex = 6;
  }
  // const dayOfWeek = 6;
  const bgImages = [
    { bg: "bgImage0.jpg", text: "/logo-text-white.png", textColor: "#FFF" },
    { bg: "bgImage1.jpg", text: "/logo-text.png", textColor: "#000" },
    { bg: "bgImage2.jpg", text: "/logo-text.png", textColor: "#000" },
    { bg: "bgImage3.jpg", text: "/logo-text-white.png", textColor: "#FFF" },
    { bg: "bgImage4.jpg", text: "/logo-text-white.png", textColor: "#FFF" },
    { bg: "bgImage5.jpg", text: "/logo-text-white.png", textColor: "#FFF" },
    { bg: "bgImage6.jpg", text: "/logo-text.png", textColor: "#000" },
  ];

  // Lets use a static image for now instead of different for each day of the week
  let bgImage = "bgImage7.jpg";
  let textLogo = "/logo-text-white.png";
  const textColor = "#000";
  //let bgImage = bgImages[dayOfWeek].bg ?? "couple3.jpg";
  //let textLogo = bgImages[dayOfWeek].text ?? "/logo-text-white.png";
  //const textColor = bgImages[dayOfWeek].textColor ?? "#FFF";

  let whyImage = bgImages[whyIndex].bg ?? "couple2.jpg";

  // Use this when we have a static image, so it will not show the same image in the why section.
  if (whyImage === "bgImage2.jpg") {
    whyImage = "bgImage3.jpg";
  }

  // if (windowSize[0] < 500) {
  //   bgImage = "loginBg3.jpg";
  //   textLogo = "logo-text-white.png";
  // }

  // onelink: https://minglify.onelink.me/8yrw/oy6xckce
  // appstore: https://apps.apple.com/us/app/minglify/id6447954430?platform=iphone
  // playstore: https://play.google.com/store/apps/details?id=se.minglify.app

  //const onelink = "https://minglify.onelink.me/8yrw/oy6xckce";
  // const appstoreLink = "https://apps.apple.com/us/app/minglify/id6447954430?platform=iphone";
  // const playstoreLink = "https://play.google.com/store/apps/details?id=se.minglify.app";
  const onelink = "https://minglify.io/app-install/web-minglify";

  return (
    <PageWrapper>
      <GoogleSignupModal
        show={showGoogleSignup}
        hide={() => setShowGoogleSignup(false)}
        token={googleToken}
        invite={invite}
        signupRef={signupRef}
      />
      {/* <FacebookSignupModal
        show={showFacebookSignup}
        hide={() => setShowFacebookSignup(false)}
        token={facebookToken}
        invite={invite}
        signupRef={signupRef}
      /> */}
      <StyledPage bgImage={bgImage}>
        <LoginContainer>
          <LoginLeftColumn>
            <LogoContainer>
              {windowSize[0] > 500 && (
                <img
                  className="login-logo"
                  width={150}
                  alt="logo"
                  src="/heart.png"
                  style={{ marginTop: "-80px" }}
                />
              )}

              <LogoTitleContainer rowGap={5}>
                <FlexRow>
                  <BrandImage height={100} alt="logo" src={textLogo} />
                </FlexRow>
                <FlexRow>
                  <StyledText
                    size={22}
                    weight={900}
                    color="#FFF"
                    shadow="1px 1px #000"
                  >
                    {t("Dating, Chat & Friends.")}
                  </StyledText>
                </FlexRow>
                <FlexRow>
                  <StyledText
                    size={16}
                    weight={900}
                    color="#FFF"
                    shadow="1px 1px #000"
                  >
                    <SpanItalic>
                      {t("Match with videos, meet & date new people.")}
                    </SpanItalic>
                  </StyledText>
                </FlexRow>
              </LogoTitleContainer>
            </LogoContainer>
            <FlexCol justifyContent="center">
              {/* <FlexRow justifyContent="center">
                <SocialLoginButtonWrapper onClick={() => facebookLogin()}>
                  <FacebookButton />
                </SocialLoginButtonWrapper>
              </FlexRow> */}
              <FlexRow justifyContent="center">
                <SocialLoginButtonWrapper onClick={() => googleLogin()}>
                  <GoogleButton />
                </SocialLoginButtonWrapper>
              </FlexRow>

              <DividerContainer>
                <HrDivider
                  textOpacity={1}
                  text={t("or") ?? "or"}
                  color={theme.colors.white}
                />

                <FlexCol alignItems="center" padding={0}>
                  <FlexRow justifyContent="center" textAlign="center">
                    <StyledText
                      size={12}
                      weight={900}
                      color={"#FFF"}
                      shadow="1px 1px #000"
                    >
                      {t(
                        "Use the form to sign in with email or username and password."
                      )}
                    </StyledText>
                  </FlexRow>
                  {googleError && (
                    <FlexRow justifyContent="center">
                      <ErrorAlert error={googleError} />
                    </FlexRow>
                  )}
                  {/* {facebookError && (
                    <FlexRow justifyContent="center">
                      <ErrorAlert error={facebookError} />
                    </FlexRow>
                  )} */}
                  {socialLoginError && (
                    <FlexRow justifyContent="center">
                      <ErrorAlert error={socialLoginError} />
                    </FlexRow>
                  )}

                  {/* <FlexRow justifyContent="center" width="320px">
                    <FlexCol>
                      <FlexRow
                        justifyContent="center"
                        wrap="nowrap"
                        colGap={20}
                      >
                        <DevicesImage src="/images/devices.png" />
                        <Text size={15} color={theme.colors.gray}>
                          {t(
                            "Works great on your laptop, mobile phone or touch pad!"
                          )}
                        </Text>
                      </FlexRow>
                    </FlexCol>
                  </FlexRow> */}
                </FlexCol>
              </DividerContainer>

              <AppStoreContainer>
                <a href={onelink}>
                  <StoreImage src="/appstore.png" alt="appstore" />
                </a>
                <Spacer height={10} width={10} />
                <a href={onelink}>
                  <StoreImage src="/googleplay.png" alt="appstore" />
                </a>
              </AppStoreContainer>
            </FlexCol>
          </LoginLeftColumn>

          <LoginRightColumn>
            <FlexCol>
              <LoginFormContainer>
                <LoginForm invite={invite} signupRef={signupRef} />
              </LoginFormContainer>
              <EasySignup weight={900} color={"#FFF"} shadow="1px 1px #000">
                {t("Quick and easy FREE signup!")}
              </EasySignup>
            </FlexCol>
          </LoginRightColumn>
        </LoginContainer>
      </StyledPage>

      <InfoHeaderWrapper>
        <InfoHeader>
          <InfoItem
            title={t("Safe and serious dating")}
            text={t(
              "Date with confidence and find someone who is also seeking something genuine."
            )}
            icon={<ShieldCheckIcon width={40} color={theme.colors.primary} />}
          />
          <InfoItem
            title={t("Modern chat")}
            text={t(
              "A modern chat with real-time translation to your language."
            )}
            icon={
              <ChatBubbleOvalLeftEllipsisIcon
                width={40}
                color={theme.colors.primary}
              />
            }
          />
          <InfoItem
            title={t("Matching with people")}
            text={t(
              "Use an advanced filter to find your perfect matches and nearby people."
            )}
            icon={<ScaleIcon width={40} color={theme.colors.primary} />}
          />
        </InfoHeader>
      </InfoHeaderWrapper>

      <Box sx={{ width: 20, height: 40 }} />

      <MingleContainer rowGap={30} padding={20}>
        <FlexRow justifyContent="center" textAlign="center">
          <StyledA href={"/international-dating/" + i18n.language}>
            <Text size={32} weight={900} color={theme.colors.primary}>
              {t("Mingle and meet in a global community")}
            </Text>
          </StyledA>
        </FlexRow>

        <BoxContainer
          className="box-container"
          colGap={20}
          justifyContent="center"
          alignItems="stretch"
        >
          <PaperBox>
            <FlexRow justifyContent="center">
              <GlobeAltIcon width={40} color={theme.colors.primary} />
            </FlexRow>
            <FlexRow justifyContent="center" textAlign="center">
              <Text size={23} weight={600}>
                {t("More than just matching")}
              </Text>
            </FlexRow>
            <FlexRow justifyContent="center">
              <Text color={theme.colors.gray} size={16}>
                {t(
                  "Minglify offers more than traditional matchmaking. It's a vibrant community where you can engage with multiple individuals simultaneously. You have the freedom to craft posts and establish your own groups."
                )}
              </Text>
            </FlexRow>
          </PaperBox>
          <PaperBox>
            <FlexRow justifyContent="center">
              <UsersIcon width={40} color={theme.colors.primary} />
            </FlexRow>
            <FlexRow justifyContent="center" textAlign="center">
              <Text size={23} weight={600}>
                {t("Groups")}
              </Text>
            </FlexRow>
            <FlexRow justifyContent="center">
              <Text color={theme.colors.gray} size={16}>
                {t(
                  "At Minglify, users have the ability to establish their own groups with either public or private accessibility. In addition, individuals can generate exclusive and concealed groups for individuals who possess a shared interest."
                )}
              </Text>
            </FlexRow>
          </PaperBox>
          <PaperBox>
            <FlexRow justifyContent="center">
              <ShieldCheckIcon width={40} color={theme.colors.primary} />
            </FlexRow>
            <FlexRow justifyContent="center" textAlign="center">
              <Text size={24} weight={600}>
                {t("A safe and moderated environment")}
              </Text>
            </FlexRow>
            <FlexRow justifyContent="center">
              <Text color={theme.colors.gray} size={16}>
                {t(
                  "To maintain a secure online environment and ensure compliance with our terms of use and privacy policies, our administrators closely monitor all user profiles and posts. If any content violates the rules or makes members feel uncomfortable, they can easily report it to our team for prompt action."
                )}
              </Text>
            </FlexRow>
          </PaperBox>
        </BoxContainer>
      </MingleContainer>

      <Box sx={{ width: 20, height: 40 }} />

      <WhyWrapper padding={40}>
        <FlexRow justifyContent="center">
          <Text size={32} weight={900} color={theme.colors.primary}>
            {t("Why Minglify?")}
          </Text>
        </FlexRow>
        <FlexRow justifyContent="center">
          <Text size={24} weight={900}>
            {t("Meet people that match you")}
          </Text>
        </FlexRow>
        <WhyImageContainer justifyContent="center">
          <WhyImage src={"/images/" + whyImage} />
          {windowSize[0] > 280 && (
            <WhyImageTextContainer>
              <StyledText
                size={windowSize[0] < 380 ? 18 : 28}
                weight={900}
                color="#FFF"
                shadow="1px 1px #000"
              >
                {t("Mingle, meet and find your true match!")}
              </StyledText>
            </WhyImageTextContainer>
          )}
        </WhyImageContainer>
      </WhyWrapper>

      <FlexCol alignItems="center">
        <WrapContainer padding={20}>
          <FlexRow justifyContent="center" wrap="nowrap">
            <FlexRow colGap={50}>
              <FlexCol>
                <FlexRow>
                  <Text size={24} weight={600}>
                    {t("Trust and security")}
                  </Text>
                </FlexRow>
                <FlexRow>
                  <Text size={16}>
                    {t(
                      "At Minglify, our primary objective is to ensure that our users have a pleasant and secure online dating experience. We strive to provide a platform where sincere and serious-minded singles can connect and pursue meaningful relationships. Our commitment to user safety is unwavering, and we have taken measures to safeguard our dating site and app. Every profile and picture is thoroughly reviewed to maintain the highest level of privacy and security for our users. We also offer a reporting system that allows users to flag any profiles or messages that make them feel uncomfortable. Our team is always available to address any concerns and provide support whenever needed."
                    )}
                  </Text>
                </FlexRow>
              </FlexCol>
            </FlexRow>
            <FlexRow>
              <ShieldCheckIcon width={100} color={theme.colors.primary} />
            </FlexRow>
          </FlexRow>
        </WrapContainer>

        <WrapContainer padding={20}>
          <FlexRow justifyContent="center" wrap="nowrap" colGap={10}>
            <FlexRow>
              <UsersIcon width={100} color={theme.colors.primary} />
            </FlexRow>
            <FlexRow colGap={50}>
              <FlexCol>
                <FlexRow>
                  <Text size={24} weight={600}>
                    {t("Detailed profiles")}
                  </Text>
                </FlexRow>
                <FlexRow>
                  <Text size={16}>
                    {t(
                      "At Minglify, the user profiles are highly detailed. You can search for matches by using specific criteria or simply by browsing through member profiles."
                    )}
                  </Text>
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexRow>
        </WrapContainer>
      </FlexCol>

      <FlexCol
        backgroundColor={"#E0357D"}
        padding={40}
        alignItems="center"
        rowGap={20}
      >
        <FlexRow justifyContent="center" textAlign="center" maxWidth="900px">
          <Text size={32} weight={900} color="#FFF">
            {t(
              "Minglify is the perfect place for singles find their partners, could you be next?"
            )}
          </Text>
        </FlexRow>
        <FlexRow color="#FFF">
          <StyledButton
            borderRadius="15px"
            backgroundColor="#FFF"
            color={theme.colors.primary}
            onClick={() => goToTop()}
          >
            {t("Sign up now")}
          </StyledButton>
        </FlexRow>
      </FlexCol>

      <FlexCol alignItems="center">
        <WrapContainer padding={20}>
          <FlexRow justifyContent="center" wrap="nowrap">
            <FlexRow colGap={50}>
              <FlexCol>
                <FlexRow textAlign="center">
                  <Text size={24} weight={600}>
                    {t("Multi-language chat")}
                  </Text>
                </FlexRow>
                <FlexRow>
                  <Text size={16}>
                    {t(
                      "Engage in conversations with individuals who speak a different language, and have their messages automatically translated into your native language as you communicate with them in real-time. This is an effortless and seamless experience like never before."
                    )}
                  </Text>
                </FlexRow>
              </FlexCol>
            </FlexRow>
            <FlexRow>
              <ChatBubbleOvalLeftEllipsisIcon
                width={100}
                color={theme.colors.primary}
              />
            </FlexRow>
          </FlexRow>
        </WrapContainer>

        <WrapContainer padding={20}>
          <FlexRow justifyContent="center" wrap="nowrap" colGap={10}>
            <FlexRow>
              <GlobeAltIcon width={100} color={theme.colors.primary} />
            </FlexRow>
            <FlexRow colGap={50}>
              <FlexCol>
                <FlexRow textAlign="center">
                  <StyledA href={"/international-dating/" + i18n.language}>
                    <Text size={24} weight={600}>
                      {t("Global online community")}
                    </Text>
                  </StyledA>
                </FlexRow>
                <FlexRow>
                  <Text size={16}>
                    {t(
                      "Minglify welcomes registrations from all individuals and boasts of its multi-lingual platform. This feature enables you to explore potential matches beyond your immediate surroundings, expanding your search worldwide. Who knows, your soulmate could be residing in a different country altogether!"
                    )}
                  </Text>
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexRow>
        </WrapContainer>
      </FlexCol>

      <FlexCol alignItems="center">
        <FlexRow maxWidth="900px" justifyContent="center">
          <PaperBox>
            <FlexRow>
              <FlexCol>
                <Text size={32} weight={900} color={theme.colors.blue}>
                  {t("Active Support")}
                </Text>
                <Text size={16}>
                  {t(
                    "Minglify prioritize your safety and security. Our team, based in Sweden is always readily available to assist you. If you require any assistance, please do not hesitate to visit our customer service portal or contact us at support@minglify.io. We conduct manual reviews of all uploaded images, and every new member must be approved by us. These measures are put in place to ensure a safe and secure environment for you!"
                  )}
                </Text>
              </FlexCol>
            </FlexRow>
            <FlexRow justifyContent="center">
              <StdButton onClick={() => navigate("/help-center/contact-form")}>
                {t("Contact support")}
              </StdButton>
            </FlexRow>
          </PaperBox>
        </FlexRow>
      </FlexCol>

      <Box sx={{ width: 20, height: 100 }} />

      <LoginFooter page="login" />
    </PageWrapper>
  );
};
export default LoginPage;
