import { Paper } from "@mui/material";
import styled from "styled-components";

export const MenuPaper = styled(Paper)`
  border-radius: 8px !important;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%), 0 4px 4px rgb(0 0 0 / 10%) !important;
  box-sizing: border-box !important;
  padding: 20px;
  margin-top: 5px;
`;
